import React from "react";
import External from "../components/Configuration/External/External";

export default function ExternalConfigPage(props) {
  return (
    <div>
      <External {...props} />
    </div>
  );
}
