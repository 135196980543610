import React, { Component } from "react";
import { API_URL } from "../../config.js";
import { message, Card, Table, List, Button, Row, Tag, Col } from "antd";
import {
  ExclamationCircleTwoTone,
  CheckCircleTwoTone,
  CaretRightOutlined,
  StopTwoTone,
} from "@ant-design/icons";

const moment = require("moment-timezone");

const zlib = require("zlib");

const colors = [
  "#2B4A6F",
  "#AA4139",
  "#462E74",
  "#2B803E",
  "#AA6039",
  "#8479e6",
  "#76b870",
  "#363690",
  "#574eb7",
  "#46b57c",
  "#44206b",
  "#43c29e",
  "#864490",
  "#36dee6",
  "#8c5fb8",
  "#7199e0",
  "#273d7f",
  "#c98fdb",
  "#4460bb",
  "#b88ae6",
  "#568de8",
  "#764e90",
  "#645ba8",
  "#2B4A6F",
  "#AA4139",
  "#462E74",
  "#2B803E",
  "#AA6039",
  "#8479e6",
  "#76b870",
  "#363690",
  "#574eb7",
  "#46b57c",
  "#44206b",
  "#43c29e",
  "#864490",
  "#36dee6",
  "#8c5fb8",
  "#7199e0",
  "#273d7f",
  "#c98fdb",
  "#4460bb",
  "#b88ae6",
  "#568de8",
  "#764e90",
  "#645ba8",
];

function tickFormatter(v) {
  const timestamp = moment(v).toDate();
  return timestamp;
}

class Health extends Component {
  constructor(props) {
    super(props);
    this.state = {
      health_response: {},
      analysis_point: null,
      trend_data: {},
      show_metrics: true,
      color_map: {},
      analysis_points: [],
      selected_trends: ["Transmission"],
      data_types: [],
      traces: [],
    };
    this.refreshData = this.refreshData.bind(this);
    this.handleResultChange = this.handleResultChange.bind(this);
    this.handleAnalysisChange = this.handleAnalysisChange.bind(this);
    this.toggleAnalyzer = this.toggleAnalyzer.bind(this);
    this.tagRender = this.tagRender.bind(this);
    this.calculateMainVisualization =
      this.calculateMainVisualization.bind(this);
  }

  toggleAnalyzer() {
    fetch(API_URL + "/actions/action/vcon", {
      method: "POST",
      headers: {
        Authorization: "Bearer " + this.props.getToken(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        type: "vcon",
        commands: [{ command: "timer-toggle", source: "WebService" }],
      }),
    })
      .then((res) => res.json())
      .then(() => {
        this.props.refreshData();
      })
      .catch((error) => {
        console.error(error);
      });
  }

  componentDidMount() {
    this.interval = setInterval(() => this.refreshData(), 60000);
    this.refreshData();
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  tagRender(props) {
    const { label, closable, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    let color = "";
    if (this.state.color_map[label]) {
      color = this.state.color_map[label];
    }
    return (
      <Tag
        color={color}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3 }}
      >
        {label}
      </Tag>
    );
  }

  handleAnalysisChange(value) {
    this.setState(
      { analysis_point: value, selected_trends: ["Transmission"] },
      function () {
        this.refreshData();
      }
    );
  }
  handleResultChange(value) {
    this.setState({ selected_trends: value }, function () {
      this.calculateMainVisualization(this.state.trend_data);
    });
  }

  refreshData() {
    fetch(API_URL + "/data/vcon_config/", {
      method: "GET",
      headers: {
        Authorization: "Bearer " + this.props.getToken(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((res_config) => res_config.json())
      .then((data_config) => {
        this.setState(
          {
            analysis_points:
              data_config["vcon_configuration"]["analysis_points"],
          },
          function () {
            let analysis_point = this.state.analysis_point;
            if (this.state.analysis_point === null) {
              analysis_point =
                data_config["vcon_configuration"]["analysis_points"][0]
                  .analysis_number;
              this.setState({ analysis_point });
            }
            fetch(API_URL + "/data/trending", {
              method: "POST",
              headers: {
                Authorization: "Bearer " + this.props.getToken(),
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                analysis_point: analysis_point,
                pixels: 50,
                fromtimestamp: 0,
                totimestamp: 0,
              }),
            })
              .then((res) => res.text())
              .then((res) => {
                const uncompressed = zlib
                  .inflateSync(new Buffer(res, "base64"))
                  .toString();
                res = JSON.parse(uncompressed);
                this.setState({ trend_data: res }, function () {
                  this.calculateMainVisualization();
                });
              })
              .catch((error) => {
                message.error("Unable to Refresh Service Status: " + error, 1);
                console.error(error);
              });
          }
        );
      })
      .catch((error) => {
        message.error("Unable to Receive VCON Configuration: " + error, 1);
        console.error(error);
      });
  }

  calculateMainVisualization() {
    let trend_data = this.state.trend_data;
    let traces = [];

    let data_types = Object.keys(trend_data["result_data"]);

    this.setState({ data_types });

    const colormap = {};
    const colormapChip = {};
    if (data_types) {
      data_types.forEach(function (r, index) {
        colormapChip[r] = colors[index];
        colormap[r] = colors[index];
      }, this);
    }

    this.setState({ color_map: colormap });

    if (data_types && data_types.length > 0) {
      if (trend_data["sample_size"]) {
        this.setState({
          sample_size_percentage:
            trend_data["sample_size"][0].sample_size_percentage,
          sample_size_pool: trend_data["sample_size"][0].sample_size_pool,
          sample_size_pixels: trend_data["sample_size"][0].sample_size_pixels,
        });
      }

      let selected_count = 0;
      if (this.state.selected_trends && this.state.selected_trends.length > 0) {
        selected_count = this.state.selected_trends.length;
      }

      this.state.selected_trends.forEach(function (s) {
        if (trend_data["result_data"][s]) {
          let modelData = trend_data["result_data"][s];
          let xArray = modelData.map((a) => tickFormatter(new Date(a.x)));
          let yArray = modelData.map((a) => a.y);
          let valueDisplayNameArray = modelData.map((a) =>
            a.value_display_name === null ? "" : a.value_display_name
          );

          let sampleData = [];
          modelData.forEach(function (d) {
            if (d.sample === true) {
              sampleData.push(d);
            }
          });

          let sampleXArray = sampleData.map((a) =>
            tickFormatter(new Date(a.x))
          );
          let sampleYArray = sampleData.map((a) => a.y);

          let trace = {
            x: xArray,
            y: yArray,
            model: s,
            points: modelData,
            yaxis: "y",
            name: "",
            mode: "lines+markers",
            fill: selected_count === 1 ? "tonexty" : "",
            type: "scatter",
            vis_type: "main",
            connectgaps: false,
            text: valueDisplayNameArray,
            hovertemplate: s + ": %{y} %{text}",
            marker: {
              size: 2.25,
              color: colormap[s],
              opacity: 1,
            },
            line: {
              width: 1.5,
              color: colormap[s],
            },
          };
          traces.push(trace);

          let samplesTrace = {
            x: sampleXArray,
            y: sampleYArray,
            model: s,
            points: modelData,
            name: "",
            mode: "markers",
            vis_type: "samples",
            yaxis: "y",
            hovertemplate: s + " - Sample: %{y}",
            type: "scatter",
            marker: {
              size: 15,
              symbol: "circle-open",
              color: colormap[s],
              opacity: 1,
            },
          };
          traces.push(samplesTrace);
        }
      }, this);
    }

    this.setState({ traces });
  }

  render() {
    return (
      <div>
        <Row gutter={[24, 24]}>
          <Col
            gutter={[24, 24]}
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            xxl={12}
          >
            <Card
              size="small"
              title="Analyzer Health"
              extra={
                <span>
                  {this.props.timer_enabled ? (
                    <Button
                      disabled={!this.props.authorized}
                      style={{ marginRight: "10px" }}
                      type="dashed"
                      icon={<StopTwoTone twoToneColor="red" />}
                      onClick={this.toggleAnalyzer}
                    >
                      Stop Analyzer
                    </Button>
                  ) : (
                    <Button
                      disabled={!this.props.authorized}
                      style={{ marginRight: "10px" }}
                      onClick={this.toggleAnalyzer}
                      type="dashed"
                      icon={<CaretRightOutlined />}
                    >
                      Start Analyzer
                    </Button>
                  )}
                </span>
              }
            >
              {this.props.result_status}

              {this.props.faults && this.props.faults.length > 0 ? (
                <span>
                  <List
                    style={{ marginBottom: "10px" }}
                    size="small"
                    itemLayout="horizontal"
                    dataSource={this.props.faults}
                    bordered
                    renderItem={(item) => (
                      <List.Item>
                        <List.Item.Meta
                          avatar={
                            <ExclamationCircleTwoTone
                              twoToneColor="#ff1a1a"
                              style={{
                                fontSize: "24pt",
                              }}
                            />
                          }
                          title={
                            "Analysis Point " +
                            item.analysis_point +
                            " - " +
                            item.name
                          }
                          description={item.code}
                        />
                      </List.Item>
                    )}
                  />
                </span>
              ) : null}
              {this.props.warnings && this.props.warnings.length > 0 ? (
                <span>
                  <List
                    size="small"
                    itemLayout="horizontal"
                    dataSource={this.props.warnings}
                    bordered
                    renderItem={(item) => (
                      <List.Item>
                        <List.Item.Meta
                          avatar={
                            <ExclamationCircleTwoTone
                              twoToneColor="#FF6100"
                              style={{
                                fontSize: "24pt",
                              }}
                            />
                          }
                          title={
                            "Analysis Point " +
                            item.analysis_point +
                            " - " +
                            item.name
                          }
                          description={item.code}
                        />
                      </List.Item>
                    )}
                  />
                </span>
              ) : null}
            </Card>
          </Col>
          <Col
            gutter={[24, 24]}
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            xxl={12}
          >
            <Card
              size="small"
              title={
                this.state.show_metrics
                  ? "Analyzer Activity"
                  : "Analyzer Services"
              }
              extra={
                <a
                  href="#"
                  onClick={() =>
                    this.setState({
                      show_metrics: !this.state.show_metrics,
                    })
                  }
                >
                  {this.state.show_metrics ? "Services" : "Status"}
                </a>
              }
            >
              <Table
                rowKey={this.state.show_metrics ? "id" : "service"}
                dataSource={
                  this.state.show_metrics
                    ? this.props.analyzer_logs
                    : this.props.service_status
                }
                size="small"
                showHeader={false}
                columns={
                  this.state.show_metrics
                    ? [
                        {
                          title: "Log",
                          dataIndex: "detail",
                        },
                        {
                          title: "Timestamp",
                          render: (record) => {
                            moment.updateLocale("en", {
                              relativeTime: {
                                future: "%s",
                                past: "%s",
                                s: "%ds ago",
                                m: "1m ago",
                                mm: "%dm ago",
                                h: "1h ago",
                                hh: "%dh ago",
                                d: "1d ago",
                                dd: "%dd ago",
                                M: "1mth ago",
                                MM: "%dmth ago",
                                y: "1y ago",
                                yy: "%dy ago",
                              },
                            });
                            var utcTime = new Date(record.datestamp);
                            let display_date = moment
                              .utc(utcTime)
                              .format("YYYY-MM-DD HH:mm:ss z");
                            return <Tag>{display_date}</Tag>;
                          },
                        },
                      ]
                    : [
                        {
                          title: "Service Name",
                          dataIndex: "service",
                        },
                        {
                          title: "Status",
                          render: (record) => {
                            if (record.status === true) {
                              return (
                                <CheckCircleTwoTone
                                  style={{ fontSize: "18pt" }}
                                  twoToneColor="#52c41a"
                                />
                              );
                            } else {
                              return (
                                <ExclamationCircleTwoTone
                                  style={{ fontSize: "18pt" }}
                                  twoToneColor="#FF6100"
                                />
                              );
                            }
                          },
                        },
                      ]
                }
              />
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Health;
