import React, { Component } from "react";
import { Card, Col, Row, Typography } from "antd";
import {
  HeartOutlined,
  SlidersOutlined,
  ExperimentOutlined,
  DatabaseOutlined,
  ToolOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import DataPage from "../pages/data";

const { Meta } = Card;
const { Title } = Typography;

class MainScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <div>
        {this.props.authorized ? (
          <Row gutter={[24, 24]}>
            <Col
              gutter={[24, 24]}
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={8}
              xxl={8}
            >
              <Link to="health">
                <Card hoverable>
                  <Meta
                    description={
                      <div align="center">
                        <div>
                          <HeartOutlined
                            style={{
                              fontSize: "96px",
                              color: "#fc3c3c",
                              margin: "45px",
                            }}
                          />
                        </div>
                        <div>
                          <Title level={4}>Health & Control</Title>
                        </div>
                      </div>
                    }
                  />
                </Card>
              </Link>
            </Col>
            <Col
              gutter={[24, 24]}
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={8}
              xxl={8}
            >
              <Link to="data">
                <Card hoverable>
                  <Meta
                    description={
                      <div align="center">
                        <div>
                          <DatabaseOutlined
                            style={{
                              fontSize: "96px",
                              color: "#fc3c3c",
                              margin: "45px",
                            }}
                          />
                        </div>
                        <div>
                          <Title level={4}>Data</Title>
                        </div>
                      </div>
                    }
                  />
                </Card>
              </Link>
            </Col>

            <Col
              gutter={[24, 24]}
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={8}
              xxl={8}
            >
              <Link to="troubleshoot">
                <Card hoverable>
                  <Meta
                    description={
                      <div align="center">
                        <div>
                          <ToolOutlined
                            style={{
                              fontSize: "96px",
                              color: "#fc3c3c",
                              margin: "45px",
                            }}
                          />
                        </div>
                        <div>
                          <Title level={4}>Troubleshoot</Title>
                        </div>
                      </div>
                    }
                  />
                </Card>
              </Link>
            </Col>
            <Col
              gutter={[24, 24]}
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={8}
              xxl={8}
            >
              <Link to="sample">
                <Card hoverable>
                  <Meta
                    description={
                      <div align="center">
                        <div>
                          <ExperimentOutlined
                            style={{
                              fontSize: "96px",
                              color: "#fc3c3c",
                              margin: "45px",
                            }}
                          />
                        </div>
                        <div>
                          <Title level={4}>Sample</Title>
                        </div>
                      </div>
                    }
                  />
                </Card>
              </Link>
            </Col>
            {this.props.jp3_user ? (
              <Col
                gutter={[24, 24]}
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={8}
                xxl={8}
              >
                <Link to="config">
                  <Card hoverable>
                    <Meta
                      description={
                        <div align="center">
                          <div>
                            <SlidersOutlined
                              style={{
                                fontSize: "96px",
                                color: "#fc3c3c",
                                margin: "45px",
                              }}
                            />
                          </div>
                          <div>
                            <Title level={4}>Configuration</Title>
                          </div>
                        </div>
                      }
                    />
                  </Card>
                </Link>
              </Col>
            ) : null}
          </Row>
        ) : (
          <div>
            <DataPage
              darkmode={this.props.darkmode}
              getToken={this.props.getToken}
              authorized={this.props.authorized}
              jp3_user={this.props.jp3_user}
              width={this.props.width}
              height={this.props.height}
            />
          </div>
        )}
      </div>
    );
  }
}

export default MainScreen;
