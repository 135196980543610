import React, { useEffect, useRef, useState } from "react";
import {
  Table,
  Input,
  Popconfirm,
  Form,
  Typography,
  Button,
  Select,
  Space,
  InputNumber,
  Tooltip,
  Divider,
  Card,
} from "antd";
import { Option } from "antd/es/mentions";
import { EditOutlined, PlusOutlined } from "@ant-design/icons";

const reverseTypeKey = (input) => {
  return input === "both"
    ? "b"
    : input === "raw model"
    ? "r"
    : input === "output"
    ? "o"
    : input;
};

const typeOptions = (
  <Select.OptGroup label="Type Options">
    <Option value="raw model">{"raw model"}</Option>
    <Option value="output">{"output"}</Option>
    <Option value="both">{"both"}</Option>
  </Select.OptGroup>
);

const mathOptions = (
  <Select.OptGroup label="Math Options">
    <Option value="">{""}</Option>
    <Option value="((<C1>+<C2>+<C3>)*3)+<iC4>+<nC4>">
      {"Calculate deemed C4"}
    </Option>
    <Option value="<VPCR>*0.834">
      {"Convert VPCR4 to RVP (for pressurized cylinders)"}
    </Option>
    <Option value="<VPCR>*0.915">
      {"Convert VPCR4 to RVP (for nonpressurized cylinders)"}
    </Option>
    <Option value="<C1>*25.45+<C2>*6.11+<C3>*1.74+<iC4>*0.58+<nC4>*0.37+<iC5>*0.058+<nC5>*0.009-<C6+>*0.097">
      {"TVP (ASTM D2598)"}
    </Option>
    <Option value="141.5/(141.5/(<API_Gravity>+131.5)-0.000331*(<Temp>-60))-131.5">
      {"Uncorrected API Gravity"}
    </Option>
    <Option value="<Dry_BTU>*0.983">{"Wet BTU"}</Option>
    <Option value="<VPCR_raw>*6.895">{"VPCR (kpa)"}</Option>
    <Option value=" (ASTM D5191).	(<VPCR_raw>*6.895*0.965)-3.78">
      {"DVPE (kpa)"}
    </Option>
    <Option value="<VPCR_raw>*0.965-0.548">{"DVPE (psi)"}</Option>
    <Option value="Current Output = (Raw Value/F) + (1-1/F)*Previous Output">
      {"Rolling Average"}
    </Option>
  </Select.OptGroup>
);

let index = 0;

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  children,
  options,
  unitOp,
  ...restProps
}) => {
  const speciesOptions =
    options && options.species && options.species.length >= 1
      ? options.species.map((s) => <Option key={s.name}>{s.name}</Option>)
      : null;

  const [items, setItems] = useState([]);
  const [name, setName] = useState("");

  const onNameChange = (event) => {
    setName(event.target.value);
  };

  const addItem = (e) => {
    e.preventDefault();
    setItems([...items, name || `New item ${index++}`]);
    setName("");
  };
  const inputNode =
    inputType === "speciesOption" ? (
      <Select
        showSearch
        style={{ width: 150 }}
        placeholder="Select species"
        optionFilterProp="children"
        filterOption={(input, option) => {
          if (option.children) {
            return (
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            );
          }
        }}
      >
        <Select.OptGroup label={"Species Options"}>
          {speciesOptions}
        </Select.OptGroup>
      </Select>
    ) : inputType === "unitOption" ? (
      <Select
        showSearch
        style={{ width: 80 }}
        placeholder="Select unit"
        optionFilterProp="children"
        filterOption={(input, option) => {
          if (option.children) {
            return (
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            );
          }
        }}
      >
        <Select.OptGroup label="Unit Options">
          {unitOp && unitOp.unitOp && unitOp.unitOp.length >= 1
            ? unitOp.unitOp.map((u) => <Option key={u}>{u}</Option>)
            : null}
        </Select.OptGroup>
      </Select>
    ) : inputType === "typeOption" ? (
      <Select
        showSearch
        style={{ width: 90 }}
        placeholder="Select type"
        optionFilterProp="children"
        filterOption={(input, option) => {
          if (option.children) {
            return (
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            );
          }
        }}
      >
        {typeOptions}
      </Select>
    ) : inputType === "mathOption" ? (
      <Select
        showSearch
        style={{ width: 220 }}
        placeholder="Select math operation"
        dropdownRender={(menu) => (
          <>
            {menu}
            <Divider style={{ margin: "8px 0" }} />
            <Space align="center" style={{ padding: "0 8px 4px" }}>
              <Input
                placeholder="Custom math"
                value={name}
                onChange={onNameChange}
              />
              <Typography.Link
                onClick={addItem}
                style={{ whiteSpace: "nowrap" }}
              >
                <PlusOutlined /> Add item
              </Typography.Link>
            </Space>
          </>
        )}
        optionFilterProp="children"
        filterOption={(input, option) => {
          if (option.children) {
            return (
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            );
          }
        }}
      >
        {mathOptions}
        {
          <Select.OptGroup label="Added Options">
            {items.map((item) => (
              <Option key={item}>{item}</Option>
            ))}
          </Select.OptGroup>
        }
      </Select>
    ) : inputType === "number" || inputType === "alarm" ? (
      <InputNumber min={0} style={{ width: 65 }} />
    ) : (
      <Input />
    );
  return (
    <td {...restProps}>
      {editing && (inputType === "number" || inputType === "mathOption") ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: false,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const ModelsForm = (props) => {
  const [form] = Form.useForm();

  const [data, setData] = useState(props.initData);
  const [count, setCount] = useState(props.initCount);
  const [species, setSpecies] = useState(props.species_config);
  const [unitOp, setUnitOp] = useState([]);
  const [editingKey, setEditingKey] = useState("");

  useEffect(() => {
    setData(props.initData);
    setCount(props.initCount);
  }, [props.initData]);

  useEffect(() => {
    setSpecies(props.species_config);
  }, [props.species_config]);

  const searchInput = useRef(null);

  const getColumnSearchProps = (dataIndex, name) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <div style={{ padding: 8 }}>
        {dataIndex == "type" ? (
          <Select
            ref={searchInput}
            showSearch
            placeholder={`Edit all ${name}`}
            optionFilterProp="children"
            filterOption={(input, option) => {
              if (option.children) {
                return (
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                );
              }
            }}
            onChange={(e) => {
              setSelectedKeys(e ? [e] : []);
            }}
            style={{ marginBottom: 8, display: "block" }}
          >
            {dataIndex == "type" ? typeOptions : null}
          </Select>
        ) : (
          <InputNumber
            min={1}
            onChange={(e) => {
              setSelectedKeys(e ? [e] : []);
            }}
            style={{ marginBottom: 8, display: "block" }}
          />
        )}
        <Space>
          <Popconfirm
            title={`Confirm edit of ${name}`}
            onConfirm={() => handleSearch(selectedKeys, confirm, dataIndex)}
            size="small"
            style={{ width: 90 }}
          >
            <Button
              type="primary"
              icon={<EditOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Edit All
            </Button>
          </Popconfirm>
          <Typography.Link
            onClick={() => {
              cancel();
              confirm({ closeDropdown: true });
            }}
            style={{
              marginRight: 8,
            }}
          >
            Cancel
          </Typography.Link>
        </Space>
      </div>
    ),
    filterIcon: () => (
      <Tooltip title={`Edit ${name} of all rows`}>
        <EditOutlined style={{ color: "#1890ff" }} />
      </Tooltip>
    ),
    render: (text) => text,
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    const newData = data.map((d) => ({
      ...d,
      [dataIndex]: selectedKeys[0],
    }));
    setData(newData);
    newData.map((d) => {
      d["type"] = reverseTypeKey(d["type"]);
      d["unit"] = d["unit"].replace(/\s+/g, "");
    });
    props.onFinish(newData, props.analysis_point);
    props.refreshConfig();
    confirm();
  };

  const handleDelete = (key) => {
    const newData = data.filter((item) => item.key !== key);
    setData(newData);
    setCount(count + 1);
    props.onFinish(newData, props.analysis_point);
    props.refreshConfig();
  };

  const handleAdd = () => {
    const newData = props.handleAdd(count.toString());
    setData([...data, newData]);
    setCount(count + 1);
    edit(newData);
  };

  const handleFormValuesChange = (changedValues) => {
    const formFieldName = Object.keys(changedValues)[0];
    if (formFieldName === "species") {
      const speciesOb = species.find(
        (o) => o.name === changedValues[formFieldName]
      );
      if (speciesOb) {
        setUnitOp(speciesOb.units);
        form.setFieldsValue({ unit: "" });
      }
    }
  };

  const isEditing = (record) => record.key === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      species: "",
      unit: "",
      type: "",
      digits: "",
      math: "",
      t_warn: "",
      t_alarm: "",
      q_warn: "",
      q_alarm: "",
      modbus: "",
      modbus_average: "",
      modbus_model_alarm: "",
      modbus_truck: "",
      ...record,
    });
    const speciesOb = species.find((o) => o.name === record.species);
    setUnitOp(speciesOb.units);
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        setData(newData);
        setEditingKey("");
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey("");
      }
      newData.map((d) => {
        d["type"] = reverseTypeKey(d["type"]);
      });
      props.onFinish(newData, props.analysis_point);
      props.refreshConfig();
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const columns = [
    {
      title: "Species",
      dataIndex: "species",
      editable: true,
      render: (text) => {
        return <div>{text}</div>;
      },
    },
    {
      title: "Unit",
      dataIndex: "unit",
      editable: true,
    },
    {
      title: "Type",
      dataIndex: "type",
      width: "10%",
      editable: true,
      ...getColumnSearchProps("type", "type"),
    },
    {
      title: "Digits",
      dataIndex: "digits",
      width: "6%",
      editable: true,
      ...getColumnSearchProps("digits", "digits"),
    },
    {
      title: "T2 Warning",
      dataIndex: "t_warn",
      width: "6%",
      editable: true,
      ...getColumnSearchProps("t_warn", "T2 warning"),
    },
    {
      title: "T2 Alarm",
      dataIndex: "t_alarm",
      width: "6%",
      editable: true,
      ...getColumnSearchProps("t_alarm", "T2 alarm"),
    },
    {
      title: "Q Warning",
      dataIndex: "q_warn",
      width: "6%",
      editable: true,
      ...getColumnSearchProps("q_warn", "Q warning"),
    },
    {
      title: "Q Alarm",
      dataIndex: "q_alarm",
      width: "6%",
      editable: true,
      ...getColumnSearchProps("q_alarm", "Q alarm"),
    },
    {
      title: "ModBus",
      dataIndex: "modbus",
      width: "10%",
      editable: true,
    },
    {
      title: "ModBus Average",
      dataIndex: "modbus_average",
      width: "8%",
      editable: true,
    },
    {
      title: "ModBus Model Alarm",
      dataIndex: "modbus_model_alarm",
      width: "8%",
      editable: true,
      ...getColumnSearchProps("modbus_model_alarm", "ModBus model alarm"),
    },
    {
      title: "ModBus Truck",
      dataIndex: "modbus_truck",
      width: "8%",
      editable: true,
      ...getColumnSearchProps("modbus_truck", "ModBus truck"),
    },
    {
      title: "Math",
      dataIndex: "math",
      width: "20%",
      editable: true,
    },
    {
      title: "Action",
      dataIndex: "operation",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record.key)}
              style={{
                marginRight: 8,
              }}
            >
              Save
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <span>
            <Typography.Link
              disabled={editingKey !== ""}
              onClick={() => edit(record)}
              style={{
                marginRight: 8,
              }}
            >
              Edit
            </Typography.Link>
            <Popconfirm
              title="Confirm delete?"
              onConfirm={() => handleDelete(record.key)}
            >
              <a>Delete</a>
            </Popconfirm>
          </span>
        );
      },
    },
  ];
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        options: { species },
        unitOp: { unitOp },
        inputType:
          col.dataIndex === "species"
            ? "speciesOption"
            : col.dataIndex === "unit"
            ? "unitOption"
            : col.dataIndex === "type"
            ? "typeOption"
            : col.dataIndex === "digits" ||
              col.dataIndex === "modbus" ||
              col.dataIndex === "modbus_average" ||
              col.dataIndex === "modbus_model_alarm" ||
              col.dataIndex === "modbus_truck"
            ? "number"
            : col.dataIndex === "t_warn" ||
              col.dataIndex === "t_alarm" ||
              col.dataIndex === "q_warn" ||
              col.dataIndex === "q_alarm"
            ? "alarm"
            : col.dataIndex === "math"
            ? "mathOption"
            : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });
  return (
    <Card
      style={{ marginBottom: "25px", marginTop: "0px" }}
      title="Model Configuration"
      extra={
        <Button
          onClick={handleAdd}
          style={{
            margin: "5px",
          }}
        >
          Add new configuration
        </Button>
      }
    >
      <Form
        form={form}
        component={false}
        onValuesChange={handleFormValuesChange}
      >
        <Table
          scroll={{ x: 1300 }}
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          bordered
          size="small"
          dataSource={data}
          columns={mergedColumns}
          rowClassName="editable-row"
          pagination={{
            onChange: cancel,
          }}
        />
      </Form>
    </Card>
  );
};

export default ModelsForm;
