import React, { Component } from "react";
import { API_URL } from "../../../config.js";
import VCONSystemForm from "./VCONSystemForm";
import VCONSpectrometersForm from "./VCONSpectrometersForm";
import VCONMultiplexerForm from "./VCONMultiplexerForm";
import VCONModbusForm from "./VCONModbusForm";
import VCONMySQLForm from "./VCONMySQLForm";
import VCONAnalysesForm from "./VCONAnalysesForm";
import {Tabs, message} from "antd";
import _ from "lodash";

const { TabPane } = Tabs;

class VCON extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vcon_config: {},
    };
    this.refreshConfig = this.refreshConfig.bind(this);
    this.saveConfig = this.saveConfig.bind(this);
  }

  componentDidMount() {
    this.refreshConfig();
  }

  refreshConfig() {
    fetch(API_URL + "/data/vcon_config/", {
      method: "GET",
      headers: {
        Authorization: "Bearer " + this.props.getToken(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((res_config) => res_config.json())
      .then((vcon_config) => {
        this.setState({ vcon_config });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  saveConfig(key, values) {
    let vcon_config = _.cloneDeep(this.state.vcon_config);
    vcon_config['vcon_configuration'][key] = values;
    this.setState({ vcon_config }, function () {
      fetch(API_URL + "/data/save_vcon_config/", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + this.props.getToken(),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(vcon_config),
      })
          .then((res) => res.json())
          .then(() => {
            message.success('Save Successful!');
            this.refreshConfig();
          })
          .catch((error) => {
            console.error(error);
          });
    });
  }

  render() {
    let initialValues = {};
    if (
        this.state.vcon_config &&
        this.state.vcon_config["vcon_configuration"] &&
        this.state.vcon_config["vcon_configuration"]["system"]
    ) {
      initialValues["analyzer_sn"] =
          this.state.vcon_config["vcon_configuration"]["system"]["analyzer_sn"];
      initialValues["analyzer_tag"] =
          this.state.vcon_config["vcon_configuration"]["system"]["analyzer_tag"];
      initialValues["analyzer_ip"] =
          this.state.vcon_config["vcon_configuration"]["system"]["analyzer_ip"];
      initialValues["remote_ip"] =
          this.state.vcon_config["vcon_configuration"]["system"]["remote_ip"];
      initialValues["spectrometer_one_emulate"] =
          this.state.vcon_config["vcon_configuration"]["spectrometers"][0]["emulate"];
      initialValues["spectrometer_one_type"] =
          this.state.vcon_config["vcon_configuration"]["spectrometers"][0]["type"];
      initialValues["spectrometer_one_serial_number"] =
          this.state.vcon_config["vcon_configuration"]["spectrometers"][0][
              "serial_number"
              ];
      initialValues["spectrometer_one_diagnostics1"] =
          this.state.vcon_config["vcon_configuration"]["spectrometers"][0][
              "diagnostics1"
              ];
      initialValues["spectrometer_one_diagnostics2"] =
          this.state.vcon_config["vcon_configuration"]["spectrometers"][0][
              "diagnostics2"
              ];
      initialValues["spectrometer_one_power_modbus"] =
          this.state.vcon_config["vcon_configuration"]["spectrometers"][0][
              "power_modbus"
              ];
      initialValues["spectrometer_one_connection"] =
          this.state.vcon_config["vcon_configuration"]["spectrometers"][0]["connection"];
      initialValues["spectrometer_one_ip"] =
          this.state.vcon_config["vcon_configuration"]["spectrometers"][0]["ip"];
      initialValues["spectrometer_one_cal_modbus"] =
          this.state.vcon_config["vcon_configuration"]["spectrometers"][0]["cal_modbus"];
      initialValues["spectrometer_two_emulate"] =
          this.state.vcon_config["vcon_configuration"]["spectrometers"][1]["emulate"];
      initialValues["spectrometer_two_type"] =
          this.state.vcon_config["vcon_configuration"]["spectrometers"][1]["type"];
      initialValues["spectrometer_two_serial_number"] =
          this.state.vcon_config["vcon_configuration"]["spectrometers"][1][
              "serial_number"
              ];
      initialValues["spectrometer_two_diagnostics1"] =
          this.state.vcon_config["vcon_configuration"]["spectrometers"][1][
              "diagnostics1"
              ];
      initialValues["spectrometer_two_diagnostics2"] =
          this.state.vcon_config["vcon_configuration"]["spectrometers"][1][
              "diagnostics2"
              ];
      initialValues["spectrometer_two_power_modbus"] =
          this.state.vcon_config["vcon_configuration"]["spectrometers"][1][
              "power_modbus"
              ];
      initialValues["spectrometer_two_connection"] =
          this.state.vcon_config["vcon_configuration"]["spectrometers"][1]["connection"];
      initialValues["spectrometer_two_ip"] =
          this.state.vcon_config["vcon_configuration"]["spectrometers"][1]["ip"];
      initialValues["emulate"] =
          this.state.vcon_config["vcon_configuration"]["multiplexer"]["emulate"];
      initialValues["laser_fiber"] =
          this.state.vcon_config["vcon_configuration"]["multiplexer"]["laser_fiber"];
      initialValues["com"] =
          this.state.vcon_config["vcon_configuration"]["multiplexer"]["com"];
      initialValues["detector_fiber"] =
          this.state.vcon_config["vcon_configuration"]["multiplexer"]["detector_fiber"];
      initialValues["node"] =
          this.state.vcon_config["vcon_configuration"]["modbus"]["node"];
      initialValues["port1"] =
          this.state.vcon_config["vcon_configuration"]["modbus"]["port1"];
      initialValues["swapped_fp"] =
          this.state.vcon_config["vcon_configuration"]["modbus"]["swapped_fp"];
      initialValues["baudRate"] =
          this.state.vcon_config["vcon_configuration"]["modbus"]["baudRate"];
      initialValues["port2"] =
          this.state.vcon_config["vcon_configuration"]["modbus"]["port2"];
      initialValues["directory"] =
          this.state.vcon_config["vcon_configuration"]["mysql"]["directory"];
      initialValues["name"] =
          this.state.vcon_config["vcon_configuration"]["mysql"]["name"];
      initialValues["frequency"] =
          this.state.vcon_config["vcon_configuration"]["mysql"]["frequency"];
      initialValues["count"] =
          this.state.vcon_config["vcon_configuration"]["analyses"]["count"];
      initialValues["cycle"] =
          this.state.vcon_config["vcon_configuration"]["analyses"]["cycle"];
      initialValues["trigger"] =
          this.state.vcon_config["vcon_configuration"]["analyses"]["trigger"];
      initialValues["valve_interval"] =
          this.state.vcon_config["vcon_configuration"]["analyses"]["valve_interval"];
      initialValues["timer_enabled"] =
          this.state.vcon_config["vcon_configuration"]["analyses"]["timer_enabled"];
      initialValues["status_modbus"] =
          this.state.vcon_config["vcon_configuration"]["analyses"]["status_modbus"];
      initialValues["beat_modbus"] =
          this.state.vcon_config["vcon_configuration"]["analyses"]["beat_modbus"];
      initialValues["state_modbus"] =
          this.state.vcon_config["vcon_configuration"]["analyses"]["state_modbus"];
      initialValues["result_output_mode"] =
          this.state.vcon_config["vcon_configuration"]["analyses"]["result_output_mode"];
      initialValues["result_output_block_value"] =
          this.state.vcon_config["vcon_configuration"]["analyses"][
              "result_output_block_value"
              ];
      initialValues["truck_avg_output_method"] =
          this.state.vcon_config["vcon_configuration"]["analyses"][
              "truck_avg_output_method"
              ];
      initialValues["truck_avg_output_truck_loading_value"] =
          this.state.vcon_config["vcon_configuration"]["analyses"][
              "truck_avg_output_truck_loading_value"
              ];
      initialValues["truck_avg_output_bad_spectra_value"] =
          this.state.vcon_config["vcon_configuration"]["analyses"][
              "truck_avg_output_bad_spectra_value"
              ];
      initialValues["background_low"] =
          this.state.vcon_config["vcon_configuration"]["analyses"]["background_low"];
      initialValues["background_high"] =
          this.state.vcon_config["vcon_configuration"]["analyses"]["background_high"];
      initialValues["background_noisy"] =
          this.state.vcon_config["vcon_configuration"]["analyses"]["background_noisy"];
      initialValues["background_methane"] =
          this.state.vcon_config["vcon_configuration"]["analyses"]["background_methane"];
      initialValues["average_method"] =
          this.state.vcon_config["vcon_configuration"]["analyses"]["average_method"];
      initialValues["average_time_hour"] =
          this.state.vcon_config["vcon_configuration"]["analyses"]["average_time_hour"];
      initialValues["average_time_min"] =
          this.state.vcon_config["vcon_configuration"]["analyses"]["average_time_min"];
      initialValues["average_time_sec"] =
          this.state.vcon_config["vcon_configuration"]["analyses"]["average_time_sec"];
      initialValues["flow_meter_enabled"] =
          this.state.vcon_config["vcon_configuration"]["analyses"]["flow_meter_enabled"];
    }
    if (
      this.state.vcon_config &&
      this.state.vcon_config["vcon_configuration"] &&
      this.state.vcon_config["vcon_configuration"]["system"]
    ) {
      return (
        <div>
          <Tabs defaultActiveKey="1" type="line">
            <TabPane tab="System" key="1">
              <VCONSystemForm initialValues={initialValues} onVCONFinish={this.saveConfig} />
            </TabPane>
            <TabPane tab="Spectrometers" key="2">
              <VCONSpectrometersForm data={initialValues} onVCONFinish={this.saveConfig} />
            </TabPane>
            <TabPane tab="MUX/Switches" key="3">
              <VCONMultiplexerForm data={initialValues} onVCONFinish={this.saveConfig} />
            </TabPane>
            <TabPane tab="Modbus" key="4">
              <VCONModbusForm data={initialValues} onVCONFinish={this.saveConfig} />
            </TabPane>
            <TabPane tab="MySQL" key="5">
              <VCONMySQLForm initialValues={initialValues} onVCONFinish={this.saveConfig} />
            </TabPane>
            <TabPane tab="Analyses" key="6">
              <VCONAnalysesForm data={initialValues} onVCONFinish={this.saveConfig} />
            </TabPane>
          </Tabs>
        </div>
      );
    } else {
      return <div />;
    }
  }
}

export default VCON;
