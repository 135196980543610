import React from "react";
import VCON from "../components/Configuration/VCON/VCON";

export default function VCONConfigPage(props) {
  return (
    <div>
      <VCON {...props} />
    </div>
  );
}
