import { Button, Form, Input, Alert } from "antd";
import React from "react";

var bcrypt = require("bcryptjs");

const AuthForm = (props) => {
  const [form] = Form.useForm();
  function onFinish(values) {
    const hashedPassword = bcrypt.hashSync(
      values.password,
      "$2a$10$vUF9.ihfQZJskyhO2.QH/e"
    ); // Salt.
    values.password = hashedPassword;
    props.onFinishAuth(values);
  }
  return (
    <div>
      <div align="center">
        <Alert
          style={{ marginBottom: "20px" }}
          description="Please contact JP3 Support at support@jp3.com or call 512-537-8450 for assistance."
          type="info"
        />
      </div>
      <Form
        id="authForm"
        layout="horizontal"
        form={form}
        onFinish={(values) => onFinish(values)}
        size="large"
      >
        <Form.Item
          label="Username"
          name="username"
          rules={[{ required: true }]}
        >
          <Input placeholder="Username" />
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true }]}
        >
          <Input.Password placeholder="Password" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" style={{ float: "right" }}>
            Login
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AuthForm;
