import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Upload,
  Table,
  message,
  Input,
  Form,
  Typography,
  Popconfirm,
  Col,
  Row,
  Select,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { API_URL } from "../../../config";
import { Option } from "antd/es/mentions";

const EditableCell = ({
  record,
  editing,
  dataIndex,
  title,
  children,
  ...restProps
}) => {
  const file = record && record.name ? record.name : null;
  const ext = file
    ? file.endsWith(".mat")
      ? ".mat"
      : file.endsWith(".py")
      ? ".py"
      : null
    : null;
  const inputNode = <Input suffix={ext} />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const ModelDeployment = (props) => {
  const [form] = Form.useForm();
  // const [fileForm] = Form.useForm();
  const [dir, setDir] = useState(props.dir_data);
  const [fileConfig, setFileConfig] = useState([]);
  const [editingKey, setEditingKey] = useState("");

  useEffect(() => {
    props.refreshTableData(props.analysis_point);
  }, []);

  useEffect(() => {
    const result = props.data.map((v) => {
      const dis = v.type === "output" ? true : false;
      return {
        ...v,
        ...props.file_data.find((sp) => sp.name === v.species),
        ...{ disabled: dis },
      };
    });
    setFileConfig(result);
  }, [props.file_data]);

  useEffect(() => {
    setDir(props.dir_data);
  }, [props.dir_data]);

  const dirOptions = dir
    ? dir.map((s) => (
        <Option key={s.key} value={s.name}>
          {s.name}
        </Option>
      ))
    : null;

  const isEditing = (record) => record.key === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      file: "",
      ...record,
    });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const save = async (record) => {
    try {
      const row = await form.validateFields();
      let values = {};
      values["filename"] = record.name;
      values["new_filename"] = row.file + record.ext;
      values["analysis_point"] = props.analysis_point;

      const isFound = dir.some((element) => {
        if (element.name === values["new_filename"]) {
          return true;
        }
      });
      if (isFound) {
        message.error("Filename already exists");
      } else {
        props.onChangeFileName(values);
        setEditingKey("");
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const handleUpload = (info) => {
    let file = info.file;
    const formData = new FormData();
    formData.append("file", file);
    formData.append("analysis_point", props.analysis_point);
    fetch(API_URL + "/actions/action/upload_model", {
      method: "POST",
      headers: {},
      body: formData,
    })
      .then((res) => res.json())
      .then(() => {
        message.success("Upload Successful.");
        props.refreshTableData(props.analysis_point);
      })
      .catch(() => {
        message.error("upload Failed.");
      })
      .finally(() => {});
  };

  const handleDelete = (filename) => {
    let values = {};
    values["filename"] = filename;
    values["analysis_point"] = props.analysis_point;
    fetch(API_URL + "/actions/action/archive_model", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values),
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.result === true) {
          props.refreshTableData(values.analysis_point);
          message.success("File Archived");
        } else {
          message.error("Unsuccessful Archive: " + json.exception);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const columns = [
    {
      title: "Model Directory",
      colSpan: 2,
      dataIndex: "file",
      editable: true,
      render: (_, record) => {
        return record && record.name ? record.name : null;
      },
    },
    {
      title: "Edit Name",
      dataIndex: "operation",
      colSpan: 0,
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record)}
              style={{
                marginRight: 8,
              }}
            >
              Save
            </Typography.Link>
            <Popconfirm title="Confirm cancel?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <span>
            <Typography.Link
              disabled={editingKey !== ""}
              onClick={() => edit(record)}
            >
              <Button type={"default"}>Edit Name</Button>
            </Typography.Link>
            <Popconfirm
              title="Archive model?"
              onConfirm={() => handleDelete(record.name)}
            >
              <Button type={"link"}>Archive File</Button>
            </Popconfirm>
          </span>
        );
      },
    },
  ];

  const dataColumns = [
    {
      title: "File Configuration",
      children: [
        {
          title: "Species",
          dataIndex: "species",
        },
        {
          title: "Unit",
          dataIndex: "unit",
        },
        {
          title: "Type",
          dataIndex: "type",
        },
        { title: "Current Model", dataIndex: "file_location" },
        {
          title: "Select New Model",
          dataIndex: "file_location",
          render: (record, index) => {
            return (
              <Form
                // form={fileForm}
                // initialValues={{
                //   file: record ? record.split("\\").pop() : null,
                // }}
                onFinish={(values) => props.onSubmit(values, props.analysis_point)}
              >
                <Input.Group compact>
                  <Form.Item
                    name="file"
                    initialValue={record ? record.split("\\").pop() : null}
                  >
                    <Select
                      showSearch
                      style={{ width: 150 }}
                      placeholder="Select model file"
                      optionFilterProp="children"
                      disabled={index.disabled}
                      // defaultValue={record ? record.split("\\").pop() : null}
                      filterOption={(input, option) => {
                        if (option.children) {
                          return (
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          );
                        }
                      }}
                    >
                      <Select.OptGroup label={"Model Options"}>
                        {dirOptions}
                      </Select.OptGroup>
                    </Select>
                  </Form.Item>
                  <Form.Item name="file_location" initialValue={record} />
                  <Form.Item name="index" initialValue={index.index} />
                  <Form.Item name="name" initialValue={index.name} />
                  <Form.Item>
                    <Button
                      htmlType="submit"
                      disabled={index.disabled}
                      type="primary"
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </Input.Group>
              </Form>
            );
          },
        },
      ],
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <Card
      style={{ marginBottom: "25px", marginTop: "0px" }}
      title="Model Deployment"
      extra={
        <Upload
          beforeUpload={() => false}
          onChange={(info) => handleUpload(info)}
        >
          <Button icon={<UploadOutlined />}>Upload model file</Button>
        </Upload>
      }
    >
      <Form form={form} component={false}>
        <Row>
          <Col span={12}>
            <Card type={"inner"} style={{ marginRight: "8px" }}>
              <Table
                size="small"
                components={{
                  body: {
                    cell: EditableCell,
                  },
                }}
                rowClassName="editable-row"
                pagination={{
                  onChange: cancel,
                }}
                dataSource={dir}
                columns={mergedColumns}
              />
            </Card>
          </Col>
          <Col span={12}>
            <Card type={"inner"} style={{ marginRight: "8px" }}>
              <Table
                size="small"
                dataSource={fileConfig}
                columns={dataColumns}
              ></Table>
            </Card>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default ModelDeployment;
