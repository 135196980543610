import React, { Component } from "react";
import { message, Card, Col, Row, Typography, Popconfirm, Alert } from "antd";
import {
  DesktopOutlined,
  ApiOutlined,
  IssuesCloseOutlined,
  ContactsOutlined,
} from "@ant-design/icons";
import { API_URL, future_features } from "../../config.js";
import Iframe from "react-iframe";

const { Meta } = Card;
const { Title } = Typography;

class Troubleshoot extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.restartServices = this.restartServices.bind(this);
    this.rebootComputer = this.rebootComputer.bind(this);
  }

  restartServices() {
    message.loading({
      content: "Services will be restarted. Please wait 30 seconds.",
      duration: 30,
    });

    console.log("Restarting Services");
    fetch(API_URL + "/actions/action/restart_services", {
      method: "POST",
      headers: {
        Authorization: "Bearer " + this.props.getToken(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
    })
      .then((res) => res.json())
      .then((json) => {
        console.log(json);
        if (json.length === 0) {
          message.success("Services have been restarted.");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  rebootComputer() {
    fetch(API_URL + "/actions/action/reboot_computer", {
      method: "POST",
      headers: {
        Authorization: "Bearer " + this.props.getToken(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.length === 0) {
          message.loading({
            content:
              "Computer has rebooted. Please wait 5 minutes for startup...",
            duration: 60,
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  componentDidMount() {}

  render() {
    return (
      <div>
        <Row gutter={[24, 24]}>
          <Col
            gutter={[24, 24]}
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
            xxl={24}
          >
            <div align="center">
              <Alert
                message="Contact JP3 Support"
                description="If you continue to experience issues, please contact JP3 Support at support@jp3.com or call 512-537-8450"
                type="info"
              />
            </div>
          </Col>

          {future_features ? (
            <Col
              gutter={[24, 24]}
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
            >
              <Card hoverable>
                <Meta
                  description={
                    <div align="center">
                      <div>
                        <IssuesCloseOutlined
                          style={{
                            fontSize: "96px",
                            color: "#fc3c3c",
                            margin: "45px",
                          }}
                        />
                      </div>
                      <div>
                        <Title level={4}>Documentation</Title>
                      </div>
                    </div>
                  }
                />
              </Card>
            </Col>
          ) : null}

          {future_features ? (
            <Col
              gutter={[24, 24]}
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
            >
              <Card hoverable>
                <Meta
                  description={
                    <div align="center">
                      <div>
                        <ContactsOutlined
                          style={{
                            fontSize: "96px",
                            color: "#fc3c3c",
                            margin: "45px",
                          }}
                        />
                      </div>
                      <div>
                        <Title level={4}>Contact Support</Title>
                      </div>
                    </div>
                  }
                />
              </Card>
            </Col>
          ) : null}
          <Col
            gutter={[24, 24]}
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            xxl={12}
          >
            <Popconfirm
              title="Are you sure that you would like to restart all services?"
              onConfirm={this.restartServices}
              okText="Yes"
              cancelText="No"
            >
              <Card hoverable>
                <Meta
                  description={
                    <div align="center">
                      <div>
                        <ApiOutlined
                          style={{
                            fontSize: "96px",
                            color: "#fc3c3c",
                            margin: "45px",
                          }}
                        />
                      </div>
                      <div>
                        <Title level={4}>Restart Services</Title>
                      </div>
                    </div>
                  }
                />
              </Card>
            </Popconfirm>
          </Col>

          <Col
            gutter={[24, 24]}
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            xxl={12}
          >
            <Popconfirm
              title="Are you sure that you would like to reboot the onboard computer?"
              onConfirm={this.rebootComputer}
              okText="Yes"
              cancelText="No"
            >
              <Card hoverable>
                <Meta
                  description={
                    <div align="center">
                      <div>
                        <DesktopOutlined
                          style={{
                            fontSize: "96px",
                            color: "#fc3c3c",
                            margin: "45px",
                          }}
                        />
                      </div>
                      <div>
                        <Title level={4}>Reboot Computer</Title>
                      </div>
                    </div>
                  }
                />
              </Card>
            </Popconfirm>
          </Col>
          <Col
            gutter={[24, 24]}
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
            xxl={24}
          >
            <Card
              title={
                <a href="document.pdf" target="_blank">
                  Documentation
                </a>
              }
            >
              <Iframe
                width={this.props.width - 100}
                height={this.props.height}
                url="document.pdf"
              />
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Troubleshoot;
