import React, { Component } from "react";
import { API_URL } from "../../../config.js";
import ModelsForm from "./ModelsForm";
import ModelDeploy from "./ModelDeploy";
import { message, Tabs } from "antd";

const typeKey = (input) => {
  return input === "b"
    ? "both"
    : input === "r"
    ? "raw model"
    : input === "o"
    ? "output"
    : input;
};

const { TabPane } = Tabs;

class Models extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vcon_config: {},
      species_config: [],
      initData: [],
      fileData: [],
      initCount: [],
      dir_data: [],
    };
    this.refreshConfig = this.refreshConfig.bind(this);
    this.refreshTableData = this.refreshTableData.bind(this);
    this.onChangeFileName = this.onChangeFileName.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onFinish = this.onFinish.bind(this);
    this.handleAdd = this.handleAdd.bind(this);
  }

  componentDidMount() {
    this.refreshConfig();
  }

  refreshConfig() {
    fetch(API_URL + "/data/species_config/", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((res_config) => res_config.json())
      .then((species_config) => {
        this.setState({ species_config });
      })
      .catch((error) => {
        console.error(error);
      });
    fetch(API_URL + "/data/vcon_config/", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((res_config) => res_config.json())
      .then((vcon_config) => {
        let results = [];
        let initData = [];
        let fileData = [];
        let initCount = [];
        if (
          vcon_config &&
          vcon_config["vcon_configuration"] &&
          vcon_config["vcon_configuration"]["analysis_points"]
        ) {
          vcon_config["vcon_configuration"]["analysis_points"].map((ap) => {
            let apData = [];
            let count = 0;
            if (ap["results"]) {
              results = ap["results"];
              results.map((r) => {
                const newData = {
                  key: count.toString(),
                  species: r["name"],
                  unit: r["unit"],
                  type: typeKey(r["type"]),
                  digits: r["digits"],
                  math: r["math"],
                  t_warn: r["t2_high_warn"],
                  t_alarm: r["t2_high_fault"],
                  q_warn: r["q_high_warn"],
                  q_alarm: r["q_high_fault"],
                  modbus: r["modbus"],
                  modbus_average: r["modbus_average"],
                  modbus_model_alarm: r["modbus_model_alarm"],
                  modbus_truck: r["modbus_truck"],
                };
                apData.push(newData);
                count = count + 1;
              });
              initData.push(apData);
              fileData.push(
                ap["model_configuration"][1]["children_result_models"]
              );
              initCount.push(count);
            }
          });
        }
        this.setState({ vcon_config, initData, fileData, initCount });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  refreshTableData = (analysis_point) => {
    fetch(API_URL + "/data/analysis_dir_contents", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        analysis_point: analysis_point,
      }),
    })
      .then((res) => res.json())
      .then((result) => {
        let dir_data = [];
        result["data"].forEach(function (item, index) {
          const ext = item
            ? item.endsWith(".mat")
              ? ".mat"
              : item.endsWith(".py")
              ? ".py"
              : null
            : null;
          const file = ext ? item.replace(ext, "") : null;
          dir_data.push({ key: index, name: item, ext, file });
        });

        this.setState({ dir_data });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  onChangeFileName(values) {
    fetch(API_URL + "/actions/action/change_model_name", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values),
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.result === true) {
          this.refreshTableData(values.analysis_point);
          message.success("Filename Updated");
        } else {
          message.error("Unsuccessful Update: " + json.exception);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  onSubmit(values, analysis_point) {
    let i = analysis_point - 1;
    let lastIndex = values["file_location"].lastIndexOf("\\");
    values["new_file_location"] =
      values["file_location"].slice(0, lastIndex + 1) + values["file"];
    fetch(API_URL + "/data/update_model_file", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        index: i,
        values: values,
      }),
    })
      .then(() => message.success("Config Updated"))
      .catch((error) => {
        console.error(error);
      });
  }

  onFinish(values, analysis_point) {
    let i = analysis_point - 1;
    fetch(API_URL + "/data/update_model_config", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        index: i,
        values: values,
      }),
    })
      .then(() => {
        message.success("Config Updated");
      })
      .catch((error) => {
        console.error(error);
      });
  }

  handleAdd(countString) {
    const newData = {
      key: countString,
      species: "C1",
      unit: `lv%`,
      type: "both",
      digits: 2,
      math: "",
      t_warn: 1,
      t_alarm: 5,
      q_warn: 3,
      q_alarm: 25,
      modbus: 7000,
      modbus_average: 8000,
      modbus_model_alarm: 0,
      modbus_truck: 0,
    };
    return newData;
  }

  render() {
    return (
      <div>
        {this.state.initCount &&
        this.state.initCount.length > 0 &&
        this.state.initData &&
        this.state.initData.length > 0 ? (
          <Tabs defaultActiveKey="0" type="card">
            {this.state.initData.map((data, i) => {
              return (
                <TabPane tab={`Analysis ${i + 1}`} key={i}>
                  <ModelsForm
                    refreshConfig={this.refreshConfig}
                    onFinish={this.onFinish}
                    handleAdd={this.handleAdd}
                    vcon_config={this.state.vcon_config}
                    species_config={this.state.species_config}
                    initData={data}
                    initCount={this.state.initCount[i]}
                    analysis_point={i + 1}
                  />
                  <ModelDeploy
                    analysis_point={i + 1}
                    refreshTableData={this.refreshTableData}
                    onChangeFileName={this.onChangeFileName}
                    onSubmit={this.onSubmit}
                    dir_data={this.state.dir_data}
                    file_data={this.state.fileData[i]}
                    data={data}
                  ></ModelDeploy>
                </TabPane>
              );
            })}
          </Tabs>
        ) : null}
      </div>
    );
  }
}

export default Models;
