import React, { Component } from "react";
import {
  message,
  Alert,
  Modal,
  Table,
  Select,
  Card,
  Tag,
  Upload,
  Typography,
  Button,
} from "antd";
import { UploadOutlined, DownloadOutlined } from "@ant-design/icons";
import { API_URL } from "../../../config.js";

const { Text } = Typography;
const { Option } = Select;
const moment = require("moment-timezone");

class Firmware extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firmware_audit: [],
      firmware_snapshots: [],
      extract_dialog: false,
      drives_available: [],
      selected_drive: "",
      download_dialog: false,
    };
    this.handleUpload = this.handleUpload.bind(this);
    this.handleDownload = this.handleDownload.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.refreshData = this.refreshData.bind(this);
    this.download = this.download.bind(this);
    this.handleExtract = this.handleExtract.bind(this);
  }

  refreshData() {
    fetch(API_URL + "/actions/usb_devices/", {
      method: "GET",
      headers: {
        Authorization: "Bearer " + this.props.getToken(),
      },
    })
      .then((res) => res.json())
      .then((res) => {
        this.setState({ drives_available: res });
      })
      .catch((e) => {
        console.log(e);
        message.error("Failed to Receive Drive Letters.");
      })
      .finally(() => {});
    fetch(API_URL + "/data/firmware_audit/", {
      method: "GET",
      headers: {
        Authorization: "Bearer " + this.props.getToken(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((res_config) => res_config.json())
      .then((data) => {
        this.setState({ firmware_audit: data });
      });

    fetch(API_URL + "/data/firmware_files/", {
      method: "GET",
      headers: {
        Authorization: "Bearer " + this.props.getToken(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((res_config) => res_config.json())
      .then((data) => {
        this.setState({ firmware_snapshots: data });
      });
  }

  componentDidMount() {
    this.interval = setInterval(() => this.refreshData(), 5000);
    this.refreshData();
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  download(blob, filename) {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    // the filename you want
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }

  handleExtract() {
    if (this.state.selected_drive !== "" && this.state.selected_drive) {
      fetch(API_URL + "/actions/action/extract_data", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + this.props.getToken(),
        },
        body: JSON.stringify({
          selected_drive: this.state.selected_drive,
        }),
      })
        .then((res) => res.json())
        .then(() => {
          message.success(
            "Extraction Submitted Successfully. Please watch the logs below for progress.",
            10
          );
          this.setState({ extract_dialog: false });
          this.refreshData();
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {});
    } else {
      message.error("Please select a valid drive letter.");
    }
  }

  handleDelete(filename) {
    fetch(API_URL + "/actions/action/delete_firmware", {
      method: "POST",
      headers: {
        Authorization: "Bearer " + this.props.getToken(),
      },
      body: JSON.stringify({
        filename: filename,
      }),
    })
      .then(() => {
        message.success("Delete Successful.");
        this.refreshData();
      })
      .catch((e) => {
        console.log(e);
        message.error("Delete Failed.");
      })
      .finally(() => {});
  }
  handleDownload(filename) {
    fetch(API_URL + "/actions/action/download_firmware", {
      method: "POST",
      headers: {
        Authorization: "Bearer " + this.props.getToken(),
      },
      body: JSON.stringify({
        filename: filename,
      }),
    })
      .then((res) => res.blob())
      .then((blob) => {
        let now = moment().format("YYYYMMDDHHmmss");
        this.download(blob, "firmware_" + now + ".zip");
        console.log(blob);
        message.success("Download Successful.");
        this.refreshData();
      })
      .catch((e) => {
        console.log(e);
        message.error("Download Failed.");
      })
      .finally(() => {});
  }
  handleUpload(info) {
    let file = info.file;
    const formData = new FormData();
    formData.append("file", file);
    fetch(API_URL + "/actions/action/upload_firmware", {
      method: "POST",
      headers: {
        Authorization: "Bearer " + this.props.getToken(),
      },
      body: formData,
    })
      .then((res) => res.json())
      .then(() => {
        message.success("Upload Successful.");

        fetch(API_URL + "/actions/action/reboot_computer", {
          method: "POST",
          headers: {
            Authorization: "Bearer " + this.props.getToken(),
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({}),
        })
          .then((res) => res.json())
          .then((json) => {
            if (json.length === 0) {
              message.loading({
                content:
                  "Computer has rebooted. Please wait 5 minutes for startup...",
                duration: 60,
              });
            }
          })
          .catch((error) => {
            console.error(error);
          });
      })
      .catch(() => {
        message.error("upload failed.");
      })
      .finally(() => {});
  }

  render() {
    return (
      <div>
        <div align="center">
          <Alert
            style={{ margin: "25px" }}
            message="WARNING: This feature allows a user to upload a valid configuration bundle to the device. This process will stop VCON and reboot the computer when finished. You may also download the current analyzer configuration bundle as needed. Snapshots are available below the Firmware Administration table."
            type="error"
          />
        </div>
        <Card
          style={{ margin: "25px" }}
          title="Firmware Administration"
          extra={
            <span>
              <Modal
                onCancel={() => this.setState({ extract_dialog: false })}
                title="Extract Data"
                visible={this.state.extract_dialog}
                footer={null}
                closable
              >
                <span>
                  <Alert
                    style={{ marginBottom: "25px" }}
                    message="Please place a USB in the analyzer for extraction of data. Alternatively, you may select the C: drive, which will allow you to download the extraction from your browser after completion of extraction. This is only recommended if you have a good connection to the analyzer. Data Extractions can take several minutes to complete."
                    type="error"
                  />
                  <Text style={{ marginRight: "20px" }}>Drive Letter:</Text>
                  <Select
                    defaultValue=""
                    style={{ width: 120 }}
                    onChange={(value) =>
                      this.setState({ selected_drive: value })
                    }
                  >
                    {this.state.drives_available.map((drive) => (
                      <Option key={drive} value={drive}>
                        {drive}
                      </Option>
                    ))}
                  </Select>
                  <Button
                    style={{ margin: "5px" }}
                    icon={<DownloadOutlined />}
                    onClick={() => this.handleExtract()}
                  >
                    Extract Data
                  </Button>
                </span>
              </Modal>
              <Button
                style={{ margin: "5px" }}
                icon={<DownloadOutlined />}
                onClick={() => this.setState({ extract_dialog: true })}
              >
                Extract Data
              </Button>
              <Button
                style={{ margin: "5px" }}
                icon={<DownloadOutlined />}
                onClick={() => this.handleDownload(null)}
              >
                Download Firmware
              </Button>
              <Upload
                beforeUpload={() => false}
                onChange={(info) => this.handleUpload(info)}
              >
                <Button style={{ margin: "5px" }} icon={<UploadOutlined />}>
                  Upload
                </Button>
              </Upload>
            </span>
          }
        >
          <Table
            rowKey="id"
            dataSource={this.state.firmware_audit}
            size="small"
            columns={[
              {
                title: "Details",
                dataIndex: "detail",
              },
              {
                title: "Username",
                dataIndex: "username",
              },
              {
                title: "Timestamp",
                render: (record) => {
                  moment.updateLocale("en", {
                    relativeTime: {
                      future: "%s",
                      past: "%s",
                      s: "%ds ago",
                      m: "1m ago",
                      mm: "%dm ago",
                      h: "1h ago",
                      hh: "%dh ago",
                      d: "1d ago",
                      dd: "%dd ago",
                      M: "1mth ago",
                      MM: "%dmth ago",
                      y: "1y ago",
                      yy: "%dy ago",
                    },
                  });
                  var format = "YYYY/MM/DD hh:mm:ss A z";
                  var timestamp = moment(record.timestamp).format(format);
                  var fromNow = moment(timestamp).fromNow();
                  return <Tag>{fromNow}</Tag>;
                },
              },
            ]}
          />
        </Card>

        <Card
          style={{ margin: "25px" }}
          title="Firmware Snapshots and Data Extractions"
        >
          <Table
            rowKey="id"
            dataSource={this.state.firmware_snapshots}
            size="small"
            columns={[
              {
                title: "Firmware Snapshot",
                dataIndex: "firmware_name",
              },
              {
                title: "File Size",
                dataIndex: "size",
              },
              {
                title: "Username",
                dataIndex: "username",
              },
              {
                title: "Timestamp",
                render: (record) => {
                  moment.updateLocale("en", {
                    relativeTime: {
                      future: "%s",
                      past: "%s",
                      s: "%ds ago",
                      m: "1m ago",
                      mm: "%dm ago",
                      h: "1h ago",
                      hh: "%dh ago",
                      d: "1d ago",
                      dd: "%dd ago",
                      M: "1mth ago",
                      MM: "%dmth ago",
                      y: "1y ago",
                      yy: "%dy ago",
                    },
                  });
                  var format = "YYYY/MM/DD hh:mm:ss A z";
                  var timestamp = moment(record.timestamp).format(format);
                  var fromNow = moment(timestamp).fromNow();
                  return <Tag>{fromNow}</Tag>;
                },
              },
              {
                title: "Action",
                render: (record) => {
                  return (
                    <span>
                      <Button
                        style={{ marginRight: "20px" }}
                        onClick={() =>
                          this.handleDownload(record.firmware_name)
                        }
                      >
                        Download
                      </Button>
                      <Button
                        onClick={() => this.handleDelete(record.firmware_name)}
                      >
                        Delete
                      </Button>
                    </span>
                  );
                },
              },
            ]}
          />
        </Card>
      </div>
    );
  }
}

export default Firmware;
