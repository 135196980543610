/* eslint-disable react/display-name */

import React from "react";
import "./App.css";
import MainScreen from "./components/MainScreen";
import NavBar from "./components/NavBar";
import SamplePage from "./pages/sample";
import HealthPage from "./pages/health";
import ModelsConfigPage from "./pages/config_models";
import WebConfigPage from "./pages/config_web";
import FirmwarePage from "./pages/config_firmware";
import VCONConfigPage from "./pages/config_vcon";
import ExternalConfigPage from "./pages/config_external";
import ConfigPage from "./pages/config";
import TroubleshootPage from "./pages/troubleshoot";
import DataPage from "./pages/data";
import "antd/dist/antd.css";
import { Routes, Route } from "react-router-dom";
import { BackTop, Layout, Result, Modal } from "antd";
import { useLocation } from "react-router-dom";
import { API_URL } from "./config.js";
import AuthForm from "./components/AuthForm";
import decodeJwt from "jwt-decode";
import "antd/dist/antd.css";

import {
  StopTwoTone,
  CheckCircleTwoTone,
  ExclamationCircleTwoTone,
} from "@ant-design/icons";

const withNavigate = (WrappedComponent) => (props) => {
  const navigate = useLocation();

  return <WrappedComponent navigate={navigate} {...props} />;
};

const NavBarNav = withNavigate(NavBar);

const { Content, Footer } = Layout;
class App extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      access: [],
      width: 0,
      height: 0,
      authorized: false,
      login_dialog: false,
      jp3_user: false,
      darkmode: false,
      status: 0,
      failed_attempt: false,
      result_status: null,
      level: 0,
      timer_enabled: false,
      metrics: [],
      service_status: [],
      analyzer_logs: [],
      faults: [],
      warnings: [],
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.refreshData = this.refreshData.bind(this);
    this.onFinishAuth = this.onFinishAuth.bind(this);
    this.getToken = this.getToken.bind(this);
    this.getUser = this.getUser.bind(this);
    this.toggleDarkMode = this.toggleDarkMode.bind(this);
  }

  toggleDarkMode() {
    localStorage.setItem(
      "jp3_darkmode",
      JSON.stringify({ darkmode: !this.state.darkmode })
    );
    window.location.reload();
  }

  getToken() {
    let jp3_user_string = sessionStorage.getItem("jp3_user");
    if (jp3_user_string !== null) {
      const jp3_user_info = JSON.parse(jp3_user_string);
      return jp3_user_info.token;
    }
    return "";
  }

  getUser() {
    let jp3_user_string = sessionStorage.getItem("jp3_user");
    if (jp3_user_string !== null) {
      const jp3_user_info = JSON.parse(jp3_user_string);
      return jp3_user_info.username;
    }
    return "";
  }

  onFinishAuth(values) {
    fetch(API_URL + "/user/auth", {
      method: "POST",
      headers: {
        Authorization: "Bearer " + this.getToken(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username: values.username,
        password: values.password,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.authorized === true) {
          this.setState({ login_dialog: false });
          sessionStorage.removeItem("jp3_user");
          sessionStorage.setItem("jp3_user", JSON.stringify(data));
          if (data.access_level === 1) {
            this.setState({
              authorized: true,
              jp3_user: true,
              failed_attempt: false,
            });
          } else {
            this.setState({
              authorized: true,
              jp3_user: false,
              failed_attempt: false,
            });
          }
        }
      });
  }

  refreshData() {
    fetch(API_URL + "/health/", {
      method: "GET",
      headers: {
        Authorization: "Bearer " + this.getToken(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (
          data &&
          data["vcon_status"] &&
          data["vcon_status"]["data"] &&
          data["vcon_status"]["data"]["state"] >= 0
        ) {
          this.setState({ health_response: data }, function () {
            let state = "";
            let level = 0;
            let timer_enabled = false;
            let current_analysis_point = 0;
            let current_analysis_point_name = "";
            let sampling = false;
            let status_state = "success";
            let status_title = "";
            let status_subtitle = "";
            let result_status = null;
            let warnings = [];
            let faults = [];
            let metrics = [];

            if (
              this.state.health_response &&
              this.state.health_response["vcon_status"] &&
              this.state.health_response["vcon_status"]["data"] &&
              this.state.health_response["vcon_status"]["data"]["sample"]
            ) {
              let analysis_points =
                this.state.health_response["vcon_status"]["data"][
                  "analysis_points"
                ];
              this.setState({ analysis_points_status: analysis_points });
              if (this.state.health_response["service_status"]) {
                this.setState({
                  service_status: this.state.health_response["service_status"],
                });
              }
              if (this.state.health_response["analyzer_log"]) {
                this.setState({
                  analyzer_logs: this.state.health_response["analyzer_log"],
                });
              }

              if (this.state.health_response["vcon_status"]["data"]) {
                metrics.push({
                  key: "Timer Enabled",
                  value:
                    this.state.health_response["vcon_status"]["data"][
                      "timer_enabled"
                    ] === 1 ? (
                      <CheckCircleTwoTone
                        twoToneColor="#52c41a"
                        style={{ fontSize: "24pt" }}
                      />
                    ) : (
                      <ExclamationCircleTwoTone
                        twoToneColor="#FF6100"
                        style={{ fontSize: "24pt" }}
                      />
                    ),
                });

                if (
                  this.state.health_response["vcon_status"]["data"].state === 0
                ) {
                  state = "Normal Operation";
                } else if (
                  this.state.health_response["vcon_status"]["data"].state === 1
                ) {
                  state = "24 Hour Averaging";
                } else if (
                  this.state.health_response["vcon_status"]["data"].state === 2
                ) {
                  state = "Collecting Spectrometer Diagnostic Data";
                } else if (
                  this.state.health_response["vcon_status"]["data"].state === 3
                ) {
                  state = "Timer is Off";
                } else if (
                  this.state.health_response["vcon_status"]["data"].state === 4
                ) {
                  state = "Performing Analysis";
                } else if (
                  this.state.health_response["vcon_status"]["data"].state === 5
                ) {
                  state = "Getting Calibration Cylinder";
                } else if (
                  this.state.health_response["vcon_status"]["data"].state === 6
                ) {
                  state = "Auto Sampling";
                } else if (
                  this.state.health_response["vcon_status"]["data"].state === 7
                ) {
                  state = "Auto Sampling";
                }
                metrics.push({
                  key: "VCON State",
                  value: state,
                });
                metrics.push({
                  key: "Current Analysis Point",
                  value:
                    this.state.health_response["vcon_status"]["data"][
                      "current_analysis_point_name"
                    ],
                });
                metrics.push({
                  key: "Sampling",
                  value:
                    this.state.health_response["vcon_status"]["data"]["sample"][
                      "sampling"
                    ] === true ? (
                      <CheckCircleTwoTone
                        twoToneColor="#52c41a"
                        style={{ fontSize: "24pt" }}
                      />
                    ) : (
                      <StopTwoTone
                        twoToneColor="#FF6100"
                        style={{ fontSize: "24pt" }}
                      />
                    ),
                });
                sampling =
                  this.state.health_response["vcon_status"]["data"]["sample"][
                    "sampling"
                  ];
                timer_enabled =
                  this.state.health_response["vcon_status"]["data"][
                    "timer_enabled"
                  ];
                current_analysis_point =
                  this.state.health_response["vcon_status"]["data"][
                    "current_analysis_point"
                  ];
                current_analysis_point_name =
                  this.state.health_response["vcon_status"]["data"][
                    "current_analysis_point_name"
                  ];

                analysis_points.forEach((point) => {
                  let fault_code = point["current_fault_code"];
                  let warn_code = point["current_warn_code"];

                  if (fault_code >= 128) {
                    faults.push({
                      analysis_point: point["analysis_point"],
                      name: point["name"],
                      code: "VCON Configuration Error",
                    });
                    fault_code = fault_code - 128;
                  }
                  if (fault_code >= 64) {
                    faults.push({
                      analysis_point: point["analysis_point"],
                      name: point["name"],
                      code: "MUX Error",
                    });
                    fault_code = fault_code - 64;
                  }
                  if (fault_code >= 32) {
                    faults.push({
                      analysis_point: point["analysis_point"],
                      name: point["name"],
                      code: "Spectrometer 2 Error",
                    });
                    fault_code = fault_code - 32;
                  }
                  if (fault_code >= 16) {
                    faults.push({
                      analysis_point: point["analysis_point"],
                      name: point["name"],
                      code: "Spectrometer 1 Error",
                    });
                    fault_code = fault_code - 16;
                  }
                  if (fault_code >= 8) {
                    faults.push({
                      analysis_point: point["analysis_point"],
                      name: point["name"],
                      code: "Prediction Engine Error",
                    });
                    fault_code = fault_code - 8;
                  }

                  if (fault_code >= 4) {
                    faults.push({
                      analysis_point: point["analysis_point"],
                      name: point["name"],
                      code: "Transmission Low Fault",
                    });
                    fault_code = fault_code - 4;
                  }
                  if (fault_code >= 2) {
                    faults.push({
                      analysis_point: point["analysis_point"],
                      name: point["name"],
                      code: "Absorption is High Fault",
                    });
                    fault_code = fault_code - 2;
                  }
                  if (fault_code >= 1) {
                    faults.push({
                      analysis_point: point["analysis_point"],
                      name: point["name"],
                      code: "High T2 and Q Modeling Fault",
                    });
                    fault_code = fault_code - 2;
                  }

                  if (warn_code >= 256) {
                    warnings.push({
                      analysis_point: point["analysis_point"],
                      name: point["name"],
                      code: "MySQL Database Error",
                    });
                    warn_code = warn_code - 256;
                  }
                  if (warn_code >= 128) {
                    warnings.push({
                      analysis_point: point["analysis_point"],
                      name: point["name"],
                      code: "Modbus Error",
                    });
                    warn_code = warn_code - 128;
                  }
                  if (warn_code >= 64) {
                    warnings.push({
                      analysis_point: point["analysis_point"],
                      name: point["name"],
                      code: "Transmission Low",
                    });
                    warn_code = warn_code - 64;
                  }
                  if (warn_code >= 32) {
                    warnings.push({
                      analysis_point: point["analysis_point"],
                      name: point["name"],
                      code: "High T2 and Q",
                    });
                    warn_code = warn_code - 32;
                  }
                  if (warn_code >= 16) {
                    warnings.push({
                      analysis_point: point["analysis_point"],
                      name: point["name"],
                      code: "Noise High",
                    });
                    warn_code = warn_code - 16;
                  }
                  if (warn_code >= 8) {
                    warnings.push({
                      analysis_point: point["analysis_point"],
                      name: point["name"],
                      code: "Water High",
                    });
                    warn_code = warn_code - 8;
                  }
                  if (warn_code >= 4) {
                    warnings.push({
                      analysis_point: point["analysis_point"],
                      name: point["name"],
                      code: "Flow Out of Range",
                    });
                    warn_code = warn_code - 4;
                  }
                  if (warn_code >= 2) {
                    warnings.push({
                      analysis_point: point["analysis_point"],
                      name: point["name"],
                      code: "Pressure Out of Range",
                    });
                    warn_code = warn_code - 2;
                  }
                  if (warn_code >= 1) {
                    warnings.push({
                      analysis_point: point["analysis_point"],
                      name: point["name"],
                      code: "Temperature Out of Range",
                    });
                    warn_code = warn_code - 1;
                  }
                });
                if (faults.length > 0) {
                  level = 2;
                  status_state = "warning";
                  status_title = "VCON is Operational with Faults";
                  status_subtitle =
                    "Currently Analyzing Analysis Point " +
                    current_analysis_point +
                    ": " +
                    current_analysis_point_name;
                } else if (timer_enabled && state === "Normal Operation") {
                  level = 0;
                  status_state = "success";
                  status_title = "VCON is Operational";
                  status_subtitle =
                    "Timer is Active. Currently Analyzing Analysis Point " +
                    current_analysis_point +
                    ": " +
                    current_analysis_point_name;

                  if (warnings.length > 0) {
                    level = 1;
                    status_state = "warning";
                    status_title = "VCON is Operational with Warnings";
                  }
                } else {
                  if (sampling === true) {
                    level = 0;
                    status_state = "info";
                    status_title = "VCON is Sampling";
                    status_subtitle =
                      "Currently Sampling Analysis Point " +
                      current_analysis_point +
                      ": " +
                      current_analysis_point_name;
                  } else {
                    level = 2;
                    status_state = "error";
                    status_title = "VCON is Not Operational";
                  }
                }

                result_status = (
                  <Result
                    size="small"
                    status={status_state}
                    title={status_title}
                    subTitle={status_subtitle}
                  />
                );
              }

              this.setState({
                level,
                faults,
                warnings,
                result_status,
                timer_enabled,
                metrics,
              });
            }
          });
        } else {
          let result_status = (
            <Result
              status="error"
              title="VCON is Not Operational"
              subTitle="VCON is not currently running."
            />
          );

          this.setState({
            result_status,
            level: 2,
            timer_enabled: false,
            metrics: [],
            faults: [],
            warnings: [],
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }
  componentDidMount() {
    let jp3_user_string = sessionStorage.getItem("jp3_user");
    if (jp3_user_string !== null) {
      const jp3_user_info = JSON.parse(jp3_user_string);
      const decodedToken = decodeJwt(jp3_user_info.token);
      var current_time = new Date().getTime() / 1000;
      if (current_time > decodedToken.exp) {
        sessionStorage.removeItem("jp3_user");
        this.setState({
          authorized: false,
          failed_attempt: false,
        });
      } else {
        if (jp3_user_info.access_level === 1) {
          this.setState({
            jp3_user: true,
          });
        } else {
          this.setState({
            jp3_user: false,
          });
        }
        this.setState({
          authorized: true,
          failed_attempt: false,
        });
      }
    }
    let jp3_darkmode = localStorage.getItem("jp3_darkmode");
    if (jp3_darkmode !== null) {
      const darkmode = JSON.parse(jp3_darkmode).darkmode;
      this.setState({ darkmode });
    }

    this.interval = setInterval(() => this.refreshData(), 2000);
    this.refreshData();
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }
  componentWillUnmount() {
    clearInterval(this.interval);
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  render() {
    if (this.state.darkmode) {
      import("antd/dist/antd.dark.css");
    }
    return (
      <Layout style={{ minHeight: "100vh" }}>
        <NavBarNav
          toggleDarkMode={() => this.toggleDarkMode()}
          darkmode={this.state.darkmode}
          getToken={this.getToken}
          status={this.state.status}
          level={this.state.level}
          login_dialog_toggle={() => this.setState({ login_dialog: true })}
          authorized={this.state.authorized}
          jp3_user={this.state.jp3_user}
        />
        <BackTop />
        <Content
          style={{
            paddingLeft: "1%",
            paddingRight: "1%",
          }}
        >
          <Routes>
            <Route
              path="/"
              element={
                <MainScreen
                  darkmode={this.state.darkmode}
                  getToken={this.getToken}
                  authorized={this.state.authorized}
                  jp3_user={this.state.jp3_user}
                  width={this.state.width}
                  height={this.state.height}
                />
              }
            />
            <Route
              path="data"
              element={
                <DataPage
                  getToken={this.getToken}
                  authorized={this.state.authorized}
                  jp3_user={this.state.jp3_user}
                  width={this.state.width}
                  height={this.state.height}
                  darkmode={this.state.darkmode}
                />
              }
            />
            <Route
              path="health"
              element={
                <HealthPage
                  darkmode={this.state.darkmode}
                  getToken={this.getToken}
                  authorized={this.state.authorized}
                  jp3_user={this.state.jp3_user}
                  refreshData={this.refreshData}
                  width={this.state.width}
                  height={this.state.height}
                  status={this.state.status}
                  result_status={this.state.result_status}
                  level={this.state.level}
                  timer_enabled={this.state.timer_enabled}
                  metrics={this.state.metrics}
                  service_status={this.state.service_status}
                  analyzer_logs={this.state.analyzer_logs}
                  faults={this.state.faults}
                  warnings={this.state.warnings}
                />
              }
            />
            {this.state.jp3_user ? (
              <Route
                path="config"
                element={
                  <ConfigPage
                    darkmode={this.state.darkmode}
                    getToken={this.getToken}
                    authorized={this.state.authorized}
                    jp3_user={this.state.jp3_user}
                    width={this.state.width}
                    height={this.state.height}
                  />
                }
              />
            ) : null}
            {this.state.jp3_user ? (
              <Route
                path="config/models"
                element={
                  <ModelsConfigPage
                    darkmode={this.state.darkmode}
                    getToken={this.getToken}
                    authorized={this.state.authorized}
                    jp3_user={this.state.jp3_user}
                    width={this.state.width}
                    height={this.state.height}
                  />
                }
              />
            ) : null}
            {this.state.jp3_user ? (
              <Route
                path="config/vcon"
                element={
                  <VCONConfigPage
                    darkmode={this.state.darkmode}
                    getToken={this.getToken}
                    authorized={this.state.authorized}
                    jp3_user={this.state.jp3_user}
                    width={this.state.width}
                    height={this.state.height}
                  />
                }
              />
            ) : null}
            {this.state.jp3_user ? (
              <Route
                path="config/web"
                element={
                  <WebConfigPage
                    darkmode={this.state.darkmode}
                    getUser={this.getUser}
                    getToken={this.getToken}
                    authorized={this.state.authorized}
                    jp3_user={this.state.jp3_user}
                    width={this.state.width}
                    height={this.state.height}
                  />
                }
              />
            ) : null}
            {this.state.jp3_user ? (
              <Route
                path="config/external"
                element={
                  <ExternalConfigPage
                    darkmode={this.state.darkmode}
                    getToken={this.getToken}
                    authorized={this.state.authorized}
                    jp3_user={this.state.jp3_user}
                    width={this.state.width}
                    height={this.state.height}
                  />
                }
              />
            ) : null}
            {this.state.authorized ? (
              <Route
                path="troubleshoot"
                element={
                  <TroubleshootPage
                    darkmode={this.state.darkmode}
                    getToken={this.getToken}
                    authorized={this.state.authorized}
                    jp3_user={this.state.jp3_user}
                    width={this.state.width}
                    height={this.state.height}
                  />
                }
              />
            ) : null}
            {this.state.authorized ? (
              <Route
                path="sample"
                element={
                  <SamplePage
                    darkmode={this.state.darkmode}
                    getToken={this.getToken}
                    authorized={this.state.authorized}
                    jp3_user={this.state.jp3_user}
                    width={this.state.width}
                    height={this.state.height}
                  />
                }
              />
            ) : null}
            {this.state.authorized ? (
              <Route
                path="config/firmware"
                element={
                  <FirmwarePage
                    darkmode={this.state.darkmode}
                    getToken={this.getToken}
                    authorized={this.state.authorized}
                    jp3_user={this.state.jp3_user}
                    width={this.state.width}
                    height={this.state.height}
                  />
                }
              />
            ) : null}
          </Routes>
          <Modal
            onCancel={() => this.setState({ login_dialog: false })}
            title="Authentication"
            visible={this.state.login_dialog}
            footer={null}
            closable
          >
            <AuthForm onFinishAuth={this.onFinishAuth} />
          </Modal>
        </Content>
        <Footer>
          <div align="center">
            © 2022 JP3 Measurement, LLC. All Rights Reserved.
          </div>
        </Footer>
      </Layout>
    );
  }
}

export default App;
