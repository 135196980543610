import React from "react";
import WebConfig from "../components/Configuration/Web/WebConfig";

export default function ModelsConfigPage(props) {
  return (
    <div>
      <WebConfig {...props} />
    </div>
  );
}
