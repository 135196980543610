import React from "react";
import Health from "../components/Health/Health";

export default function HealthPage(props) {
  return (
    <div>
      <Health {...props} />
    </div>
  );
}
