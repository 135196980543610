import React, { Component } from "react";
import {
  Card,
  Button,
  Table,
  Col,
  Row,
  Tabs,
  Modal,
  Form,
  Typography,
  message,
  Input,
  Popover,
  Select,
} from "antd";
import {
  BarcodeOutlined,
  BarChartOutlined,
  CheckCircleTwoTone,
  InfoCircleOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import Plot from "react-plotly.js";
import { Link } from "react-router-dom";
import { API_URL } from "../../config";
import moment from "moment";
import DataPage from "../../pages/data";

const { TabPane } = Tabs;
const { Option } = Select;
const zlib = require("zlib");

const barcodeColumns = [
  {
    title: "Barcode",
    dataIndex: "barcode",
    key: "barcode",
    render: (record) => {
      return (
        <Link to="">
          <a>{record}</a>
        </Link>
      );
    },
  },
  {
    title: "Start Time",
    dataIndex: "starttime",
    key: "starttime",
  },
];

class Sample extends Component {
  constructor(props) {
    super(props);
    this.state = {
      barcode_modal_visible: false,
      selected_analysis_number: 1,
      latest_spectra: [],
      analysis_point_info: [],
      analyzer_tag: "",
      analyzer_sn: "",
      isLoading: false,
      sampling_button_text: "START",
      sampling_disabled: false,
      vcon_version: "",
      cancel_button_visible: false,
      sampling_state: "",
      cancel_modal_visible: false,
      cancel_sampling_text: "",
      bgColor: "green",
      vcon_operational_status_visible: false,
      vcon_status: "",
      vcon_status_button_status: "",
      vcon_status_button_code: "",
      barcode: "",
      barcode_table_data: "",
      barcode_details: "",
      barcode_modal_analysis_number: "",
      spectra_count: "",
      sampling_complete: false,
      inputted_spectra_count: 0,
      vcon_sampling_status: "",
      latest_result_data: {},
      spectra_passed_in: false,
    };
    this.retrieveVCONStatus = this.retrieveVCONStatus.bind(this);
    this.getSpectraAndTableData = this.getSpectraAndTableData.bind(this);
    this.handleSpectraCountChange = this.handleSpectraCountChange.bind(this);
  }

  componentDidMount() {
    this.interval = setInterval(() => this.getSpectraAndTableData(), 60000);
    this.getSpectraAndTableData();
    this.VCONStatusInterval = setInterval(
      () => this.retrieveVCONStatus(),
      2000
    );
    this.retrieveVCONStatus();
    this.getVCONVersion();
    this.getAnalysisPointInfo();
  }

  componentWillUnmount() {
    clearInterval(this.interval);
    clearInterval(this.VCONStatusInterval);
  }

  getSpectraAndTableData() {
    fetch(API_URL + "/data/trending", {
      method: "POST",
      headers: {
        Authorization: "Bearer " + this.props.getToken(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        analysis_point: this.state.selected_analysis_number,
        pixels: 50,
        fromtimestamp: 0,
        totimestamp: 0,
      }),
    })
      .then((res) => res.text())
      .then((res) => {
        const uncompressed = zlib
          .inflateSync(new Buffer(res, "base64"))
          .toString();
        res = JSON.parse(uncompressed);
        let spectra;
        if ("Extra" in res) {
          spectra = res.Extra.slice(-1)[0]["spectra"];
        }
        this.setState({ latest_spectra: spectra }, function () {});
      })
      .catch((error) => {
        message.error("Unable to Refresh Service Status: " + error, 1);
        console.error(error);
      });
  }

  getVCONVersion() {
    let required_vcon_version = "4.2.6";
    fetch(API_URL + "/sample/vcon_info/", {
      method: "GET",
      headers: {
        Authorization: "Bearer " + this.props.getToken(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        this.setState({ vcon_version: data.vcon_version }, function () {
          this.compareVCONVersions(
            required_vcon_version,
            this.state.vcon_version
          );
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getAnalysisPointInfo() {
    fetch(API_URL + "/sample/analysis_point_info/", {
      method: "GET",
      headers: {
        Authorization: "Bearer " + this.props.getToken(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        this.setState({
          analysis_point_info: data.analysis_points,
          isLoading: true,
          analyzer_sn: data.analyzer_sn,
          analyzer_tag: data.analyzer_tag,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  handleTabChange = (key) => {
    if (key === "3") {
      fetch(API_URL + "/sample/barcodes/", {
        method: "GET",
        headers: {
          Authorization: "Bearer " + this.props.getToken(),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((data) => {
          this.setState({ barcode_table_data: data });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  handleBarcodeRowClick(e) {
    const barcode = e.barcode;
    fetch(API_URL + "/sample/barcodes_details/" + barcode + "/", {
      method: "GET",
      headers: {
        Authorization: "Bearer " + this.props.getToken(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        this.setState(
          {
            barcode_details: data[0],
            barcode: barcode,
            barcode_modal_analysis_number: data[0].analysis_number,
          },
          function () {
            fetch(API_URL + "/sample/spectra_count/" + barcode + "/", {
              method: "GET",
              headers: {
                Authorization: "Bearer " + this.props.getToken(),
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            })
              .then((res) => res.json())
              .then((data) => {
                this.setState({
                  spectra_count: data[0].spectra_count,
                  barcode_modal_visible: true,
                });
              })
              .catch((error) => {
                console.error(error);
              });
          }
        );
      })
      .catch((error) => {
        console.error(error);
      });
  }

  handleBarcodeModal = () => {
    this.setState({
      barcode_modal_visible: false,
    });
  };

  handleCancelModal = () => {
    this.setState({
      cancel_modal_visible: false,
    });
  };

  handleSamplingCompleteModal = () => {
    this.setState({
      sampling_complete: false,
    });
  };

  handleAnalysisButtonClick = (analysis_point_number) => {
    this.setState(
      {
        selected_analysis_number: analysis_point_number,
      },
      function () {
        this.getSpectraAndTableData();
      }
    );
  };

  compareVCONVersions(requiredVCON, systemVCON) {
    const rVCON = requiredVCON.split(".");
    const sysVCON = systemVCON.split(".");
    for (let i = 0; i < sysVCON.length; i++) {
      const a = parseInt(sysVCON[i]) || 0;
      const b = parseInt(rVCON[i]) || 0;
      if (a >= b) {
        return this.setState({ sampling_disabled: false });
      }
      if (a < b) {
        message.info(
          "Sampling Disabled! VCON Version must be 4.2.6 or higher to sample from Mocha."
        );
        return this.setState({ sampling_disabled: true });
      }
    }
    return this.setState({ sampling_disabled: false });
  }

  compareVCONVersionsForSamplingStatus(requiredVCON, systemVCON) {
    const rVCON = requiredVCON.split(".");
    const sysVCON = systemVCON.split(".");
    for (let i = 0; i < sysVCON.length; i++) {
      const a = parseInt(sysVCON[i]) || 0;
      const b = parseInt(rVCON[i]) || 0;
      if (a >= b) {
        return true;
      }
      if (a < b) {
        return false;
      }
    }
  }

  handleSamplingClick() {
    if (this.state.sampling_state !== "") {
      this.setState(
        {
          sampling_state: "STOP_SAMPLING",
          vcon_status: "Sampling is Stopping",
          bgColor: "yellow",
          cancel_button_visible: true,
          sampling_button_text: "STOPPING",
        },
        function () {
          fetch(API_URL + "/actions/action/vcon", {
            method: "POST",
            headers: {
              Authorization: "Bearer " + this.props.getToken(),
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              type: "vcon",
              commands: [{ command: "stop-sampling", source: "ServiceName" }],
            }),
          })
            .then((res) => res.json())
            .then((json) => {
              if (json.length > 0) {
                this.setState({
                  sampling_state: "",
                  vcon_status: "",
                  bgColor: "green",
                  cancel_button_visible: false,
                  sampling_button_text: "START",
                  sampling_complete: true,
                  vcon_sampling_status: "",
                });
              }
            })
            .catch((error) => {
              console.error(error);
            });
        }
      );
    } else {
      this.setState(
        {
          bgColor: "yellow",
          sampling_button_text: "STOP",
          vcon_status: "Stopping Timer",
          sampling_state: "STOP_TIMER",
          cancel_button_visible: true,
        },
        function () {
          fetch(API_URL + "/actions/action/vcon", {
            method: "POST",
            headers: {
              Authorization: "Bearer " + this.props.getToken(),
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              type: "vcon",
              commands: [
                { command: "stop-timer", source: "ServiceName" },
                {
                  command: "start-sampling",
                  spectra_count:
                    this.state.inputted_spectra_count > 0
                      ? this.state.inputted_spectra_count
                      : 100,
                  analysis_point: this.state.selected_analysis_number,
                  source: "ServiceName",
                },
              ],
            }),
          })
            .then((res) => res.json())
            .then((json) => {
              if (json.length > 0) {
                if (this.state.inputted_spectra_count > 0) {
                  this.setState({
                    spectra_passed_in: true,
                    vcon_status:
                      "Sampling will automatically stop after " +
                      this.state.inputted_spectra_count +
                      " spectra",
                  });
                } else {
                  this.setState({
                    bgColor: "red",
                    vcon_status: "Sampling",
                    sampling_state: "SAMPLING",
                    cancel_button_visible: false,
                  });
                }
              }
            })
            .catch((error) => {
              console.error(error);
            });
        }
      );
    }
  }

  handleCancel = () => {
    this.setState(
      {
        cancel_modal_visible: true,
        cancel_sampling_text: "Sample is being canceled...",
      },
      function () {
        fetch(API_URL + "/actions/action/vcon", {
          method: "POST",
          headers: {
            Authorization: "Bearer " + this.props.getToken(),
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            type: "vcon",
            commands: [{ command: "cancel-sampling", source: "ServiceName" }],
          }),
        })
          .then((res) => res.json())
          .then((json) => {
            if (json.length > 0) {
              this.setState({
                sampling_state: "",
                vcon_status: "",
                cancel_sampling_text: "Sample has being canceled.",
                cancel_button_visible: false,
                bgColor: "green",
                sampling_button_text: "START",
                vcon_sampling_status: "",
              });
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }
    );
  };

  retrieveVCONStatus() {
    fetch(API_URL + "/actions/action/vcon", {
      method: "POST",
      headers: {
        Authorization: "Bearer " + this.props.getToken(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        type: "vcon",
        commands: [{ command: "status", source: "ServiceName" }],
      }),
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.length > 0) {
          if (json[0]) {
            this.setState(
              {
                vcon_status_button_code: json[0].data.state,
              },
              function () {
                let status_object = json[0].data;
                if (status_object.sample) {
                  if (
                    status_object.state === 0 &&
                    status_object.timer_enabled === 1 &&
                    status_object.sample.sampling === false
                  ) {
                    this.setState({
                      vcon_status_button_status: "VCON is operating normally",
                      sampling_disabled: false,
                    });
                  } else {
                    this.setState({ sampling_disabled: true });
                  }
                  if (status_object.sample.sampling === true) {
                    let measurement = status_object.sample.spectra_count;
                    let current_analysis_point_name =
                      status_object.current_analysis_point_name;
                    this.setState({
                      vcon_sampling_status:
                        "Performing measurement " +
                        measurement +
                        " on " +
                        current_analysis_point_name +
                        "...",
                      sampling_disabled: false,
                      vcon_status_button_status: "VCON is sampling",
                    });
                  }
                  if (
                    status_object.sample.sampling === false &&
                    this.state.spectra_passed_in === true
                  ) {
                    this.setState({
                      vcon_status: "",
                      sampling_state: "",
                      vcon_sampling_status: "",
                      bgColor: "green",
                      cancel_button_visible: false,
                      sampling_button_text: "START",
                      sampling_complete: true,
                      spectra_passed_in: false,
                    });
                  }
                }
              }
            );
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  handleSpectraCountChange(e) {
    this.setState({ inputted_spectra_count: parseInt(e.target.value) });
  }

  openVCONHeartbeatModal = () => {
    this.setState({ vcon_operational_status_visible: true }, function () {
      this.retrieveVCONStatus();
    });
  };

  closeVCONHeartbeatModal = () => {
    this.setState({ vcon_operational_status_visible: false });
  };

  render() {
    const {
      isLoading,
      sampling_button_text,
      cancel_sampling_text,
      latest_spectra,
    } = this.state;

    let xValues = [];
    let yValues = [];
    if (latest_spectra) {
      for (let i = 0; i < latest_spectra.length; i++) {
        xValues.push(latest_spectra[i].w);
        yValues.push(latest_spectra[i].s);
      }
    }
    let endtime = moment(this.state.barcode_details.endtime);
    let starttime = moment(this.state.barcode_details.starttime);
    let duration = (endtime - starttime) / 1000 + "s";

    let analysis_point_info = [];
    if (isLoading === true) {
      analysis_point_info = this.state.analysis_point_info;
    }
    let cancel_sampling_button = [];
    if (this.state.cancel_button_visible === true) {
      cancel_sampling_button.push(
        <Button
          style={{ background: "#EEEEEE", borderColor: "beige" }}
          onClick={this.handleCancel}
        >
          Cancel
        </Button>
      );
    }
    let vcon_status_button = [];
    if (this.state.vcon_status_button_code === 0) {
      vcon_status_button.push(
        <div align="center" style={{ paddingTop: 20 }}>
          <Button onClick={this.openVCONHeartbeatModal}>
            <CheckCircleTwoTone twoToneColor="#00c400" />
            VCON Status
            <SyncOutlined />
          </Button>
        </div>
      );
    } else {
      vcon_status_button.push(
        <div align="center" style={{ paddingTop: 20 }}>
          <Button onClick={this.openVCONHeartbeatModal}>
            <CheckCircleTwoTone twoToneColor="#808080" />
            VCON Status
            <SyncOutlined />
          </Button>
        </div>
      );
    }
    const infoIcon = (
      <Popover
        content={
          <div style={{ width: 215 }}>
            <p>Enter the # of Spectra to capture</p>
          </div>
        }
      >
        <InfoCircleOutlined />
      </Popover>
    );

    return (
      <div>
        <Card>
          <div align="center">
            <Tabs onChange={this.handleTabChange}>
              <TabPane
                tab={
                  <span>
                    <BarChartOutlined twoToneColor="#fc3c3c" />
                    Sample
                  </span>
                }
                key="1"
              >
                <span>
                  <Select
                    placeholder="Analysis Point"
                    style={{ marginRight: "10px", width: "200px" }}
                    value={this.state.selected_analysis_number}
                    onChange={this.handleAnalysisButtonClick}
                  >
                    {analysis_point_info.map((ap) => {
                      let analysis_point_name =
                        "A" + ap.analysis_number + ": " + ap.display_name;
                      return (
                        <Option
                          value={ap.analysis_number}
                          key={ap.analysis_number}
                        >
                          {analysis_point_name}
                        </Option>
                      );
                    })}
                  </Select>
                  {vcon_status_button}
                </span>
                <div align="center">
                  <Plot
                    data={[
                      {
                        x: xValues,
                        y: yValues,
                        type: "scatter",
                        mode: "lines",
                        line: { color: "#ff5c4d", width: 1 },
                      },
                    ]}
                    layout={{
                      paper_bgcolor: "rgba(0,0,0,0)",
                      plot_bgcolor: "rgba(0,0,0,0)",
                      showlegend: false,
                      width: this.props.width / 1.75,
                      height: this.props.height / 2.5,
                      hovermode: "x",
                      spikedistance: -1,
                      title: {
                        font: {
                          family: "Roboto",
                          size: 12,
                        },
                      },
                      margin: {
                        l: 25,
                        r: 25,
                        b: 25,
                        t: 25,
                        pad: 4,
                      },
                      xaxis: {
                        range: this.props.range,
                        rangeslider: this.props.plotly_slider
                          ? { range: this.props.range }
                          : false,
                        font: {
                          family: "sans-serif",
                          size: 10,
                          color: this.props.darkmode ? "#fff" : "#000",
                        },
                        showgrid: false,
                        tickfont: {
                          family: "sans-serif",
                          size: 10,
                          color: this.props.darkmode ? "#fff" : "#000",
                        },
                        automargin: true,
                        showspikes: true,
                        spikethickness: 1,
                        spikemode: "across+toaxis",
                        spikesnap: "cursor",
                        showLine: true,
                        spikedash: "solid",
                      },
                      // Left Y Axis Snaps
                      yaxis: {
                        showgrid: false,
                        tickfont: {
                          family: "sans-serif",
                          size: 12,
                          color: this.props.darkmode ? "#fff" : "#000",
                        },
                        automargin: true,
                        showspikes: false,
                        spikemode: "across+toaxis",
                        spikesnap: "cursor",
                        showLine: true,
                        spikedash: "solid",
                      },
                    }}
                    config={{
                      displayModeBar: false,
                      displaylogo: false,
                      modeBarButtonsToRemove: ["lasso2d", "select2d"],
                    }}
                  />
                </div>
                <Col>
                  <Row align="center" style={{ paddingTop: 10 }}>
                    <Input
                      style={{ width: 170 }}
                      addonBefore="Spectra"
                      addonAfter={infoIcon}
                      onChange={(e) => this.handleSpectraCountChange(e)}
                    />
                    <div style={{ paddingLeft: 20, paddingRight: 10 }}>
                      <Button
                        style={{
                          background: this.state.bgColor,
                        }}
                        onClick={() => this.handleSamplingClick()}
                        disabled={this.state.sampling_disabled}
                      >
                        {sampling_button_text}
                      </Button>
                    </div>
                    {cancel_sampling_button}
                  </Row>
                  <div align="center">
                    <Typography style={{ paddingTop: 10 }}>
                      {this.state.vcon_status}
                    </Typography>
                  </div>
                  <div align="center">{this.state.vcon_sampling_status}</div>
                </Col>
              </TabPane>
              <TabPane
                tab={
                  <span>
                    <BarChartOutlined twoToneColor="#fc3c3c" />
                    Data
                  </span>
                }
                key="2"
              >
                <DataPage
                  darkmode={this.props.darkmode}
                  getToken={this.props.getToken}
                  authorized={this.props.authorized}
                  jp3_user={this.props.jp3_user}
                  width={this.props.width}
                  height={this.props.height}
                />
              </TabPane>
              <TabPane
                tab={
                  <span>
                    <BarcodeOutlined twoToneColor="#fc3c3c" />
                    Barcodes
                  </span>
                }
                key="3"
              >
                <Table
                  rowKey="barcode"
                  columns={barcodeColumns}
                  dataSource={this.state.barcode_table_data}
                  onRow={(e) => {
                    return {
                      onClick: () => {
                        this.handleBarcodeRowClick(e);
                      },
                    };
                  }}
                />
              </TabPane>
            </Tabs>
            <Modal
              title={
                this.state.analyzer_tag +
                " - A" +
                this.state.barcode_modal_analysis_number +
                " - " +
                this.state.barcode
              }
              visible={this.state.barcode_modal_visible}
              onOk={this.handleBarcodeModal}
              onCancel={this.handleBarcodeModal}
              footer={[
                <Button key="close" onClick={() => this.handleBarcodeModal()}>
                  Close
                </Button>,
              ]}
            >
              <div>
                <Form
                  id="barcodeForm"
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 10 }}
                  layout="horizontal"
                  size="small"
                >
                  <Form.Item
                    label={<b> Start Time </b>}
                    rules={[{ required: false }]}
                  >
                    {this.state.barcode_details.starttime}
                  </Form.Item>
                  <Form.Item
                    label={<b> End Time </b>}
                    rules={[{ required: false }]}
                  >
                    {this.state.barcode_details.endtime}
                  </Form.Item>
                  <Form.Item
                    label={<b> Duration </b>}
                    rules={[{ required: false }]}
                  >
                    {duration}
                  </Form.Item>
                  <Form.Item
                    label={<b> Spectra Count </b>}
                    rules={[{ required: false }]}
                  >
                    {this.state.spectra_count}
                  </Form.Item>
                  <Form.Item
                    label={<b> Temperature </b>}
                    rules={[{ required: false }]}
                  >
                    {this.state.barcode_details.temp}
                  </Form.Item>
                  <Form.Item
                    label={<b> Pressure </b>}
                    rules={[{ required: false }]}
                  >
                    {this.state.barcode_details.pres}
                  </Form.Item>
                  <Form.Item
                    label={<b> Transmission </b>}
                    rules={[{ required: false }]}
                  >
                    {this.state.barcode_details.trans}
                  </Form.Item>
                </Form>
              </div>
            </Modal>
            <Modal
              visible={this.state.cancel_modal_visible}
              onOk={this.handleCancelModal}
              onCancel={this.handleCancelModal}
              footer={[
                <Button key="close" onClick={() => this.handleCancelModal()}>
                  Close
                </Button>,
              ]}
            >
              <div align="center">{cancel_sampling_text}</div>
            </Modal>
            <Modal
              visible={this.state.vcon_operational_status_visible}
              onOk={this.closeVCONHeartbeatModal}
              onCancel={this.closeVCONHeartbeatModal}
              footer={[
                <Button
                  key="close"
                  onClick={() => this.closeVCONHeartbeatModal()}
                >
                  Close
                </Button>,
              ]}
            >
              <div align="center">
                <Typography>
                  Status: {this.state.vcon_status_button_status}
                </Typography>
                <Typography>
                  Code: {this.state.vcon_status_button_code}
                </Typography>
              </div>
            </Modal>
            <Modal
              visible={this.state.sampling_complete}
              onOk={this.handleSamplingCompleteModal}
              onCancel={this.handleSamplingCompleteModal}
              footer={[
                <Button
                  key="close"
                  onClick={() => this.handleSamplingCompleteModal()}
                >
                  Close
                </Button>,
              ]}
            >
              <div align="center">
                Sampling has completed. Please check the Barcodes tab for
                results.
              </div>
            </Modal>
          </div>
        </Card>
      </div>
    );
  }
}

export default Sample;
