import React from "react";
import {Button, Card, Form, Input, Row} from "antd";

const VCONSystemForm = (props) => {
  const [form] = Form.useForm();

  function onFinish(values) {
    props.onVCONFinish("system", values);
  }
  return (
    <Card>
      <Form
          id="vconSystemForm"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 14 }}
          layout="horizontal"
          form={form}
          initialValues={props.initialValues}
          onFinish={(values) => onFinish(values)}
          size="large"
      >
        <Row align="center">
          <Form.Item
            label="Analyzer SN"
            name="analyzer_sn"
            rules={[{ required: true }]}
          >
            <Input placeholder="Input" style={{ width: 125 }} />
          </Form.Item>
          <Form.Item
            label="Analyzer Tag"
            name="analyzer_tag"
            rules={[{ required: true }]}
          >
            <Input placeholder="Input" style={{ width: 300 }} />
          </Form.Item>
          <Form.Item
            label="Analyzer IP"
            name="analyzer_ip"
            rules={[{ required: true }]}
          >
            <Input placeholder="Input" style={{ width: 125 }} />
          </Form.Item>
          <Form.Item
            label="Remote IP"
            name="remote_ip"
            rules={[{ required: true }]}
          >
            <Input placeholder="Input" style={{ width: 125 }} />
          </Form.Item>
        </Row>
        <Row align="center" style={{ paddingTop: 10 }}>
          <Form.Item style={{ paddingRight: 10 }}>
            <Button type="primary" style={{ color: "#000000" }}>
              Spectrometers Diagnostic Collection
            </Button>
          </Form.Item>
          <Form.Item style={{ paddingRight: 10 }}>
            <Button type="primary" style={{ color: "#790000" }}>
              Power OFF Spectrometer 1
            </Button>
          </Form.Item>
          <Form.Item style={{ paddingRight: 10 }}>
            <Button type="primary" style={{ color: "#790000" }}>
              Power OFF Spectrometer 2
            </Button>
          </Form.Item>
          <Form.Item style={{ paddingRight: 10 }}>
            <Button
                type="primary"
                style={{ backgroundColor: "yellow", color: "black" }}
                onClick={() => form.resetFields()}
            >
              CANCEL
            </Button>
          </Form.Item>
          <Form.Item>
            <Button
                type="primary"
                form="vconSystemForm"
                htmlType="submit"
                style={{ backgroundColor: "lightgreen", color: "black" }}
            >
              SAVE
            </Button>
          </Form.Item>
        </Row>
      </Form>
    </Card>
  );
};

export default VCONSystemForm;
