import React, { Component } from "react";
import { Card, Col, Row, Typography } from "antd";
import {
  DatabaseOutlined,
  ExperimentOutlined,
  UserOutlined,
  DeploymentUnitOutlined,
  ToolOutlined,
  BranchesOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import { future_features } from "../../config.js";

const { Meta } = Card;
const { Title } = Typography;

class Configuration extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <div>
        <Row gutter={[24, 24]}>
          {future_features ? (
            <Col
              gutter={[24, 24]}
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
            >
              <Link to="/config/vcon">
                <Card hoverable>
                  <Meta
                    description={
                      <div align="center">
                        <div>
                          <DeploymentUnitOutlined
                            style={{
                              fontSize: "96px",
                              color: "#fc3c3c",
                              margin: "45px",
                            }}
                          />
                        </div>
                        <div>
                          <Title level={4}>VCON</Title>
                        </div>
                      </div>
                    }
                  />
                </Card>
              </Link>
            </Col>
          ) : null}

          {future_features ? (
            <Col
              gutter={[24, 24]}
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
            >
              <Link to="/config/models">
                <Card hoverable>
                  <Meta
                    description={
                      <div align="center">
                        <div>
                          <ToolOutlined
                            style={{
                              fontSize: "96px",
                              color: "#fc3c3c",
                              margin: "45px",
                            }}
                          />
                        </div>
                        <div>
                          <Title level={4}>Models</Title>
                        </div>
                      </div>
                    }
                  />
                </Card>
              </Link>
            </Col>
          ) : null}

          <Col
            gutter={[24, 24]}
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            xxl={12}
          >
            <Link to="/config/firmware">
              <Card hoverable>
                <Meta
                  description={
                    <div align="center">
                      <div>
                        <DatabaseOutlined
                          style={{
                            fontSize: "96px",
                            color: "#fc3c3c",
                            margin: "45px",
                          }}
                        />
                      </div>
                      <div>
                        <Title level={4}>Firmware</Title>
                      </div>
                    </div>
                  }
                />
              </Card>
            </Link>
          </Col>

          <Col
            gutter={[24, 24]}
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            xxl={12}
          >
            <Link to="/config/web">
              <Card hoverable>
                <Meta
                  description={
                    <div align="center">
                      <div>
                        <UserOutlined
                          style={{
                            fontSize: "96px",
                            color: "#fc3c3c",
                            margin: "45px",
                          }}
                        />
                      </div>
                      <div>
                        <Title level={4}>Web Application</Title>
                      </div>
                    </div>
                  }
                />
              </Card>
            </Link>
          </Col>
          <Col
            gutter={[24, 24]}
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            xxl={12}
          >
            <a href="https://10.75.75.251/webvisu/webvisu.htm">
              <Card hoverable>
                <Meta
                  description={
                    <div align="center">
                      <div>
                        <ExperimentOutlined
                          style={{
                            fontSize: "96px",
                            color: "#fc3c3c",
                            margin: "45px",
                          }}
                        />
                      </div>
                      <div>
                        <Title level={4}>PLC</Title>
                      </div>
                    </div>
                  }
                />
              </Card>
            </a>
          </Col>
          <Col
            gutter={[24, 24]}
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            xxl={12}
          >
            <a href="http://10.75.75.200">
              <Card hoverable>
                <Meta
                  description={
                    <div align="center">
                      <div>
                        <BranchesOutlined
                          style={{
                            fontSize: "96px",
                            color: "#fc3c3c",
                            margin: "45px",
                          }}
                        />
                      </div>
                      <div>
                        <Title level={4}>Moxa MGate</Title>
                      </div>
                    </div>
                  }
                />
              </Card>
            </a>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Configuration;
