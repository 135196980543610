import React, { Component } from "react";
import { Table, Button, Result } from "antd";
import UserForm from "./UserForm";
import { API_URL } from "../../../config.js";
import { UserOutlined } from "@ant-design/icons";
class WebConfig extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      user: {},
    };
    this.refreshData = this.refreshData.bind(this);
    this.editUser = this.editUser.bind(this);
    this.deleteUser = this.deleteUser.bind(this);
  }

  editUser(record) {
    if (record.user_id) {
      fetch(API_URL + "/user/update", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + this.props.getToken(),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(record),
      })
        .then((res) => res.json())
        .then(() => {
          this.refreshData();
        });
    } else {
      fetch(API_URL + "/user/add", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + this.props.getToken(),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(record),
      })
        .then((res) => res.json())
        .then(() => {
          this.refreshData();
        });
    }
  }
  deleteUser(record) {
    if (record.user_id) {
      fetch(API_URL + "/user/delete", {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + this.props.getToken(),
          "Content-Type": "application/json",
        },
        body: JSON.stringify(record),
      })
        .then((res) => res.json())
        .then(() => {
          this.refreshData();
        });
    }
  }
  refreshData() {
    fetch(API_URL + "/user/list", {
      method: "GET",
      headers: {
        Authorization: "Bearer " + this.props.getToken(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        this.setState({ users: data });
      });
  }

  componentDidMount() {
    this.refreshData();
  }

  render() {
    return (
      <div>
        <Result
          style={{ padding: "0px", marginBottom: "15px" }}
          icon={<UserOutlined />}
          status="info"
          title={"Logged in as " + this.props.getUser()}
          subTitle={"API Token: " + this.props.getToken()}
        />
        <UserForm onFinish={this.editUser} user={this.state.user} />
        <Table
          rowKey={"user_id"}
          dataSource={this.state.users}
          size="large"
          columns={[
            {
              title: "Username",
              dataIndex: "username",
            },
            {
              title: "Access Level",
              render: (record) => {
                if (record.access_level === 1) {
                  return "Admin";
                } else {
                  return "Customer";
                }
              },
            },
            {
              title: "Controls",
              render: (record) => {
                return (
                  <span>
                    <Button
                      style={{ marginRight: "20px" }}
                      onClick={() => {
                        this.setState({ user: record });
                      }}
                    >
                      Edit
                    </Button>
                    <Button
                      disabled={record.username !== "admin" ? false : true}
                      onClick={() => {
                        this.deleteUser(record);
                      }}
                    >
                      Delete
                    </Button>
                  </span>
                );
              },
            },
          ]}
        />
      </div>
    );
  }
}

export default WebConfig;
