import React from "react";
import Configuration from "../components/Configuration/Configuration";

export default function ConfigPage(props) {
  return (
    <div>
      <Configuration {...props} />
    </div>
  );
}
