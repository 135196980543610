import React from "react";
import Data from "../components/Data/Data";

export default function DataPage(props) {
  return (
    <div>
      <Data {...props} />
    </div>
  );
}
