import React, { Component } from "react";
import { Card, Col, Row, Typography } from "antd";
import { LinkOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

const { Meta } = Card;
const { Title } = Typography;

class External extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <div>
        <Row gutter={[24, 24]}>
          <Col
            gutter={[24, 24]}
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            xxl={12}
          >
            <a href="http://10.75.75.200">
              <Card hoverable>
                <Meta
                  description={
                    <div align="center">
                      <div>
                        <LinkOutlined
                          style={{
                            fontSize: "32px",
                            color: "#fc3c3c",
                            margin: "45px",
                          }}
                        />
                      </div>
                      <div>
                        <Title level={4}>Moxa MGate</Title>
                      </div>
                    </div>
                  }
                />
              </Card>
            </a>
          </Col>

          <Col
            gutter={[24, 24]}
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            xxl={12}
          >
            <Link to="https://192.168.13.31:9443">
              <Card hoverable>
                <Meta
                  description={
                    <div align="center">
                      <div>
                        <LinkOutlined
                          style={{
                            fontSize: "32px",
                            color: "#fc3c3c",
                            margin: "45px",
                          }}
                        />
                      </div>
                      <div>
                        <Title level={4}>Modem</Title>
                      </div>
                    </div>
                  }
                />
              </Card>
            </Link>
          </Col>
        </Row>
      </div>
    );
  }
}

export default External;
