import React, { Component } from "react";
import Plot from "react-plotly.js";

class PlotVisualization extends Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  render() {
    return (
      <Plot
        data={this.props.traces}
        layout={{
          paper_bgcolor: "rgba(0,0,0,0)",
          plot_bgcolor: "rgba(0,0,0,0)",
          showlegend: false,
          width: this.props.width,
          height: this.props.height,
          hovermode: "x",
          spikedistance: -1,
          title: {
            font: {
              family: "Roboto",
              size: 12,
            },
          },
          margin: {
            l: 25,
            r: 5,
            b: 25,
            t: 0,
            pad: 0,
          },
          xaxis: {
            type: "date",
            nticks: 4,
            tickformat: "%I:%M %p ",
            range: this.props.range,
            rangeslider: this.props.plotly_slider
              ? { range: this.props.range }
              : false,
            font: {
              family: "sans-serif",
              size: 8,
              color: this.props.darkmode ? "#fff" : "#000",
            },
            showgrid: false,
            tickfont: {
              family: "sans-serif",
              size: 10,
              color: this.props.darkmode ? "#fff" : "#000",
            },
            automargin: true,
            showspikes: true,
            spikethickness: 1,
            spikemode: "across+toaxis",
            spikesnap: "cursor",
            showLine: true,
            spikedash: "solid",
          },
          // Left Y Axis Snaps
          yaxis: {
            showgrid: false,
            tickfont: {
              family: "sans-serif",
              size: 10,
              color: this.props.darkmode ? "#fff" : "#000",
            },
            automargin: true,
            showspikes: false,
            spikemode: "across+toaxis",
            spikesnap: "cursor",
            showLine: true,
            spikedash: "solid",
          },
          // Right Y Axis Snaps
          yaxis2: {
            showgrid: false,
            tickfont: {
              family: "sans-serif",
              size: 10,
              color: this.props.darkmode ? "#fff" : "#000",
            },
            side: "right",
            overlaying: "y",
            automargin: true,
            showspikes: false,
            spikemode: "across+toaxis",
            spikesnap: "cursor",
            showLine: true,
            spikedash: "solid",
          },
          // Events
          yaxis3: {
            showgrid: false,
            tickfont: {
              family: "sans-serif",
              size: 10,
              color: this.props.darkmode ? "#fff" : "#000",
            },
            visible: false,
            anchor: "x",
            overlaying: "y",
            automargin: true,
            showspikes: false,
            spikemode: "across+toaxis",
            spikesnap: "cursor",
            showLine: false,
            spikedash: "solid",
          },
          // T2 and Q Y Axis
          yaxis4: {
            showgrid: false,
            tickfont: {
              family: "sans-serif",
              size: 10,
              color: this.props.darkmode ? "#fff" : "#000",
            },
            title: "T2 and Q",
            anchor: "x",
            visible: false,
            overlaying: "y",
            automargin: true,
            showspikes: false,
            spikemode: "across+toaxis",
            spikesnap: "cursor",
            showLine: false,
            spikedash: "solid",
          },
          // Weather
          yaxis5: {
            showgrid: false,
            tickfont: {
              family: "sans-serif",
              size: 10,
              color: this.props.darkmode ? "#fff" : "#000",
            },
            visible: false,
            anchor: "x",
            overlaying: "y",
            automargin: true,
            showspikes: false,
            spikemode: "across+toaxis",
            spikesnap: "cursor",
            showLine: false,
            spikedash: "solid",
          },
          // Selected Point from Other Graphs
          yaxis6: {
            showgrid: false,
            tickfont: {
              family: "sans-serif",
              size: 10,
              color: this.props.darkmode ? "#fff" : "#000",
            },
            visible: false,
            anchor: "x",
            overlaying: "y",
            automargin: true,
            showspikes: false,
            spikemode: "across+toaxis",
            spikesnap: "cursor",
            showLine: false,
            spikedash: "solid",
          },

          // Reserved yaxis5 - yaxis9 for other visualizations.

          // Normalized Support

          yaxis10: {
            showgrid: false,
            tickfont: {
              family: "sans-serif",
              size: 10,
              color: this.props.darkmode ? "#fff" : "#000",
            },
            anchor: "x",
            visible: false,
            overlaying: "y",
            automargin: true,
            showspikes: false,
            spikemode: "across+toaxis",
            spikesnap: "cursor",
            showLine: true,
            spikedash: "solid",
          },
          yaxis11: {
            showgrid: false,
            tickfont: {
              family: "sans-serif",
              size: 10,
              color: this.props.darkmode ? "#fff" : "#000",
            },
            anchor: "x",
            visible: false,
            overlaying: "y",
            automargin: true,
            showspikes: false,
            spikemode: "across+toaxis",
            spikesnap: "cursor",
            showLine: false,
            spikedash: "solid",
          },
          yaxis12: {
            showgrid: false,
            tickfont: {
              family: "sans-serif",
              size: 10,
              color: this.props.darkmode ? "#fff" : "#000",
            },
            anchor: "x",
            visible: false,
            overlaying: "y",
            automargin: true,
            showspikes: false,
            spikemode: "across+toaxis",
            spikesnap: "cursor",
            showLine: false,
            spikedash: "solid",
          },
          yaxis13: {
            showgrid: false,
            tickfont: {
              family: "sans-serif",
              size: 10,
              color: this.props.darkmode ? "#fff" : "#000",
            },
            anchor: "x",
            visible: false,
            overlaying: "y",
            automargin: true,
            showspikes: false,
            spikemode: "across+toaxis",
            spikesnap: "cursor",
            showLine: false,
            spikedash: "solid",
          },
          yaxis14: {
            showgrid: false,
            tickfont: {
              family: "sans-serif",
              size: 10,
              color: this.props.darkmode ? "#fff" : "#000",
            },
            anchor: "x",
            visible: false,
            overlaying: "y",
            automargin: true,
            showspikes: false,
            spikemode: "across+toaxis",
            spikesnap: "cursor",
            showLine: false,
            spikedash: "solid",
          },
          yaxis15: {
            showgrid: false,
            tickfont: {
              family: "sans-serif",
              size: 10,
              color: this.props.darkmode ? "#fff" : "#000",
            },
            anchor: "x",
            visible: false,
            overlaying: "y",
            automargin: true,
            showspikes: false,
            spikemode: "across+toaxis",
            spikesnap: "cursor",
            showLine: false,
            spikedash: "solid",
          },
          yaxis16: {
            showgrid: false,
            tickfont: {
              family: "sans-serif",
              size: 10,
              color: this.props.darkmode ? "#fff" : "#000",
            },
            anchor: "x",
            visible: false,
            overlaying: "y",
            automargin: true,
            showspikes: false,
            spikemode: "across+toaxis",
            spikesnap: "cursor",
            showLine: false,
            spikedash: "solid",
          },
          yaxis17: {
            showgrid: false,
            tickfont: {
              family: "sans-serif",
              size: 10,
              color: this.props.darkmode ? "#fff" : "#000",
            },
            anchor: "x",
            visible: false,
            overlaying: "y",
            automargin: true,
            showspikes: false,
            spikemode: "across+toaxis",
            spikesnap: "cursor",
            showLine: false,
            spikedash: "solid",
          },
          yaxis18: {
            showgrid: false,
            tickfont: {
              family: "sans-serif",
              size: 10,
              color: this.props.darkmode ? "#fff" : "#000",
            },
            anchor: "x",
            visible: false,
            overlaying: "y",
            automargin: true,
            showspikes: false,
            spikemode: "across+toaxis",
            spikesnap: "cursor",
            showLine: false,
            spikedash: "solid",
          },
        }}
        config={{
          displayModeBar: false,
          displaylogo: false,
          modeBarButtonsToRemove: ["lasso2d", "select2d"],
        }}
        onHover={(hover) => this.props.onHover({ hover })}
      />
    );
  }
}

export default PlotVisualization;
