import React from "react";
import {Button, Card, Form, Row, Select, Switch} from "antd";

const {Option} = Select;

const VCONMultiplexerForm = (props) => {
    const [form] = Form.useForm();
    let emulate_checked = false;
    let laser_fiber_checked = false;
    let detector_fiber_checked = false;
    if(props.data['emulate'] === true || props.data['emulate'] === 1){
        emulate_checked = true;
    }
    if(props.data['laser_fiber'] === true || props.data['laser_fiber'] === 0){
        laser_fiber_checked = true;
    }
    if(props.data['detector_fiber'] === true || props.data['detector_fiber'] === 0){
        detector_fiber_checked = true;
    }
    function onFinish(values) {
        props.onVCONFinish("multiplexer", values);
    }
    return(
        <Card>
            <Form
                id="vconMultiplexerForm"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 14 }}
                layout="horizontal"
                form={form}
                initialValues={props.data}
                onFinish={(values) => onFinish(values)}
                size="large"
            >
                <Row align="center">
                    <Form.Item
                        label="Emulate"
                        name="emulate"
                        rules={[{ required: true }]}
                    >
                        <Switch defaultChecked={emulate_checked} checkedChildren="True" unCheckedChildren="False" />
                    </Form.Item>
                    <Form.Item
                        label="Laser Fiber"
                        name="laser_fiber"
                        rules={[{ required: true }]}
                    >
                        <Switch defaultChecked={laser_fiber_checked} checkedChildren="MUX" unCheckedChildren="Switch" />
                    </Form.Item>
                    <Form.Item
                        label="MUX Port"
                        name="com"
                        rules={[{ required: true }]}
                    >
                        <Select placeholder="Select" style={{ width: 100 }}>
                            <Option value={1}>COM1</Option>
                            <Option value={2}>COM2</Option>
                            <Option value={3}>COM3</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Detector Fiber"
                        name="detector_fiber"
                        rules={[{ required: true }]}
                    >
                        <Switch defaultChecked={detector_fiber_checked} checkedChildren="MUX" unCheckedChildren="Switch" />
                    </Form.Item>
                </Row>
                <Row align="center" style={{ paddingTop: 10 }}>
                    <Form.Item style={{ paddingRight: 10 }}>
                        <Button type="primary" style={{ color: "#000000" }}>
                            Spectrometers Diagnostic Collection
                        </Button>
                    </Form.Item>
                    <Form.Item style={{ paddingRight: 10 }}>
                        <Button type="primary" style={{ color: "#790000" }}>
                            Power OFF Spectrometer 1
                        </Button>
                    </Form.Item>
                    <Form.Item style={{ paddingRight: 10 }}>
                        <Button type="primary" style={{ color: "#790000" }}>
                            Power OFF Spectrometer 2
                        </Button>
                    </Form.Item>
                    <Form.Item style={{ paddingRight: 10 }}>
                        <Button
                            type="primary"
                            style={{ backgroundColor: "yellow", color: "black" }}
                            onClick={() => form.resetFields()}
                        >
                            CANCEL
                        </Button>
                    </Form.Item>
                    <Form.Item>
                        <Button
                            type="primary"
                            form="vconMultiplexerForm"
                            htmlType="submit"
                            style={{ backgroundColor: "lightgreen", color: "black" }}
                        >
                            SAVE
                        </Button>
                    </Form.Item>
                </Row>
            </Form>
        </Card>
    );
};

export default VCONMultiplexerForm;