import React from "react";
import {Card, Form, Input, Row, InputNumber, Button} from "antd";

const VCONMySQLForm = (props) => {
    const [form] = Form.useForm();
    function onFinish(values) {
        props.onVCONFinish("mysql", values);
    }

    return (
        <Card>
            <Form
                id="vconMysqlForm"
                labelCol={{span: 24}}
                wrapperCol={{span: 14}}
                layout="horizontal"
                form={form}
                initialValues={props.initialValues}
                onFinish={(values) => onFinish(values)}
                size="large"
            >
                <Row align="center">
                    <Form.Item
                        label="Database Folder"
                        name="directory"
                        rules={[{required: true}]}
                    >
                        <Input placeholder="Input/Select" style={{width: 500}}/>
                    </Form.Item>
                    <Form.Item
                        label="Database Name"
                        name="name"
                        rules={[{required: true}]}
                    >
                        <Input placeholder="Input" style={{width: 120}}/>
                    </Form.Item>
                    <Form.Item
                        label="Frequency"
                        name="frequency"
                        rules={[{required: true}]}
                    >
                        <InputNumber style={{width: 45}}/>
                    </Form.Item>
                </Row>
                <Row align="center" style={{paddingTop: 10}}>
                    <Form.Item style={{paddingRight: 10}}>
                        <Button type="primary" style={{color: "#000000"}}>
                            Spectrometers Diagnostic Collection
                        </Button>
                    </Form.Item>
                    <Form.Item style={{paddingRight: 10}}>
                        <Button type="primary" style={{color: "#790000"}}>
                            Power OFF Spectrometer 1
                        </Button>
                    </Form.Item>
                    <Form.Item style={{paddingRight: 10}}>
                        <Button type="primary" style={{color: "#790000"}}>
                            Power OFF Spectrometer 2
                        </Button>
                    </Form.Item>
                    <Form.Item style={{paddingRight: 10}}>
                        <Button
                            type="primary"
                            style={{backgroundColor: "yellow", color: "black"}}
                            onClick={() => form.resetFields()}
                        >
                            CANCEL
                        </Button>
                    </Form.Item>
                    <Form.Item>
                        <Button
                            type="primary"
                            form="vconMysqlForm"
                            htmlType="submit"
                            style={{backgroundColor: "lightgreen", color: "black"}}
                        >
                            SAVE
                        </Button>
                    </Form.Item>
                </Row>
            </Form>
        </Card>
    );
};

export default VCONMySQLForm;