import React from "react";
import Troubleshoot from "../components/Troubleshoot/Troubleshoot";

export default function TroubleshootPage(props) {
  return (
    <div>
      <Troubleshoot {...props} />
    </div>
  );
}
