import React, { Component } from "react";
import { API_URL } from "../../config.js";
import {
  DatePicker,
  Card,
  Table,
  Switch,
  Button,
  message,
  Row,
  Divider,
  Tag,
  Tabs,
  Modal,
  Select,
  Col,
} from "antd";
import {
  EyeOutlined,
  PlusSquareOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import PlotVisualization from "./PlotVisaulization.js";
import Plot from "react-plotly.js";
import VisualizationForm from "./VisualizationForm.js";
import JSONLoaderForm from "./JSONLoaderForm.js";

const { TabPane } = Tabs;

const { RangePicker } = DatePicker;
const { Option } = Select;
const moment = require("moment-timezone");

const zlib = require("zlib");

function shadeColor(color, percent) {
  var R = parseInt(color.substring(1, 3), 16);
  var G = parseInt(color.substring(3, 5), 16);
  var B = parseInt(color.substring(5, 7), 16);

  R = parseInt((R * (100 + percent)) / 100);
  G = parseInt((G * (100 + percent)) / 100);
  B = parseInt((B * (100 + percent)) / 100);

  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;

  var RR = R.toString(16).length == 1 ? "0" + R.toString(16) : R.toString(16);
  var GG = G.toString(16).length == 1 ? "0" + G.toString(16) : G.toString(16);
  var BB = B.toString(16).length == 1 ? "0" + B.toString(16) : B.toString(16);

  return "#" + RR + GG + BB;
}

const colors = [
  "#2B4A6F",
  "#AA4139",
  "#462E74",
  "#2B803E",
  "#AA6039",
  "#8479e6",
  "#76b870",
  "#363690",
  "#574eb7",
  "#46b57c",
  "#44206b",
  "#43c29e",
  "#864490",
  "#36dee6",
  "#8c5fb8",
  "#7199e0",
  "#273d7f",
  "#c98fdb",
  "#4460bb",
  "#b88ae6",
  "#568de8",
  "#764e90",
  "#645ba8",
  "#2B4A6F",
  "#AA4139",
  "#462E74",
  "#2B803E",
  "#AA6039",
  "#8479e6",
  "#76b870",
  "#363690",
  "#574eb7",
  "#46b57c",
  "#44206b",
  "#43c29e",
  "#864490",
  "#36dee6",
  "#8c5fb8",
  "#7199e0",
  "#273d7f",
  "#c98fdb",
  "#4460bb",
  "#b88ae6",
  "#568de8",
  "#764e90",
  "#645ba8",
];

function tickFormatter(v) {
  const timestamp = moment(v).toDate();
  return timestamp;
}

class Data extends Component {
  constructor(props) {
    super(props);
    this.state = {
      analysis_point: null,
      trend_data: {},
      color_map: {},
      edit_mode: false,
      current_spectra_traces: {},
      hovering: false,
      hover_spectra: {},
      spectra_traces: [],
      hidden_results: {},
      group_dict: {},
      visualization_threshold_dialog: false,
      visualization_configuration: {},
      visualization_threshold_selected_ap: 0,
      visualization_threshold_selected_key: "",
      latest_data: true,
      service_status: [],
      latest_result_data: {},
      group_ind_traces: {},
      edit_analysis_number: 0,
      json_load_dialog: false,
      hovered_result_data: {},
      analysis_points: [],
      fromtimestamp: moment().subtract(4, "hours").valueOf(),
      totimestamp: moment().valueOf(),
      selected_trends: ["Transmission"],
      data_types: {},
      traces: {},
      default_landing_key: null,
      individual_traces: {},
    };
    this.refreshData = this.refreshData.bind(this);
    this.handleResultChange = this.handleResultChange.bind(this);
    this.handleAnalysisChange = this.handleAnalysisChange.bind(this);
    this.onHover = this.onHover.bind(this);
    this.tagRender = this.tagRender.bind(this);
    this.calculateMainVisualization =
      this.calculateMainVisualization.bind(this);
    this.handleSetCustomTimeRange = this.handleSetCustomTimeRange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.hideResult = this.hideResult.bind(this);
    this.toggleResultVisualization = this.toggleResultVisualization.bind(this);
    this.onFinishVisualization = this.onFinishVisualization.bind(this);
    this.onFinishJSON = this.onFinishJSON.bind(this);
  }

  onFinishJSON(values) {
    if (values.json) {
      let json = JSON.parse(values.json);
      localStorage.removeItem("jp3_visualization_configuration");
      localStorage.removeItem("jp3_default_landing_key");
      localStorage.removeItem("jp3_hidden_results");
      if (json["visualization_configuration"]) {
        this.setState(
          {
            visualization_configuration: json["visualization_configuration"],
          },
          function () {
            localStorage.setItem(
              "jp3_visualization_configuration",
              JSON.stringify(json["visualization_configuration"])
            );
          }
        );
      }
      if (json["hidden_results"]) {
        this.setState({ hidden_results: json["hidden_results"] }, function () {
          localStorage.setItem(
            "jp3_hidden_results",
            JSON.stringify(json["hidden_results"])
          );
          this.refreshData();
        });
      }

      if (json["jp3_default_landing_key"]) {
        this.setState(
          { default_landing_key: json["jp3_default_landing_key"] },
          function () {
            localStorage.setItem(
              "jp3_default_landing_key",
              JSON.stringify(json["jp3_default_landing_key"])
            );
          }
        );
      }
    }
    this.setState({ json_load_dialog: false });
  }

  onFinishVisualization(values) {
    let visualization_configuration = this.state.visualization_configuration;
    if (
      !visualization_configuration[
        this.state.visualization_threshold_selected_ap
      ]
    ) {
      visualization_configuration[
        this.state.visualization_threshold_selected_ap
      ] = {};
    }

    visualization_configuration[this.state.visualization_threshold_selected_ap][
      this.state.visualization_threshold_selected_key
    ] = {
      upper_threshold: values.upper_threshold,
      lower_threshold: values.lower_threshold,
      group: values.group,
      group_display: values.group_display,
    };
    this.setState(
      { visualization_configuration, visualization_threshold_dialog: false },
      function () {
        localStorage.setItem(
          "jp3_visualization_configuration",
          JSON.stringify(visualization_configuration)
        );
        this.refreshData();
      }
    );
  }

  toggleResultVisualization(key, analysis_number) {
    let visualization_configuration = this.state.visualization_configuration;
    if (!visualization_configuration[analysis_number]) {
      visualization_configuration[analysis_number] = {};
    }

    if (visualization_configuration[analysis_number][key]) {
      visualization_configuration[analysis_number][key] = null;
      let hasKeys = false;
      Object.keys(visualization_configuration[analysis_number]).forEach(
        function (v) {
          if (visualization_configuration[analysis_number][v]) {
            hasKeys = true;
          }
        }
      );

      if (!hasKeys) {
        visualization_configuration[analysis_number] = null;
      }
      this.setState(
        {
          visualization_configuration,
          visualization_threshold_dialog: false,
          visualization_threshold_selected_ap: 0,
          visualization_threshold_selected_key: "",
        },
        function () {
          localStorage.setItem(
            "jp3_visualization_configuration",
            JSON.stringify(visualization_configuration)
          );
          this.refreshData();
        }
      );
    } else {
      this.setState(
        {
          visualization_configuration,
          visualization_threshold_selected_ap: analysis_number,
          visualization_threshold_selected_key: key,
          visualization_threshold_dialog: true,
        },
        function () {
          localStorage.setItem(
            "jp3_visualization_configuration",
            JSON.stringify(visualization_configuration)
          );
          this.refreshData();
        }
      );
    }
  }

  hideResult(result, analysis_number) {
    let hidden_results = this.state.hidden_results[analysis_number];
    if (!hidden_results) {
      hidden_results = [];
    }
    if (hidden_results.includes(result)) {
      hidden_results = hidden_results.filter((x) => x !== result);
    } else {
      hidden_results.push(result);
    }

    const newHiddenResults = {
      ...this.state.hidden_results,
      [analysis_number]: hidden_results,
    };

    this.setState({ hidden_results: newHiddenResults }, function () {
      localStorage.setItem(
        "jp3_hidden_results",
        JSON.stringify(newHiddenResults)
      );
      this.refreshData();
    });
  }

  onHover(value) {
    if (!this.state.hovering) {
      this.setState({ hovering: true });
    }
    if (value.hover.points && value.hover.points.length > 0) {
      if (value.hover.points[0].pointIndex > 0) {
        if (
          this.state.trend_data &&
          this.state.trend_data[this.state.analysis_point] &&
          this.state.trend_data[this.state.analysis_point]["Extra"] &&
          this.state.trend_data[this.state.analysis_point]["Extra"][
            value.hover.points[0].pointIndex
          ]
        ) {
          let spectra =
            this.state.trend_data[this.state.analysis_point]["Extra"][
              value.hover.points[0].pointIndex
            ].spectra;

          this.state.analysis_points.forEach(function (ap) {
            let hovered_result_data_list = [];
            if (
              this.state.trend_data[ap.analysis_number] &&
              this.state.trend_data[ap.analysis_number]["result_data"]
            ) {
              Object.keys(
                this.state.trend_data[ap.analysis_number]["result_data"]
              ).forEach(function (key) {
                let result_obj =
                  this.state.trend_data[ap.analysis_number]["result_data"][key][
                    value.hover.points[0].pointIndex
                  ];
                if (result_obj) {
                  hovered_result_data_list.push({
                    name: result_obj.name,
                    value: result_obj.y,
                    unit: result_obj.unit,
                    key: result_obj.key,
                  });
                }
              }, this);
            }
            let current_hovered_result_data = this.state.hovered_result_data;
            current_hovered_result_data[ap.analysis_number] =
              hovered_result_data_list;
            //this.setState({ hovered_result_data: current_hovered_result_data });
          }, this);

          let spectra_traces = [];

          let signalArray = spectra.map((a) => a.s);
          let waveLengthArray = spectra.map((a) => a.w);

          let trace = {
            x: waveLengthArray,
            y: signalArray,
            points: spectra,
            yaxis: "y",
            name: "",
            mode: "lines",
            fill: "tonexty",
            fillcolor: this.props.darkmode
              ? "rgba(32, 32, 32, 0.6)"
              : "rgba(187, 208, 232, 0.8)",
            fillopacity: 0.5,
            type: "scatter",
            vis_type: "main",
            connectgaps: false,
            hovertemplate: "%{y}",
            marker: {
              size: 2.25,
              color: "",
              opacity: 1,
            },
            line: {
              width: 1,
              color: this.props.darkmode ? "#ff5c4d" : "black",
            },
          };
          spectra_traces.push(trace);
          this.setState({ spectra_traces });
        }
      }
    }
  }
  componentDidMount() {
    let inputJSON = {};
    if (localStorage.getItem("jp3_hidden_results")) {
      let hidden_results = JSON.parse(
        localStorage.getItem("jp3_hidden_results")
      );
      this.setState({ hidden_results });
      inputJSON["hidden_results"] = hidden_results;
    }
    if (localStorage.getItem("jp3_visualization_configuration")) {
      let visualization_configuration = JSON.parse(
        localStorage.getItem("jp3_visualization_configuration")
      );
      this.setState({ visualization_configuration });
      inputJSON["visualization_configuration"] = visualization_configuration;
    }

    if (localStorage.getItem("jp3_default_landing_key")) {
      let default_landing_key = JSON.parse(
        localStorage.getItem("jp3_default_landing_key")
      );
      this.setState({ default_landing_key });
      inputJSON["jp3_default_landing_key"] = default_landing_key;
    } else {
      this.setState({ default_landing_key: "1" });
      inputJSON["jp3_default_landing_key"] = "1";
    }

    console.log(inputJSON);

    this.setState({ json_configuration: inputJSON });

    this.interval = setInterval(() => this.refreshData(), 30000);
    this.refreshData();
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  tagRender(props) {
    const { label, closable, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    let color = "";
    if (this.state.color_map[label]) {
      color = this.state.color_map[label];
    }
    return (
      <Tag
        color={color}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3 }}
      >
        {label}
      </Tag>
    );
  }

  handleChange(timeperiod) {
    this.setState(
      {
        fromtimestamp: moment().subtract(timeperiod, "hours").valueOf(),
        totimestamp: moment().valueOf(),
      },
      function () {
        this.refreshData();
      }
    );
  }

  handleSetCustomTimeRange(value) {
    if (value === null || value.length === 0) {
      this.setState({
        fromtimestamp: moment().subtract(4, "hours").valueOf(),
        totimestamp: moment().valueOf(),
      });
      return;
    }

    let checkFrom = moment(value[0]);
    let checkTo = moment(value[1]);
    let duration = moment.duration(checkTo.diff(checkFrom));
    if (duration.asDays() < 0) {
      message.error("Please select a valid time range.");
      return;
    }

    let fromtimestamp = moment(value[0]).valueOf();
    let totimestamp = moment(value[1]).valueOf();

    this.setState(
      {
        fromtimestamp: fromtimestamp,
        totimestamp: totimestamp,
      },
      function () {
        this.refreshData();
      }
    );
  }

  handleAnalysisChange(value) {
    this.setState(
      { analysis_point: value, selected_trends: ["Transmission"] },
      function () {
        this.refreshData();
      }
    );
  }
  handleResultChange(value) {
    this.setState({ selected_trends: value }, function () {
      if (this.state.trend_data[this.state.analysis_point]) {
        this.calculateMainVisualization(
          this.state.analysis_point,
          this.state.trend_data[this.state.analysis_point]
        );
      }
    });
  }

  refreshData() {
    if (this.state.latest_data) {
      let diff = this.state.totimestamp - this.state.fromtimestamp;
      let diff_s = parseInt(Math.floor(diff / 1000), 10);
      this.setState({
        fromtimestamp: moment().subtract(diff_s, "seconds").valueOf(),
        totimestamp: moment().valueOf(),
      });
    }
    fetch(API_URL + "/data/vcon_config/", {
      method: "GET",
      headers: {
        Authorization: "Bearer " + this.props.getToken(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((res_config) => res_config.json())
      .then((data_config) => {
        this.setState(
          {
            analysis_points:
              data_config["vcon_configuration"]["analysis_points"],
          },

          function () {
            if (this.state.analysis_point === null) {
              let analysis_point =
                data_config["vcon_configuration"]["analysis_points"][0]
                  .analysis_number;
              this.setState({ analysis_point });
            }

            this.state.analysis_points.forEach(function (ap) {
              fetch(API_URL + "/data/trending", {
                method: "POST",
                headers: {
                  Authorization: "Bearer " + this.props.getToken(),
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  analysis_point: ap.analysis_number,
                  pixels: 100,
                  fromtimestamp: this.state.fromtimestamp,
                  totimestamp: this.state.totimestamp,
                }),
              })
                .then((res) => res.text())
                .then((res) => {
                  const uncompressed = zlib
                    .inflateSync(new Buffer(res, "base64"))
                    .toString();
                  res = JSON.parse(uncompressed);
                  if (
                    res["result_data"] &&
                    res["result_data"]["Transmission"]
                  ) {
                    this.setState({
                      refresh_time: moment(
                        res["result_data"]["Transmission"][
                          res["result_data"]["Transmission"].length - 1
                        ].x
                      ).format("YYYY-MM-DD hh:mm:ss A"),
                    });
                  }
                  this.calculateMainVisualization(ap.analysis_number, res);
                })
                .catch((error) => {
                  console.error(error);
                });
            }, this);

            let analysis_numbers = [];
            this.state.analysis_points.forEach(function (ap) {
              analysis_numbers.push(ap.analysis_number);
            });

            fetch(API_URL + "/data/latest_result_data", {
              method: "POST",
              headers: {
                Authorization: "Bearer " + this.props.getToken(),
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                analysis_points: analysis_numbers,
              }),
            })
              .then((res) => res.text())
              .then((res) => {
                const uncompressed = zlib
                  .inflateSync(new Buffer(res, "base64"))
                  .toString();
                res = JSON.parse(uncompressed);
                // Build Tables for Results
                let latest_result_data = {};
                let current_spectra_traces = {};
                analysis_numbers.forEach(function (ap) {
                  if (res[ap]) {
                    let ap_results = [];
                    Object.keys(res[ap]).forEach(function (key) {
                      if (key !== "spectra" && key !== "pcscores") {
                        if (res[ap][key] && res[ap][key]["name"]) {
                          let ap_object = {};
                          ap_object["analysis_point"] = ap;
                          ap_object["key"] = res[ap][key]["key"];
                          ap_object["hourlyavg"] = res[ap][key]["hourlyavg"];
                          ap_object["modelid"] = res[ap][key]["modelid"];
                          ap_object["name"] = res[ap][key]["name"];
                          ap_object["unit"] = res[ap][key]["unit"];
                          ap_object["normalized"] = res[ap][key]["n"];
                          ap_object["value"] = res[ap][key]["y"];
                          ap_object["timestamp"] = res[ap][key]["x"];
                          ap_object["t2"] = res[ap][key]["t2"];
                          ap_object["q"] = res[ap][key]["q"];
                          ap_object["type"] = res[ap][key]["t"];
                          ap_object["value_display_name"] =
                            res[ap][key]["value_display_name"];
                          ap_results.push(ap_object);
                        }
                      }
                      if (key === "spectra") {
                        let spectra_traces = [];

                        let signalArray = res[ap][key].map((a) => a.s);
                        let waveLengthArray = res[ap][key].map((a) => a.w);

                        let trace = {
                          x: waveLengthArray,
                          y: signalArray,
                          points: res[ap][key],
                          yaxis: "y",
                          name: "",
                          mode: "lines",
                          fillcolor: this.props.darkmode
                            ? "rgba(32, 32, 32, 0.6)"
                            : "rgba(187, 208, 232, 0.8)",
                          fill: "tonexty",
                          type: "scatter",
                          vis_type: "main",
                          connectgaps: false,
                          hovertemplate: "%{y}",
                          line: {
                            width: 1,
                            color: this.props.darkmode ? "#ff5c4d" : "black",
                          },
                        };
                        spectra_traces.push(trace);
                        current_spectra_traces[ap] = spectra_traces;
                      }
                    }, this);
                    latest_result_data[ap] = ap_results;
                  }
                }, this);

                this.setState(
                  { current_spectra_traces, latest_result_data },
                  function () {}
                );
              })
              .catch((error) => {
                console.error(error);
              });
          }
        );
      })
      .catch((error) => {
        console.error(error);
      });
  }

  calculateMainVisualization(analysis_number, trend_data) {
    let traces = [];
    let data_types = [];
    let group_ind_traces = this.state.group_ind_traces;
    let individual_traces = this.state.individual_traces;

    if (trend_data["result_data"]) {
      let trend_data_keys = Object.keys(trend_data["result_data"]);
      trend_data_keys.forEach(function (key) {
        data_types.push(trend_data["result_data"][key][0]);
      });
      const newDataTypes = {
        ...this.state.data_types,
        [analysis_number]: data_types,
      };

      this.setState({ data_types: newDataTypes });

      if (
        newDataTypes[analysis_number] &&
        newDataTypes[analysis_number].length > 0
      ) {
        if (trend_data["sample_size"]) {
          this.setState({
            sample_size_percentage:
              trend_data["sample_size"][0].sample_size_percentage,
            sample_size_pool: trend_data["sample_size"][0].sample_size_pool,
            sample_size_pixels: trend_data["sample_size"][0].sample_size_pixels,
          });
        }

        let selected_count = 0;
        if (
          this.state.selected_trends &&
          this.state.selected_trends.length > 0
        ) {
          selected_count = this.state.selected_trends.length;
        }

        // Main Visualization

        let color = 0;
        this.state.selected_trends.forEach(function (s) {
          if (trend_data["result_data"][s]) {
            let modelData = trend_data["result_data"][s];
            let xArray = modelData.map((a) => tickFormatter(new Date(a.x)));
            let yArray = modelData.map((a) => a.y);
            let valueDisplayNameArray = modelData.map((a) =>
              a.value_display_name === null ? "" : a.value_display_name
            );

            let sampleData = [];
            modelData.forEach(function (d) {
              if (d.sample === true) {
                sampleData.push(d);
              }
            });

            let sampleXArray = sampleData.map((a) =>
              tickFormatter(new Date(a.x))
            );
            let sampleYArray = sampleData.map((a) => a.y);
            let trace = {
              x: xArray,
              y: yArray,
              model: s,
              points: modelData,
              yaxis: "y",
              name: "",
              mode: "lines+markers",
              fill: selected_count === 1 ? "tonexty" : "",
              fillcolor: this.props.darkmode
                ? "rgba(32, 32, 32, 0.6)"
                : "rgba(187, 208, 232, 0.8)",
              type: "scatter",
              vis_type: "main",
              connectgaps: false,
              text: valueDisplayNameArray,
              hovertemplate: s + ": %{y} %{text}",
              marker: {
                size: 2.25,
                color: this.props.darkmode
                  ? shadeColor(colors[color], 50)
                  : colors[color],
                opacity: 1,
              },
              line: {
                width: 1.5,

                color: this.props.darkmode
                  ? shadeColor(colors[color], 50)
                  : colors[color],
              },
            };
            traces.push(trace);

            let samplesTrace = {
              x: sampleXArray,
              y: sampleYArray,
              model: s,
              points: modelData,
              name: "",
              mode: "markers",
              vis_type: "samples",
              yaxis: "y",
              hovertemplate: s + " - Sample: %{y}",
              type: "scatter",
              marker: {
                size: 15,
                symbol: "circle-open",

                color: this.props.darkmode
                  ? shadeColor(colors[color], 50)
                  : colors[color],
                opacity: 1,
              },
            };
            traces.push(samplesTrace);
          }
          color++;
        }, this);
      }
      let current_trend_data = this.state.trend_data;
      let current_traces = this.state.traces;
      current_traces[analysis_number] = traces;
      current_trend_data[analysis_number] = trend_data;
      this.setState({ traces: current_traces, trend_data: current_trend_data });

      // Individual Visualizations

      if (this.state.visualization_configuration) {
        Object.keys(this.state.visualization_configuration).forEach(function (
          key
        ) {
          let ind_traces = [];
          let group_traces = {};
          let visualization_configuration =
            this.state.visualization_configuration[key];
          if (
            visualization_configuration &&
            analysis_number === parseInt(key)
          ) {
            let color = 0;
            Object.keys(visualization_configuration).forEach(function (
              result_key
            ) {
              if (
                trend_data["result_data"][result_key] &&
                visualization_configuration[result_key]
              ) {
                let upper_threshold =
                  visualization_configuration[result_key]["upper_threshold"];
                let lower_threshold =
                  visualization_configuration[result_key]["lower_threshold"];
                let group = visualization_configuration[result_key]["group"];
                let group_display =
                  visualization_configuration[result_key]["group_display"];
                let modelData = trend_data["result_data"][result_key];
                let xArray = modelData.map((a) => tickFormatter(new Date(a.x)));
                let yArray = modelData.map((a) => a.y);
                let yUpperArray = Array(xArray.length).fill(upper_threshold);
                let yLowerArray = Array(xArray.length).fill(lower_threshold);
                let valueDisplayNameArray = modelData.map((a) =>
                  a.value_display_name === null ? "" : a.value_display_name
                );

                let sampleData = [];
                modelData.forEach(function (d) {
                  if (d.sample === true) {
                    sampleData.push(d);
                  }
                });

                let sampleXArray = sampleData.map((a) =>
                  tickFormatter(new Date(a.x))
                );
                let sampleYArray = sampleData.map((a) => a.y);
                let trace = {
                  x: xArray,
                  y: yArray,
                  model: result_key,
                  group: group,
                  group_display: group_display,
                  points: modelData,
                  yaxis: "y",
                  name: "",
                  mode: "lines+markers",
                  type: "scatter",
                  vis_type: "main",
                  connectgaps: false,
                  text: valueDisplayNameArray,
                  hovertemplate: result_key + ": %{y} %{text}",
                  marker: {
                    size: 2.25,

                    color: this.props.darkmode
                      ? shadeColor(colors[color], 50)
                      : colors[color],
                    opacity: 1,
                  },
                  line: {
                    width: 1.5,

                    color: this.props.darkmode
                      ? shadeColor(colors[color], 50)
                      : colors[color],
                  },
                };

                if (!group_traces[group]) {
                  group_traces[group] = [];
                }

                if (group && group !== null && group > 0) {
                  group_traces[group].push(trace);
                } else {
                  ind_traces.push(trace);
                }

                let lower_trace = {
                  x: xArray,
                  y: yLowerArray,
                  model: result_key,
                  group: group,
                  group_display: group_display,
                  points: modelData,
                  yaxis: "y",
                  name: "",
                  mode: "lines",
                  type: "scatter",
                  vis_type: "main",
                  connectgaps: false,
                  text: valueDisplayNameArray,
                  hoverinfo: "skip",
                  line: {
                    dash: "dot",
                    width: 1,
                    opacity: 0.25,

                    color: this.props.darkmode
                      ? shadeColor(colors[color], 50)
                      : colors[color],
                  },
                };

                if (group && group !== null && group > 0) {
                  group_traces[group].push(lower_trace);
                } else {
                  ind_traces.push(lower_trace);
                }

                let upper_trace = {
                  x: xArray,
                  y: yUpperArray,
                  model: result_key,
                  group: group,
                  group_display: group_display,
                  points: modelData,
                  yaxis: "y",
                  name: "",
                  mode: "lines",
                  type: "scatter",
                  vis_type: "main",
                  connectgaps: false,
                  hoverinfo: "skip",
                  text: valueDisplayNameArray,
                  line: {
                    dash: "dot",
                    width: 1,
                    opacity: 0.25,

                    color: this.props.darkmode
                      ? shadeColor(colors[color], 50)
                      : colors[color],
                  },
                };

                if (group && group !== null && group > 0) {
                  group_traces[group].push(upper_trace);
                } else {
                  ind_traces.push(upper_trace);
                }

                let samplesTrace = {
                  x: sampleXArray,
                  y: sampleYArray,
                  model: result_key,
                  group: group,
                  group_display: group_display,
                  points: modelData,
                  name: "",
                  mode: "markers",
                  vis_type: "samples",
                  yaxis: "y",
                  hovertemplate: result_key + " - Sample: %{y}",
                  type: "scatter",
                  marker: {
                    size: 15,
                    symbol: "circle-open",

                    color: this.props.darkmode
                      ? shadeColor(colors[color], 50)
                      : colors[color],
                    opacity: 1,
                  },
                };

                if (group && group !== null && group > 0) {
                  group_traces[group].push(samplesTrace);
                } else {
                  ind_traces.push(samplesTrace);
                }
              }
              color++;
            },
            this);
            group_ind_traces[key] = group_traces;
            individual_traces[key] = ind_traces;
          }
        },
        this);
      }
      this.setState({ individual_traces }, function () {
        let group_dict = {};
        Object.keys(group_ind_traces).forEach((a) => {
          if (group_ind_traces[a]) {
            Object.keys(group_ind_traces[a]).forEach((b) => {
              if (
                b &&
                a &&
                b !== "undefined" &&
                a !== "undefined" &&
                group_ind_traces[a][b]
              ) {
                if (!group_dict[b]) {
                  group_dict[b] = [];
                }
                group_ind_traces[a][b].forEach(function (obj) {
                  if (obj) {
                    group_dict[b].push(obj);
                  }
                });
              }
            });
          }
        });
        this.setState({ group_dict });
      });
    }
  }

  render() {
    if (this.state.default_landing_key) {
      return (
        <div>
          <Tabs
            size="small"
            defaultActiveKey={this.state.default_landing_key}
            tabBarExtraContent={
              <span style={{ verticalAlign: "center" }}>
                <span
                  style={{
                    verticalAlign: "center",
                    fontSize: "16px",
                    padding: "5px",
                    marginRight: "20px",
                  }}
                >
                  <b>Last Update: {this.state.refresh_time}</b>
                </span>
              </span>
            }
          >
            <TabPane tab="Visualization" key="1">
              <Row gutter={[24, 24]}>
                <Col
                  onTouchStart={() => this.setState({ hovering: false })}
                  onClick={() => this.setState({ hovering: false })}
                  gutter={[24, 24]}
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  xxl={24}
                >
                  <div align="center">
                    <Select
                      placeholder="Analysis Point"
                      style={{ marginRight: "10px", width: "200px" }}
                      value={this.state.analysis_point}
                      onChange={this.handleAnalysisChange}
                    >
                      {this.state.analysis_points.map((a) => {
                        return (
                          <Option
                            value={a.analysis_number}
                            key={a.analysis_number}
                          >
                            {"A" + a.analysis_number + ": " + a.display_name}
                          </Option>
                        );
                      })}
                    </Select>
                    <Select
                      mode="multiple"
                      allowClear
                      tagRender={this.tagRender}
                      value={this.state.selected_trends}
                      style={{ width: "400px" }}
                      placeholder="Select Result"
                      onChange={this.handleResultChange}
                    >
                      {this.state.data_types[this.state.analysis_point]
                        ? this.state.data_types[this.state.analysis_point].map(
                            (dt) => {
                              return (
                                <Option value={dt.key} key={dt.key}>
                                  {dt.name + " " + dt.unit}
                                </Option>
                              );
                            }
                          )
                        : null}
                    </Select>
                  </div>
                  <div align="center">
                    <RangePicker
                      showTime={{
                        format: "hh:mm A z",
                        defaultValue: [
                          moment("00:00 AM", "HH:mm A"),
                          moment("11:59 PM", "HH:mm A"),
                        ],
                      }}
                      style={{ margin: "5px" }}
                      value={[
                        moment(this.state.fromtimestamp),
                        moment(this.state.totimestamp),
                      ]}
                      format="YYYY/MM/DD hh:mm A z"
                      placeholder={["Start Time", "End Time"]}
                      onChange={this.handleSetCustomTimeRange}
                      renderExtraFooter={() => (
                        <span>
                          <Button
                            onClick={() => this.handleChange(1)}
                            style={{ marginRight: "5px" }}
                          >
                            1h
                          </Button>

                          <Button
                            onClick={() => this.handleChange(4)}
                            style={{ marginRight: "5px" }}
                          >
                            4h
                          </Button>

                          <Button
                            onClick={() => this.handleChange(24)}
                            style={{ marginRight: "5px" }}
                          >
                            24h
                          </Button>

                          <Button
                            onClick={() => this.handleChange(72)}
                            style={{ marginRight: "5px" }}
                          >
                            3d
                          </Button>

                          <Button
                            onClick={() => this.handleChange(168)}
                            style={{ marginRight: "5px" }}
                          >
                            7d
                          </Button>
                        </span>
                      )}
                    />
                    <Switch
                      style={{ marginLeft: "20px" }}
                      checkedChildren="Latest Data"
                      unCheckedChildren="Latest Data"
                      checked={this.state.latest_data}
                      onClick={() =>
                        this.setState(
                          {
                            latest_data: !this.state.latest_data,
                          },
                          function () {
                            this.refreshData();
                          }
                        )
                      }
                    />
                  </div>
                </Col>
                <Col
                  gutter={[24, 24]}
                  xs={16}
                  sm={16}
                  md={16}
                  lg={16}
                  xl={16}
                  xxl={16}
                >
                  <Card
                    size="small"
                    title="Visualization Overview"
                    onMouseLeave={() => this.setState({ hovering: false })}
                  >
                    <div align="center">
                      <PlotVisualization
                        darkmode={this.props.darkmode}
                        onHover={this.onHover}
                        traces={this.state.traces[this.state.analysis_point]}
                        width={this.props.width / 1.75}
                        height={this.props.height / 3}
                      />
                    </div>
                  </Card>
                  <Card
                    size="small"
                    style={{ width: "100%", marginTop: "24px" }}
                    title={
                      this.state.hovering
                        ? "Hovered Spectra"
                        : "Current Spectra"
                    }
                  >
                    <div align="center">
                      <Plot
                        data={
                          this.state.hovering
                            ? this.state.spectra_traces
                            : this.state.current_spectra_traces[
                                this.state.analysis_point
                              ]
                        }
                        layout={{
                          paper_bgcolor: "rgba(0,0,0,0)",
                          plot_bgcolor: "rgba(0,0,0,0)",
                          showlegend: false,
                          width: this.props.width / 1.75,
                          height: this.props.height / 2.5,
                          hovermode: "x",
                          spikedistance: -1,
                          title: {
                            font: {
                              family: "Roboto",
                              size: 12,
                            },
                          },
                          margin: {
                            l: 25,
                            r: 25,
                            b: 25,
                            t: 25,
                            pad: 4,
                          },
                          xaxis: {
                            range: this.props.range,
                            rangeslider: this.props.plotly_slider
                              ? { range: this.props.range }
                              : false,
                            font: {
                              family: "sans-serif",
                              size: 10,
                              color: this.props.darkmode ? "#fff" : "#000",
                            },
                            showgrid: false,
                            tickfont: {
                              family: "sans-serif",
                              size: 10,
                              color: this.props.darkmode ? "#fff" : "#000",
                            },
                            automargin: true,
                            showspikes: true,
                            spikethickness: 1,
                            spikemode: "across+toaxis",
                            spikesnap: "cursor",
                            showLine: true,
                            spikedash: "solid",
                          },
                          // Left Y Axis Snaps
                          yaxis: {
                            showgrid: false,
                            tickfont: {
                              family: "sans-serif",
                              size: 12,
                              color: this.props.darkmode ? "#fff" : "#000",
                            },
                            automargin: true,
                            showspikes: false,
                            spikemode: "across+toaxis",
                            spikesnap: "cursor",
                            showLine: true,
                            spikedash: "solid",
                          },
                        }}
                        config={{
                          displayModeBar: false,
                          displaylogo: false,
                          modeBarButtonsToRemove: ["lasso2d", "select2d"],
                        }}
                      />
                    </div>
                  </Card>
                </Col>
                <Col
                  gutter={[24, 24]}
                  xs={8}
                  sm={8}
                  md={8}
                  lg={8}
                  xl={8}
                  xxl={8}
                >
                  <Card
                    size="small"
                    title={
                      this.state.hovering
                        ? "Result Table (Hovering)"
                        : "Result Table"
                    }
                    extra={
                      <Button
                        size="small"
                        type="primary"
                        onClick={() =>
                          this.setState({ json_load_dialog: true })
                        }
                      >
                        JSON
                      </Button>
                    }
                  >
                    <Table
                      pagination={false}
                      rowKey="key"
                      dataSource={
                        this.state.hovering
                          ? this.state.hovered_result_data[
                              this.state.analysis_point
                            ]
                          : this.state.latest_result_data[
                              this.state.analysis_point
                            ]
                      }
                      size="small"
                      columns={[
                        {
                          title: "Result",
                          dataIndex: "name",
                          defaultSortOrder: "ascend",
                          sorter: (a, b) => {
                            if (a.name && b.name) {
                              if (a.name === "Temperature") {
                                return -1;
                              }
                              if (a.name === "Pressure") {
                                return -1;
                              }
                              if (a.name === "Transmission") {
                                return -1;
                              }
                              return a.name.localeCompare(b.name);
                            }
                          },
                        },
                        {
                          title: "Value",
                          render: (record) => {
                            return record.value + " " + record.unit;
                          },
                        },
                      ]}
                    />
                  </Card>
                </Col>
              </Row>
            </TabPane>

            <TabPane tab="Result Summary" key="2">
              <Row gutter={[6, 6]} align="top">
                {this.state.analysis_points.map((a) => {
                  let filtered_dataset_hovering =
                    this.state.hovered_result_data[a.analysis_number];
                  let filtered_dataset_not_hovering =
                    this.state.latest_result_data[a.analysis_number];

                  if (this.state.hidden_results[a.analysis_number]) {
                    this.state.hidden_results[a.analysis_number].forEach(
                      function (result) {
                        if (filtered_dataset_hovering !== undefined) {
                          filtered_dataset_hovering =
                            filtered_dataset_hovering.filter(
                              (x) => x.key !== result
                            );
                        }

                        if (filtered_dataset_not_hovering !== undefined) {
                          filtered_dataset_not_hovering =
                            filtered_dataset_not_hovering.filter(
                              (x) => x.key !== result
                            );
                        }
                      }
                    );
                  }

                  if (
                    this.state.latest_result_data &&
                    this.state.latest_result_data[a.analysis_number] &&
                    this.state.latest_result_data[a.analysis_number].length > 0
                  ) {
                    return (
                      <Col
                        gutter={[24, 24]}
                        flex="auto"
                        key={"tabInfo" + a.analysis_number}
                      >
                        <Card
                          size="small"
                          onMouseLeave={() =>
                            this.setState({ hovering: false })
                          }
                          title={a.display_name}
                          extra={
                            <a
                              href="#"
                              onClick={() =>
                                this.setState({
                                  edit_mode: !this.state.edit_mode,
                                  edit_analysis_number: a.analysis_number,
                                })
                              }
                            >
                              {this.state.edit_mode &&
                              this.state.edit_analysis_number ===
                                a.analysis_number
                                ? "Done"
                                : "Edit"}
                            </a>
                          }
                        >
                          <Table
                            showHeader={false}
                            size="small"
                            pagination={false}
                            rowKey="key"
                            dataSource={
                              this.state.edit_mode &&
                              this.state.edit_analysis_number ===
                                a.analysis_number
                                ? this.state.latest_result_data[
                                    a.analysis_number
                                  ]
                                : this.state.hovering
                                ? filtered_dataset_hovering
                                : filtered_dataset_not_hovering
                            }
                            columns={
                              this.state.edit_mode &&
                              this.state.edit_analysis_number ===
                                a.analysis_number
                                ? [
                                    {
                                      title: "Result",
                                      dataIndex: "name",
                                      defaultSortOrder: "ascend",
                                      sorter: (a, b) => {
                                        if (a.name && b.name) {
                                          if (a.name === "Temperature") {
                                            return -1;
                                          }
                                          if (a.name === "Pressure") {
                                            return -1;
                                          }
                                          if (a.name === "Transmission") {
                                            return -1;
                                          }
                                          return a.name.localeCompare(b.name);
                                        }
                                      },
                                    },
                                    {
                                      title: "Value",
                                      render: (record) => {
                                        return record.value + " " + record.unit;
                                      },
                                    },
                                    {
                                      title: "",
                                      render: (record) => {
                                        if (
                                          this.state.hidden_results &&
                                          this.state.hidden_results[
                                            a.analysis_number
                                          ] &&
                                          this.state.hidden_results[
                                            a.analysis_number
                                          ].includes(record.key)
                                        ) {
                                          return (
                                            <EyeOutlined
                                              style={{ color: "red" }}
                                              onClick={() =>
                                                this.hideResult(
                                                  record.key,
                                                  a.analysis_number
                                                )
                                              }
                                            />
                                          );
                                        } else {
                                          return (
                                            <EyeOutlined
                                              onClick={() =>
                                                this.hideResult(
                                                  record.key,
                                                  a.analysis_number
                                                )
                                              }
                                            />
                                          );
                                        }
                                      },
                                    },

                                    {
                                      title: "",
                                      render: (record) => {
                                        if (
                                          this.state
                                            .visualization_configuration &&
                                          this.state
                                            .visualization_configuration[
                                            a.analysis_number
                                          ] &&
                                          this.state
                                            .visualization_configuration[
                                            a.analysis_number
                                          ][record.key]
                                        ) {
                                          return (
                                            <PlusSquareOutlined
                                              style={{ color: "#0099cc" }}
                                              onClick={() =>
                                                this.toggleResultVisualization(
                                                  record.key,
                                                  a.analysis_number
                                                )
                                              }
                                            />
                                          );
                                        } else {
                                          return (
                                            <PlusSquareOutlined
                                              onClick={() =>
                                                this.toggleResultVisualization(
                                                  record.key,
                                                  a.analysis_number
                                                )
                                              }
                                            />
                                          );
                                        }
                                      },
                                    },
                                  ]
                                : [
                                    {
                                      title: "Result",
                                      dataIndex: "name",
                                      defaultSortOrder: "ascend",
                                      sorter: (a, b) => {
                                        if (a.name === "Temperature") {
                                          return -1;
                                        }
                                        if (a.name === "Pressure") {
                                          return -1;
                                        }
                                        if (a.name === "Transmission") {
                                          return -1;
                                        }
                                        if (a.name && b.name) {
                                          return a.name.localeCompare(b.name);
                                        }
                                      },
                                    },
                                    {
                                      title: "Value",
                                      render: (record) => {
                                        return record.value + " " + record.unit;
                                      },
                                    },
                                    {
                                      title: "",
                                      render: (record) => {
                                        if (
                                          this.state
                                            .visualization_configuration &&
                                          this.state
                                            .visualization_configuration[
                                            a.analysis_number
                                          ] &&
                                          this.state
                                            .visualization_configuration[
                                            a.analysis_number
                                          ][record.key]
                                        ) {
                                          let colorCode = Object.keys(
                                            this.state
                                              .visualization_configuration[
                                              a.analysis_number
                                            ]
                                          ).indexOf(record.key);
                                          return (
                                            <CheckCircleOutlined
                                              style={{
                                                fontSize: "14pt",
                                                color: this.props.darkmode
                                                  ? shadeColor(
                                                      colors[colorCode],
                                                      50
                                                    )
                                                  : colors[colorCode],
                                              }}
                                            />
                                          );
                                        }
                                      },
                                    },
                                  ]
                            }
                          />
                        </Card>
                      </Col>
                    );
                  } else {
                    return null;
                  }
                })}
              </Row>
              <Divider></Divider>

              <Row gutter={[6, 6]}>
                {this.state.analysis_points.map((a) => {
                  if (
                    this.state.visualization_configuration[a.analysis_number] &&
                    this.state.visualization_configuration[a.analysis_number] &&
                    this.state.individual_traces[a.analysis_number] &&
                    this.state.individual_traces[a.analysis_number].length > 0
                  ) {
                    return (
                      <Col
                        gutter={[24, 24]}
                        xs={6}
                        sm={6}
                        md={6}
                        lg={6}
                        xl={6}
                        xxl={6}
                      >
                        <Card
                          size="small"
                          title={a.display_name}
                          onMouseLeave={() =>
                            this.setState({ hovering: false })
                          }
                        >
                          <div align="center">
                            <PlotVisualization
                              darkmode={this.props.darkmode}
                              visualization_configuration={
                                this.state.visualization_configuration[
                                  a.analysis_number
                                ]
                              }
                              onHover={this.onHover}
                              traces={
                                this.state.individual_traces[a.analysis_number]
                              }
                              width={this.props.width / 4.5}
                              height={this.props.height / 5}
                            />
                          </div>
                        </Card>
                      </Col>
                    );
                  }
                })}
                {Object.keys(this.state.group_dict).map((a) => {
                  let group_display = "Group " + a;
                  if (
                    a &&
                    a !== "null" &&
                    a !== "undefined" &&
                    this.state.group_dict[a]
                  ) {
                    this.state.group_dict[a].forEach(function (t) {
                      if (t.group_display && t.group_display !== "") {
                        group_display = t.group_display;
                      }
                    });
                    return (
                      <Col
                        key={a + "visgroup"}
                        gutter={[24, 24]}
                        xs={6}
                        sm={6}
                        md={6}
                        lg={6}
                        xl={6}
                        xxl={6}
                      >
                        <Card
                          size="small"
                          title={group_display}
                          onMouseLeave={() =>
                            this.setState({ hovering: false })
                          }
                        >
                          <div align="center">
                            <PlotVisualization
                              darkmode={this.props.darkmode}
                              visualization_configuration={
                                this.state.visualization_configuration[
                                  a.analysis_number
                                ]
                              }
                              onHover={this.onHover}
                              traces={this.state.group_dict[a]}
                              width={this.props.width / 4.5}
                              height={this.props.height / 5}
                            />
                          </div>
                        </Card>
                      </Col>
                    );
                  } else {
                    return null;
                  }
                })}
              </Row>
            </TabPane>
          </Tabs>

          <Modal
            onCancel={() =>
              this.setState({ visualization_threshold_dialog: false })
            }
            destroyOnClose={true}
            title="Visualization Configuration"
            visible={this.state.visualization_threshold_dialog}
            footer={null}
            closable
          >
            <VisualizationForm
              onFinishVisualization={this.onFinishVisualization}
              data={
                this.state.latest_result_data[
                  this.state.visualization_threshold_selected_ap
                ]
              }
              vis_key={this.state.visualization_threshold_selected_key}
              visualization_configuration={
                this.state.visualization_configuration[
                  this.state.visualization_threshold_selected_ap
                ]
              }
            />
          </Modal>

          <Modal
            onCancel={() => this.setState({ json_load_dialog: false })}
            destroyOnClose={true}
            title="JSON Configuration Loader"
            visible={this.state.json_load_dialog}
            footer={null}
            closable
          >
            <JSONLoaderForm
              onFinishJSON={this.onFinishJSON}
              currentJSON={this.state.json_configuration}
            />
          </Modal>
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}

export default Data;
