import React from "react";
import Firmware from "../components/Configuration/Firmware/Firmware";

export default function FirmwarePage(props) {
  return (
    <div>
      <Firmware {...props} />
    </div>
  );
}
