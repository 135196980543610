import { Button, Form, Input, Alert } from "antd";
import React from "react";

const { TextArea } = Input;

const JSONLoaderForm = (props) => {
  const [form] = Form.useForm();

  let initialValues = {
    json: JSON.stringify(props.currentJSON),
  };

  function onFinish(values) {
    props.onFinishJSON(values);
  }
  return (
    <div>
      <div align="center">
        <Alert
          style={{ marginBottom: "20px" }}
          description="Copy or Paste JSON to load or save a configuration state. Configuration is saved to the browsers cache for future recall."
          type="info"
        />
      </div>
      <Form
        id="jsonLoaderForm"
        layout="horizontal"
        form={form}
        initialValues={initialValues}
        onFinish={(values) => onFinish(values)}
        size="large"
      >
        <Form.Item label="JSON" name="json" rules={[{ required: true }]}>
          <TextArea showCount rows={15} maxLength={75000} />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" style={{ float: "right" }}>
            Save
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default JSONLoaderForm;
