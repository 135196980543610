import React, { Component } from "react";
import { Breadcrumb, Dropdown, Menu, Typography } from "antd";
import {
  CheckCircleFilled,
  ExperimentOutlined,
  WarningFilled,
  LoginOutlined,
  LogoutOutlined,
  UserOutlined,
  BulbOutlined,
  HomeOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import logo from "../logo_only.png";
import { Layout, Button } from "antd";

const { Header } = Layout;

class NavBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      time: null,
    };
  }

  componentDidMount() {}

  render() {
    let breadcrumbs = [];
    if (
      window.location &&
      window.location.pathname &&
      window.location.pathname !== "/"
    )
      breadcrumbs.push(
        <Breadcrumb.Item key="home">
          <HomeOutlined />
          <Link to="/">Home</Link>
        </Breadcrumb.Item>
      );

    if (
      window.location &&
      window.location.pathname &&
      window.location.pathname === "/sample"
    ) {
      breadcrumbs.push(
        <Breadcrumb.Item key="sample">
          <ExperimentOutlined />
          <Link to="/sample">Sample</Link>
        </Breadcrumb.Item>
      );
    }
    if (
      window.location &&
      window.location.pathname &&
      window.location.pathname === "/health"
    ) {
      breadcrumbs.push(
        <Breadcrumb.Item key="health">
          <ExperimentOutlined />
          <Link to="/health">Health</Link>
        </Breadcrumb.Item>
      );
    }
    if (
      window.location &&
      window.location.pathname &&
      window.location.pathname === "/data"
    ) {
      breadcrumbs.push(
        <Breadcrumb.Item key="data">
          <ExperimentOutlined />
          <Link to="/data">Data</Link>
        </Breadcrumb.Item>
      );
    }
    if (
      window.location &&
      window.location.pathname &&
      window.location.pathname === "/troubleshoot"
    ) {
      breadcrumbs.push(
        <Breadcrumb.Item key="troubleshoot">
          <ExperimentOutlined />
          <Link to="/troubleshoot">Troubleshoot</Link>
        </Breadcrumb.Item>
      );
    }
    if (
      window.location &&
      window.location.pathname &&
      window.location.pathname === "/config"
    ) {
      breadcrumbs.push(
        <Breadcrumb.Item key="config">
          <ExperimentOutlined />
          <Link to="/config">Config</Link>
        </Breadcrumb.Item>
      );
    }
    if (
      window.location &&
      window.location.pathname &&
      window.location.pathname === "/config/models"
    ) {
      breadcrumbs.push(
        <Breadcrumb.Item key="config">
          <ExperimentOutlined />
          <Link to="/config">Config</Link>
        </Breadcrumb.Item>
      );
      breadcrumbs.push(
        <Breadcrumb.Item key="config_models">
          <ExperimentOutlined />
          <Link to="/config/models">Models</Link>
        </Breadcrumb.Item>
      );
    }
    if (
      window.location &&
      window.location.pathname &&
      window.location.pathname === "/config/web"
    ) {
      breadcrumbs.push(
        <Breadcrumb.Item key="config">
          <ExperimentOutlined />
          <Link to="/config">Config</Link>
        </Breadcrumb.Item>
      );
      breadcrumbs.push(
        <Breadcrumb.Item key="config_web">
          <ExperimentOutlined />
          <Link to="/config/web">Web Config</Link>
        </Breadcrumb.Item>
      );
    }
    if (
      window.location &&
      window.location.pathname &&
      window.location.pathname === "/config/vcon"
    ) {
      breadcrumbs.push(
        <Breadcrumb.Item key="config">
          <ExperimentOutlined />
          <Link to="/config">Config</Link>
        </Breadcrumb.Item>
      );
      breadcrumbs.push(
        <Breadcrumb.Item key="config_vcon">
          <ExperimentOutlined />
          <Link to="/config/vcon">VCON</Link>
        </Breadcrumb.Item>
      );
    }
    if (
      window.location &&
      window.location.pathname &&
      window.location.pathname === "/config/external"
    ) {
      breadcrumbs.push(
        <Breadcrumb.Item key="config">
          <ExperimentOutlined />
          <Link to="/config">Config</Link>
        </Breadcrumb.Item>
      );
      breadcrumbs.push(
        <Breadcrumb.Item key="config_external">
          <ExperimentOutlined />
          <Link to="/config/external">External Hardware</Link>
        </Breadcrumb.Item>
      );
    }
    if (
      window.location &&
      window.location.pathname &&
      window.location.pathname === "/config/firmware"
    ) {
      breadcrumbs.push(
        <Breadcrumb.Item key="config">
          <ExperimentOutlined />
          <Link to="/config">Config</Link>
        </Breadcrumb.Item>
      );
      breadcrumbs.push(
        <Breadcrumb.Item key="config_firmware">
          <ExperimentOutlined />
          <Link to="/config/firmware">Firmware</Link>
        </Breadcrumb.Item>
      );
    }

    let status_widget = [];

    if (this.props.level === 0) {
      status_widget = (
        <Link to="health">
          <CheckCircleFilled
            style={{
              color: "#00a234",
              paddingRight: 10,
              verticalAlign: "middle",
              fontSize: "34px",
            }}
          />
        </Link>
      );
    } else if (this.props.level === 1) {
      status_widget = (
        <Link to="health">
          <WarningFilled
            style={{
              color: "#FF8700",
              paddingRight: 10,
              verticalAlign: "middle",
              fontSize: "34px",
            }}
          />
        </Link>
      );
    } else if (this.props.level === 2) {
      status_widget = (
        <Link to="health">
          <WarningFilled
            style={{
              color: "#FF0000",
              paddingRight: 10,
              verticalAlign: "middle",
              fontSize: "34px",
            }}
          />
        </Link>
      );
    }
    return (
      <div>
        <Header
          style={{
            paddingLeft: "1%",
            paddingRight: "1%",
            backgroundColor: "hsl(215, 0%, 2%)",
          }}
        >
          <div style={{ float: "left" }}>
            <Link to="/">
              <img
                width={90}
                height={55}
                src={logo}
                alt="JP3"
                style={{ verticalAlign: "middle" }}
              />
            </Link>
          </div>
          <div style={{ float: "right" }}>
            {status_widget}
            <Dropdown
              overlay={
                <Menu>
                  {this.props.authorized ? (
                    <Menu.Item
                      key="logout"
                      onClick={() => {
                        sessionStorage.removeItem("jp3_user");
                        window.location = "/";
                      }}
                    >
                      <Typography>
                        <LogoutOutlined style={{ paddingRight: "10px" }} />
                        Logout
                      </Typography>
                    </Menu.Item>
                  ) : (
                    <Menu.Item
                      key="login"
                      onClick={() => this.props.login_dialog_toggle()}
                    >
                      <Typography>
                        <LoginOutlined
                          style={{ paddingRight: "10px" }}
                          onClick={() => {
                            this.props.login_dialog_toggle();
                          }}
                        />
                        Login
                      </Typography>
                    </Menu.Item>
                  )}
                  <Menu.Item
                    key="darklight"
                    onClick={() => this.props.toggleDarkMode()}
                  >
                    <Typography>
                      <BulbOutlined style={{ paddingRight: "10px" }} />
                      Toggle Dark/Light Theme
                    </Typography>
                  </Menu.Item>
                </Menu>
              }
            >
              <Button size="large" shape="circle" icon={<UserOutlined />} />
            </Dropdown>
          </div>
        </Header>
        <div style={{ padding: "10px" }}>
          <Breadcrumb>{breadcrumbs}</Breadcrumb>
        </div>
      </div>
    );
  }
}

export default NavBar;
