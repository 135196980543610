import React from "react";
import Models from "../components/Configuration/Models/Models";

export default function ModelsConfigPage(props) {
  return (
    <div>
      <Models {...props} />
    </div>
  );
}
