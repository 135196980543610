import { Button, Form, InputNumber, Alert, Input } from "antd";
import React from "react";

const VisualizationForm = (props) => {
  const [form] = Form.useForm();

  let upper_threshold = null;
  let lower_threshold = null;
  let group = null;
  let display = null;

  let initialValues = {
    upper_threshold: upper_threshold,
    lower_threshold: lower_threshold,
    group: group,
    group_display: display,
  };

  function onFinish(values) {
    props.onFinishVisualization(values);
  }
  return (
    <div>
      <div align="center">
        <Alert
          style={{ marginBottom: "20px" }}
          description="Select a visualization group number which associates this result to a visualization grouping below. If no group is selected, the default visualization group will be analysis point specific. Optionally, you may also select a range criteria for this result to fall between. Selecting no criteria will disable the range criteria. "
          type="info"
        />
      </div>
      <Form
        id="visualizationForm"
        layout="horizontal"
        form={form}
        initialValues={initialValues}
        onFinish={(values) => onFinish(values)}
        size="large"
      >
        <Form.Item
          label="Group Number"
          name="group"
          rules={[{ required: false }]}
        >
          <InputNumber min={0} max={50} />
        </Form.Item>
        <Form.Item
          label="Group Display"
          name="group_display"
          rules={[{ required: false }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Lower Threshold"
          name="lower_threshold"
          rules={[{ required: false }]}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item
          label="Upper Threshold"
          name="upper_threshold"
          rules={[{ required: false }]}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" style={{ float: "right" }}>
            Save
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default VisualizationForm;
