import React from "react";
import {Card, Form, Row, Select, Switch, InputNumber, Button} from "antd";

const {Option} = Select;

const VCONModbusForm = (props) => {
    const [form] = Form.useForm();
    let swapped_fp_checked = false;
    if(props.data['swapped_fp'] === true || props.data['swapped_fp'] === 1){
        swapped_fp_checked = true;
    }
    function onFinish(values) {
        props.onVCONFinish("modbus", values);
    }
    return(
        <Card>
            <Form
                id="vconModbusForm"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 14 }}
                layout="horizontal"
                form={form}
                initialValues={props.data}
                onFinish={(values) => onFinish(values)}
                size="large"
            >
                <Row align="center">
                    <Form.Item
                        label="Node"
                        name="node"
                        rules={[{ required: true }]}
                    >
                        <InputNumber placeholder="Input" style={{ width: 75 }} />
                    </Form.Item>
                    <Form.Item
                        label="Serial Port"
                        name="port1"
                        rules={[{ required: true }]}
                    >
                        <InputNumber placeholder="Input" style={{ width: 75 }} />
                    </Form.Item>
                    <Form.Item
                        label="Swapped FP"
                        name="swapped_fp"
                        rules={[{ required: true }]}
                    >
                        <Switch defaultChecked={swapped_fp_checked} checkedChildren="Yes" unCheckedChildren="No" />
                    </Form.Item>
                    <Form.Item
                        label="Baud Rate"
                        name="baudRate"
                        rules={[{ required: true }]}
                    >
                        <Select placeholder="Select Rate" style={{ width: 100 }}>
                            <Option value={300}>300</Option>
                            <Option value={600}>600</Option>
                            <Option value={1200}>1200</Option>
                            <Option value={2400}>2400</Option>
                            <Option value={4800}>4800</Option>
                            <Option value={9600}>9600</Option>
                            <Option value={19200}>19200</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Serial Port 2"
                        name="port2"
                        rules={[{ required: true }]}
                    >
                        <InputNumber placeholder="Input" style={{ width: 75 }} />
                    </Form.Item>
                </Row>
                <Row align="center" style={{ paddingTop: 10 }}>
                    <Form.Item style={{ paddingRight: 10 }}>
                        <Button type="primary" style={{ color: "#000000" }}>
                            Spectrometers Diagnostic Collection
                        </Button>
                    </Form.Item>
                    <Form.Item style={{ paddingRight: 10 }}>
                        <Button type="primary" style={{ color: "#790000" }}>
                            Power OFF Spectrometer 1
                        </Button>
                    </Form.Item>
                    <Form.Item style={{ paddingRight: 10 }}>
                        <Button type="primary" style={{ color: "#790000" }}>
                            Power OFF Spectrometer 2
                        </Button>
                    </Form.Item>
                    <Form.Item style={{ paddingRight: 10 }}>
                        <Button
                            type="primary"
                            style={{ backgroundColor: "yellow", color: "black" }}
                            onClick={() => form.resetFields()}
                        >
                            CANCEL
                        </Button>
                    </Form.Item>
                    <Form.Item>
                        <Button
                            type="primary"
                            form="vconModbusForm"
                            htmlType="submit"
                            style={{ backgroundColor: "lightgreen", color: "black" }}
                        >
                            SAVE
                        </Button>
                    </Form.Item>
                </Row>
            </Form>
        </Card>
    );
};

export default VCONModbusForm;