import { Button, Form, Input, Select } from "antd";
import React from "react";
var bcrypt = require("bcryptjs");

const { Option } = Select;
const UserForm = (props) => {
  let new_user = true;
  const [form] = Form.useForm();
  if (!props.user.access_level) {
    props.user.access_level = 2;
  }
  form.setFieldsValue(props.user);
  if (props.user.user_id) {
    new_user = false;
  }

  function onFinish(values) {
    if (props.user.user_id) {
      values.user_id = props.user.user_id;
    }
    const hashedPassword = bcrypt.hashSync(
      values.password,
      "$2a$10$vUF9.ihfQZJskyhO2.QH/e"
    ); // Salt.
    values.password = hashedPassword;
    props.onFinish(values);
  }
  return (
    <Form
      id="userForm"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 10 }}
      layout="horizontal"
      form={form}
      onFinish={(values) => onFinish(values)}
      size="large"
    >
      <Form.Item label="Username" name="username" rules={[{ required: true }]}>
        <Input placeholder="Username" disabled={new_user ? false : true} />
      </Form.Item>
      <Form.Item label="Password" name="password" rules={[{ required: true }]}>
        <Input.Password placeholder="Password" />
      </Form.Item>
      <Form.Item label="Access Level" name="access_level">
        <Select defaultValue={2} style={{ width: 120 }}>
          <Option value={1}>Admin</Option>
          <Option value={2}>Customer</Option>
        </Select>
      </Form.Item>
      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button type="primary" htmlType="submit">
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};

export default UserForm;
