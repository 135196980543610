import React from "react";
import Sample from "../components/Sample/Sample";

export default function SamplePage(props) {
  return (
    <div>
      <Sample {...props} />
    </div>
  );
}
