import React from "react";
import {Card, Form, Input, Row, Select, Switch, InputNumber, Divider, Button} from "antd";

const {Option} = Select;

const VCONAnalysesForm = (props) => {
    const [form] = Form.useForm();
    let trigger_checked = false;
    let timer_checked = false;
    let truck_on_output_checked = false;
    let flow_meter_checked = false;
    if(props.data['trigger'] === true || props.data['trigger'] === "T"){
        trigger_checked = true;
    }
    if(props.data['timer_enabled'] === true || props.data['timer_enabled'] === 1){
        timer_checked = true;
    }
    if(props.data['truck_avg_output_method'] === true || props.data['truck_avg_output_method'] === "R"){
        truck_on_output_checked = true;
    }
    if(props.data['flow_meter_enabled'] === true || props.data['flow_meter_enabled'] === 1) {
        flow_meter_checked = true;
    }
    function onFinish(values) {
        props.onVCONFinish("analyses", values);
    }
    return(
        <Card>
            <Form
                id="vconAnalysesForm"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 14 }}
                layout="horizontal"
                form={form}
                initialValues={props.data}
                onFinish={(values) => onFinish(values)}
                size="large"
            >
                <Row align="center">
                    <Form.Item
                        label="Timer"
                        name="timer_enabled"
                        rules={[{ required: true }]}
                    >
                        <Switch defaultChecked={timer_checked} checkedChildren="On" unCheckedChildren="Off" />
                    </Form.Item>
                    <Form.Item
                        label="Analysis Cycle"
                        name="cycle"
                        rules={[{ required: true }]}
                    >
                        <Input placeholder="Input" style={{ width: 50 }} />
                    </Form.Item>
                    <Form.Item
                        label="Trigger"
                        name="trigger"
                        rules={[{ required: true }]}
                    >
                        <Switch defaultChecked={trigger_checked} checkedChildren="Timer" unCheckedChildren="Valve" />
                    </Form.Item>
                    <Form.Item
                        label="Valve Interval(s)"
                        name="valve_interval"
                        rules={[{ required: true }]}
                    >
                        <InputNumber placeholder="Input" style={{ width: 60 }}/>
                    </Form.Item>
                </Row>
                <Divider />
                <Row align="center">
                    <Form.Item
                        label="Status Modbus"
                        name="status_modbus"
                        rules={[{ required: true }]}
                    >
                        <InputNumber placeholder="Input" style={{ width: 75 }} />
                    </Form.Item>
                    <Form.Item
                        label="Beat Modbus"
                        name="beat_modbus"
                        rules={[{ required: true }]}
                    >
                        <InputNumber placeholder="Input" style={{ width: 75 }} />
                    </Form.Item>
                    <Form.Item
                        label="State Modbus"
                        name="state_modbus"
                        rules={[{ required: true }]}
                    >
                        <InputNumber placeholder="Input" style={{ width: 75 }} />
                    </Form.Item>
                    <Form.Item
                        label="Output @ High T2/Q"
                        name="result_output_mode"
                        rules={[{ required: true }]}
                    >
                        <Select placeholder="Select" style={{ width: 100 }}>
                            <Option value={0}>Track</Option>
                            <Option value={1}>Hold</Option>
                            <Option value={2}>Block</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Block Value"
                        name="result_output_block_value"
                        rules={[{ required: true }]}
                    >
                        <InputNumber placeholder="Input" style={{ width: 75 }} />
                    </Form.Item>
                </Row>
                <Divider />
                <Row align="center">
                    <Form.Item
                        label="Truck-On Output"
                        name="truck_avg_output_method"
                        rules={[{ required: true }]}
                    >
                        <Switch defaultChecked={truck_on_output_checked} checkedChildren="Rolling" unCheckedChildren="Fixed" />
                    </Form.Item>
                    <Form.Item
                        label="Truck-On Value"
                        name="truck_avg_output_truck_loading_value"
                        rules={[{ required: true }]}
                    >
                        <InputNumber placeholder="Input" style={{ width: 75 }} />
                    </Form.Item>
                    <Form.Item
                        label="Bad Truck Value"
                        name="truck_avg_output_bad_spectra_value"
                        rules={[{ required: true }]}
                    >
                        <InputNumber placeholder="Input" style={{ width: 75 }} />
                    </Form.Item>
                    <Form.Item
                        label="24Hr. Avg"
                        name="average_method"
                        rules={[{ required: true }]}
                    >
                        <Select placeholder="Select" style={{ width: 125 }}>
                            <Option value={"F"}>Fixed Time</Option>
                            <Option value={"R"}>Rolling</Option>
                            <Option value={"D"}>Disabled</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="24Hr."
                        name="average_time_hour"
                        rules={[{ required: true }]}
                    >
                        <Input placeholder="Input" style={{ width: 40 }} />
                    </Form.Item>
                    <Form.Item
                        label="Avg."
                        name="average_time_min"
                        rules={[{ required: true }]}
                    >
                        <Input placeholder="Input" style={{ width: 50 }} />
                    </Form.Item>
                    <Form.Item
                        label="Time"
                        name="average_time_sec"
                        rules={[{ required: true }]}
                    >
                        <Input placeholder="Input" style={{ width: 50 }} />
                    </Form.Item>
                </Row>
                <Divider />
                <Row align="center">
                    <Form.Item
                        label="BG Low"
                        name="background_low"
                        rules={[{ required: true }]}
                    >
                        <InputNumber placeholder="Input" style={{ width: 75 }} />
                    </Form.Item>
                    <Form.Item
                        label="BG High"
                        name="background_high"
                        rules={[{ required: true }]}
                    >
                        <InputNumber placeholder="Input" style={{ width: 75 }} />
                    </Form.Item>
                    <Form.Item
                        label="BG Noisy"
                        name="background_noisy"
                        rules={[{ required: true }]}
                    >
                        <InputNumber placeholder="Input" style={{ width: 75 }} />
                    </Form.Item>
                    <Form.Item
                        label="BG Methane"
                        name="background_methane"
                        rules={[{ required: true }]}
                    >
                        <InputNumber placeholder="Input" style={{ width: 75 }} />
                    </Form.Item>
                    <Form.Item
                        label="Flow Meter"
                        name="flow_meter_enabled"
                        rules={[{ required: true }]}
                    >
                        <Switch defaultChecked={flow_meter_checked} checkedChildren="On" unCheckedChildren="Off" />
                    </Form.Item>
                </Row>
                <Row align="center" style={{ paddingTop: 10 }}>
                    <Form.Item style={{ paddingRight: 10 }}>
                        <Button type="primary" style={{ color: "#000000" }}>
                            Spectrometers Diagnostic Collection
                        </Button>
                    </Form.Item>
                    <Form.Item style={{ paddingRight: 10 }}>
                        <Button type="primary" style={{ color: "#790000" }}>
                            Power OFF Spectrometer 1
                        </Button>
                    </Form.Item>
                    <Form.Item style={{ paddingRight: 10 }}>
                        <Button type="primary" style={{ color: "#790000" }}>
                            Power OFF Spectrometer 2
                        </Button>
                    </Form.Item>
                    <Form.Item style={{ paddingRight: 10 }}>
                        <Button
                            type="primary"
                            style={{ backgroundColor: "yellow", color: "black" }}
                            onClick={() => form.resetFields()}
                        >
                            CANCEL
                        </Button>
                    </Form.Item>
                    <Form.Item>
                        <Button
                            type="primary"
                            form="vconAnalysesForm"
                            htmlType="submit"
                            style={{ backgroundColor: "lightgreen", color: "black" }}
                        >
                            SAVE
                        </Button>
                    </Form.Item>
                </Row>
            </Form>
        </Card>
    );
};

export default VCONAnalysesForm;