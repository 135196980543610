import React from "react";
import {
    Card,
    Divider,
    Form,
    Input,
    Row,
    Select,
    Switch,
    InputNumber, Button,
} from "antd";

const {Option} = Select;

const VCONSpectrometersForm = (props) => {
    const [form] = Form.useForm();
    let spectrometer_one_checked = false;
    let spectrometer_two_checked = false;
    if (props.data["spectrometer_one_connection"] === true || props.data["spectrometer_one_connection"] === 0) {
        spectrometer_one_checked = true;
    }
    if (props.data["spectrometer_two_connection"] === true || props.data["spectrometer_two_connection"] === 0) {
        spectrometer_two_checked = true;
    }

    function onFinish(values) {
        let spectrometers = [];
        let spectrometer_one = Object.keys(values).filter((key) => key.includes('spectrometer_one')).reduce((
            cur, key) => {
            return Object.assign(cur, {[key.slice(17, key.length)]: values[key]})
        }, {});
        spectrometers.push(spectrometer_one);
        let spectrometer_two = Object.keys(values).filter((key) => key.includes('spectrometer_two')).reduce((
            cur, key) => {
            return Object.assign(cur, {[key.slice(17, key.length)]: values[key]})
        }, {});
        spectrometers.push(spectrometer_two);
        props.onVCONFinish("spectrometers", spectrometers);
    }

    return (
        <Card>
            <Form
                id="vconSpectrometersForm"
                labelCol={{span: 24}}
                wrapperCol={{span: 14}}
                layout="horizontal"
                form={form}
                initialValues={props.data}
                onFinish={(values) => onFinish(values)}
                size="large"
            >
                <Row align="center">
                    <Form.Item
                        label="Source"
                        name="spectrometer_one_emulate"
                        rules={[{required: true}]}
                    >
                        <Select placeholder="Select" style={{width: 115}}>
                            <Option value={0}>Local</Option>
                            <Option value={1}>Emulate</Option>
                            <Option value={2}>UDP</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Type"
                        name="spectrometer_one_type"
                        rules={[{required: true}]}
                    >
                        <Select placeholder="Select" style={{width: 100}}>
                            <Option value={0}>Gen 1</Option>
                            <Option value={1}>Gen 1N</Option>
                            <Option value={2}>Gen 2</Option>
                            <Option value={3}>Gen 2N</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Serial Number"
                        name="spectrometer_one_serial_number"
                        rules={[{required: true}]}
                    >
                        <Input placeholder="Input Value" style={{width: 110}}/>
                    </Form.Item>
                    <Form.Item
                        label="Diag."
                        name="spectrometer_one_diagnostics1"
                        rules={[{required: true}]}
                    >
                        <Input style={{width: 45}}/>
                    </Form.Item>
                    <Form.Item
                        label="Clock"
                        name="spectrometer_one_diagnostics2"
                        rules={[{required: true}]}
                    >
                        <Input style={{width: 45}}/>
                    </Form.Item>
                    <Form.Item
                        label="Power Modbus"
                        name="spectrometer_one_power_modbus"
                        rules={[{required: true}]}
                    >
                        <InputNumber placeholder="Input" style={{width: 75}}/>
                    </Form.Item>
                    <Form.Item
                        label="Connection"
                        name="spectrometer_one_connection"
                        rules={[{required: true}]}
                    >
                        <Switch defaultChecked={spectrometer_one_checked}
                                checkedChildren="USB"
                                unCheckedChildren="Ethernet"
                        />
                    </Form.Item>
                    <Form.Item
                        label="IP Address"
                        name="spectrometer_one_ip"
                        rules={[{required: true}]}
                    >
                        <Input placeholder="Input" style={{width: 120}}/>
                    </Form.Item>
                    <Form.Item
                        label="Cal Modbus"
                        name="spectrometer_one_cal_modbus"
                        rules={[{required: true}]}
                    >
                        <InputNumber placeholder="Input" style={{width: 75}}/>
                    </Form.Item>
                </Row>
                <Divider/>
                <Row align="center">
                    <Form.Item
                        label="Source"
                        name="spectrometer_two_emulate"
                        rules={[{required: true}]}
                    >
                        <Select placeholder="Select" style={{width: 115}}>
                            <Option value={0}>Local</Option>
                            <Option value={1}>Emulate</Option>
                            <Option value={2}>UDP</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Type"
                        name="spectrometer_two_type"
                        rules={[{required: true}]}
                    >
                        <Select placeholder="Select" style={{width: 100}}>
                            <Option value={0}>Gen 1</Option>
                            <Option value={1}>Gen 1N</Option>
                            <Option value={2}>Gen 2</Option>
                            <Option value={3}>Gen 2N</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Serial Number"
                        name="spectrometer_two_serial_number"
                        rules={[{required: true}]}
                    >
                        <Input placeholder="Input" style={{width: 110}}/>
                    </Form.Item>
                    <Form.Item
                        label="Diag."
                        name="spectrometer_two_diagnostics1"
                        rules={[{required: true}]}
                    >
                        <Input style={{width: 45}}/>
                    </Form.Item>
                    <Form.Item
                        label="Clock"
                        name="spectrometer_two_diagnostics2"
                        rules={[{required: true}]}
                    >
                        <Input style={{width: 45}}/>
                    </Form.Item>
                    <Form.Item
                        label="Power Modbus"
                        name="spectrometer_two_power_modbus"
                        rules={[{required: true}]}
                    >
                        <InputNumber placeholder="Input" style={{width: 75}}/>
                    </Form.Item>
                    <Form.Item
                        label="Connection"
                        name="spectrometer_two_connection"
                        rules={[{required: true}]}
                    >
                        <Switch
                            defaultChecked={spectrometer_two_checked}
                            checkedChildren="USB"
                            unCheckedChildren="Ethernet"
                        />
                    </Form.Item>
                    <Form.Item
                        label="IP Address"
                        name="spectrometer_two_ip"
                        rules={[{required: true}]}
                    >
                        <Input placeholder="Input" style={{width: 120}}/>
                    </Form.Item>
                </Row>
                <Row align="center" style={{paddingTop: 10}}>
                    <Form.Item style={{paddingRight: 10}}>
                        <Button type="primary" style={{color: "#000000"}}>
                            Spectrometers Diagnostic Collection
                        </Button>
                    </Form.Item>
                    <Form.Item style={{paddingRight: 10}}>
                        <Button type="primary" style={{color: "#790000"}}>
                            Power OFF Spectrometer 1
                        </Button>
                    </Form.Item>
                    <Form.Item style={{paddingRight: 10}}>
                        <Button type="primary" style={{color: "#790000"}}>
                            Power OFF Spectrometer 2
                        </Button>
                    </Form.Item>
                    <Form.Item style={{paddingRight: 10}}>
                        <Button
                            type="primary"
                            style={{backgroundColor: "yellow", color: "black"}}
                            onClick={() => form.resetFields()}
                        >
                            CANCEL
                        </Button>
                    </Form.Item>
                    <Form.Item>
                        <Button
                            type="primary"
                            form="vconSpectrometersForm"
                            htmlType="submit"
                            style={{backgroundColor: "lightgreen", color: "black"}}
                        >
                            SAVE
                        </Button>
                    </Form.Item>
                </Row>
            </Form>
        </Card>
    );
};

export default VCONSpectrometersForm;
